@use "../../common/screen";

.top-search {
  @include screen.to(screen.$xl) {
    max-width: 457px;
    margin: 0 auto;
  }
}

.top-search__form {
  position: relative;
}

.top-search__label {
  display: block;
}

.top-search__input {
  position: relative;
  width: 100%;
  min-height: 40px;
  padding: 10px 24px 10px 44px;
  border: 1px solid #25314c;
  border-radius: 100px;
  background: transparent;
  outline: none;
  font-size: 16px;
  line-height: normal;
  resize: none;
  transition: background-color var(--transition), border-color var(--transition);
  appearance: none;
  scrollbar-width: none;

  &:disabled {
    opacity: 0.6;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border-color: var(--color-accent);
  }

  &:focus:not(:disabled) {
    background: #fff;
  }

  &::-ms-clear {
    display: none;
  }

  &::placeholder {
    color: var(--color-text-disabled);
    opacity: 1;
  }
}

.top-search__submit {
  position: absolute;
  top: 50%;
  left: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: transparent;
  color: var(--color-text-disabled);
  outline: none;
  transition: color var(--transition);
  transform: translateY(-50%);
  appearance: none;
}
