@use "../../common/screen";

.footer {
  padding: 40px 0;
  background: linear-gradient(90deg, #494949 5.54%, #767676 54.74%, #4c4c4c 100%);
  color: #fff;

  @include screen.from(screen.$xl) {
    padding: 50px 0;
  }
}

.footer__link {
  transition: color var(--transition);

  &:hover,
  &:focus-visible {
    color: var(--color-links-hover-light);
  }
}

.footer__icon {
  flex-shrink: 0;
  margin-right: 8px;

  &.is-whatsapp {
    color: #11ecd2;
  }

  &.is-tg {
    color: #00a0e7;
  }

  &.is-viber {
    color: #ae9ef4;
  }

  &.is-skype {
    color: #00b5f9;
  }
}

.footer__heading {
  font-weight: 500;
  font-size: 16px;
  // text-decoration: underline;
  white-space: nowrap; // who needs the grid?..
}

.footer__layout {
  display: grid;
  grid-template:
    'logo       logo'
    'phones     phones'
    'schedule   schedule'
    'partner    partner'
    'instrument instrument'
    'contacts   contacts'
    'socials    reviews'
    'copyright  copyright'
    / minmax(0, 1fr) minmax(0, 1fr);
  align-items: start;
  column-gap: 20px;

  @include screen.from(screen.$sm) {
    grid-template:
      'logo       logo'
      'phones     contacts'
      'schedule   contacts'
      'partner    contacts'
      'instrument contacts' 1fr
      'socials    reviews'
      'copyright  copyright'
      / minmax(0, 1fr) minmax(0, 1fr);
  }

  @include screen.from(screen.$md) {
    grid-template:
      '. logo       logo      .'
      '. phones     contacts  .'
      '. schedule   contacts  .'
      '. partner    contacts  .'
      '. instrument contacts  .'
      '. socials    reviews   .'
      '. copyright  copyright .'
      / minmax(0, 1fr) minmax(0, 2fr) minmax(0, 2fr) minmax(0, 1fr);
  }

  @include screen.from(screen.$xl) {
    grid-template:
      '. logo  contacts socials'
      '. phones  contacts socials'
      '. schedule  contacts socials'
      '. partner  contacts reviews'
      '. instrument  contacts reviews'
      '. copyright copyright copyright'
      / 128px 320px 278px 1fr;
    gap: 0;
    align-items: start;
  }
}

.footer__logo {
  display: block;
  grid-area: logo;
  justify-self: center;
  margin-bottom: 40px;

  @include screen.from(screen.$xl) {
    justify-self: start;
    margin-bottom: 20px;
  }
}

.footer__phones {
  grid-area: phones;
  margin-bottom: 15px;

  @include screen.from(screen.$xl) {
    margin-bottom: 15px;
  }
}

.footer__phone {
  display: block;
  font-weight: 500;
  font-size: 16px;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }
}

.footer__schedule {
  grid-area: schedule;
  margin-bottom: 15px;

  @include screen.from(screen.$xl) {
    margin-bottom: 35px;
  }
}

.footer__partner {
  grid-area: partner;
  margin-bottom: 15px;

  @include screen.from(screen.$xl) {
    margin-bottom: 25px;
  }
}

.footer__instrument {
  grid-area: instrument;
  margin-bottom: 40px;

  @include screen.from(screen.$xl) {
    margin-bottom: 0;
  }
}

.footer__contacts {
  display: grid;
  grid-area: contacts;
  grid-template-rows: auto auto auto 1fr;
  grid-template-columns: 100%;
  gap: 20px;
  align-items: start;
  margin-bottom: 40px;

  @include screen.from(screen.$xs) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 12px;
  }

  @include screen.from(screen.$sm) {
    grid-template-columns: 100%;
  }

  @include screen.from(screen.$xl) {
    margin-bottom: 0;
  }
}

.footer__contacts-heading {
  grid-column: span 1;
  margin-bottom: 0;

  @include screen.from(screen.$xs) {
    grid-column: span 2;
  }

  @include screen.from(screen.$sm) {
    grid-column: span 1;
  }
}

.footer__contact {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.footer__address {
  align-items: start;

  @include screen.to(screen.$xs) {
    order: 1;
  }

  @include screen.from(screen.$xs) {
    grid-row: 2 / span 3;
    grid-column: 2;
  }

  @include screen.from(screen.$sm) {
    grid-row: auto;
    grid-column: auto;
    order: 1;
  }
}

.footer__socials {
  display: grid;
  grid-area: socials;
  grid-template-columns: 100%;
  gap: 20px;
}

.footer__reviews {
  display: grid;
  grid-area: reviews;
  grid-template-columns: 100%;
  gap: 20px;
  align-content: start;
}

.footer__copyright {
  grid-area: copyright;
  justify-self: center;
  margin-top: 40px;
  opacity: 0.5;

  @include screen.from(screen.$md) {
    br {
      display: none;
    }
  }

  @include screen.from(screen.$xl) {
    justify-self: start;
  }
}
