.sale-paysystem-yandex-button-item {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;
  padding: 13px 20px;
  border: 1px solid #315cc1;
  border-radius: 16px;
  background: #315cc1;
  color: #fff;
  outline: none;
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 140%; /* 28px */
  letter-spacing: 0.4px;
  text-align: center;
  text-transform: uppercase;
  transition: background-color var(--transition), color var(--transition);
  appearance: none;

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    background-color: #e8efff;
    color: #315cc1;
  }

  &:active:not(:disabled) {
    background-color: #fff;
    color: #315cc1;
  }
}
