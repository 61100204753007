@use "../../common/screen";

.contacts {
}

.contacts__main {
  display: grid;
  grid-template-columns: 100%;
  gap: 30px;

  @include screen.from(screen.$xl) {
    grid-template-columns: 1fr 866px;
    gap: 85px;
    align-items: start;
    width: 100%;
    max-width: var(--size-container-width);
    margin: auto;
    padding: 0 var(--size-container-sidegap);
  }
}

.contacts__contacts {
  display: grid;
  grid-template-rows: auto auto auto 1fr;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 22px 20px;
  align-content: start;
  width: 100%;
  max-width: 409px;
  margin: 0 auto;
  padding: 0 var(--size-container-sidegap);

  @include screen.from(screen.$xl) {
    grid-template-columns: 100%;
    max-width: 100%;
    margin: 0;
  }
}

.contacts__subheading {
  font-weight: 500;
  text-decoration: underline;

  @include screen.to(screen.$xl) {
    grid-column: span 2;
  }
}

.contacts__contact {
  display: flex;
  align-items: center;
  transition: color var(--transition);

  &:hover,
  &:focus-visible {
    color: var(--color-links-hover);
  }

  .i {
    flex-shrink: 0;
    margin-right: 8px;
  }

  &.is-address {
    grid-row: 2 / span 3;
    align-items: start;

    @include screen.from(screen.$xl) {
      grid-row: span 1;
      order: 1;
    }
  }

  &:last-of-type {
    height: fit-content;
  }
}

.contacts__map {
  overflow: hidden;
  height: 600px;
  border: 2px solid #9b9b9b;
}

.contacts__how {
  margin-top: 20px;
  background: #ebf0ff;

  @include screen.from(screen.$md) {
    margin-top: 20px;
  }

  @include screen.from(screen.$xl) {
    margin-top: 50px;
    background: transparent;
  }
}

.contacts__how-inner {
  padding: 20px;
  background: #ebf0ff;

  @include screen.from(screen.$md) {
    padding: 30px 113px;
  }

  @include screen.from(screen.$xl) {
    padding: 50px 60px;
    border-radius: 20px;
  }
}

.contacts__how-layout {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;

  @include screen.from(screen.$md) {
    gap: 30px;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: 500px 1fr;
    gap: 50px;
  }
}

.contacts__how-text {
  @include screen.from(screen.$md) {
    font-size: 16px;
  }

  @include screen.from(screen.$xl) {
    order: 1;
    font-size: 20px;
  }
}

.contacts__requisites {
  font-size: 16px;

  @include screen.from(screen.$xl) {
    font-size: 20px;
  }
}

.contacts__requisites-name {
  margin-bottom: 24px;
}

.contacts__requisites-list {
  @include screen.from(screen.$sm) {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 15px;
  }

  @include screen.from(screen.$xl) {
    gap: 20px 35px;
  }
}

.contacts__requisites-label {
  font-weight: 700;

  @include screen.to(screen.$sm) {
    margin-bottom: 6px;
  }
}

.contacts__requisites-value {
  @include screen.to(screen.$sm) {
    margin-bottom: 20px;
  }
}
