@use "../common/screen";

.product-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100px;
  padding: 8px 8px 14px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 12px;
  background: #fff;
  transition: box-shadow var(--transition);

  &:hover,
  &:focus-within {
    box-shadow: 0 8px 36px 0 rgb(0 0 0 / 20%);
  }

  @include screen.from(screen.$md) {
    padding: 14px;
    border-radius: 20px;
  }
}

.product-card__picture-link {
  display: block;
  width: 100%;
}

.product-card__picture {
  display: block;
  width: 100%;
  height: 135px;
  margin-bottom: 12px;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.product-card__title {
  margin-bottom: 24px;
  font-weight: 500;
  text-align: center;
  transition: color var(--transition);

  &:hover,
  &:focus-visible,
  .product-card:has(.product-card__picture-link:hover) &,
  .product-card:has(.product-card__picture-link:focus-visible) & {
    color: var(--color-links-hover);
  }

  @include screen.from(350px) {
    font-size: 13px;
  }

  @include screen.from(screen.$sm) {
    font-size: 14px;
  }
}

.product-card__props {
  display: grid;
  grid-template-columns: max-content 1fr;
  margin-top: auto;
  margin-bottom: 8px;
  color: #9b9b9b;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: normal;
}

.product-card__prop-value.is-manufacturer {
  grid-column: span 2;
}

.product-card__quantity {
  display: none;
}

.product-card__prices {
  display: grid;
  grid-template:
    'old discount'
    'price discount'
    / 1fr 40px;
  margin-bottom: 8px;
}

.product-card__price {
  display: block;
  grid-area: price;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: normal;

  @include screen.from(350px) {
    font-size: 18px;
  }

  @include screen.from(screen.$sm) {
    font-size: 20px;
  }
}

.product-card__old-price {
  display: block;
  grid-area: old;
  margin-bottom: 8px;
  color: #9b9b9b;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: normal;
  text-decoration: line-through;
}

.product-card__discount {
  display: flex;
  grid-area: discount;
  justify-content: center;
  align-items: center;
  align-self: end;
  width: 40px;
  height: 40px;
  border: 1px solid var(--color-lines);
  border-radius: 12px;
  color: #9b9b9b;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  user-select: none;
}

.product-card__buy {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 250px;
  margin: 0 auto;
  padding: 0;
  background: var(--color-accent-light);
  outline: 0;
  text-align: center;
  appearance: none;

  &:disabled {
    background: var(--color-text-alt);
  }
}

.product-card__buy-icon {
  display: flex;
  flex: 0 1 34px;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  color: #fff;
}

.product-card__buy-text {
  flex: 1 0 0;
  min-height: 34px;
  padding: 7px;
  border: 1px solid var(--color-accent-light);
  border-left: 1px solid var(--color-accent-light);
  background: #fff;
  color: var(--color-text-main);
  font-weight: 500;
  text-transform: uppercase;
  transition: color var(--transition), background-color var(--transition),
    border-color var(--transition);

  .product-card__buy:disabled & {
    border: 1px solid var(--color-text-alt);
    border-left: 1px solid var(--color-text-alt);
    color: var(--color-text-alt);
  }

  .product-card__buy:not(:disabled):hover &,
  .product-card__buy:not(:disabled):focus-visible & {
    border-left-color: #fff;
    background: var(--color-accent-light);
    color: #fff;
  }
}
