@use "../../common/screen";

.catalog {
  padding-top: 24px;
  padding-bottom: 80px;

  @include screen.from(screen.$sm) {
    padding-top: 30px;
  }

  @include screen.from(screen.$md) {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  @include screen.from(screen.$xl) {
    padding-top: 24px;
  }
}

.catalog__filter {
  display: none;

  @include screen.from(screen.$xl) {
    display: block;
  }
}

.catalog__heading {
  margin-bottom: 20px;
  text-align: center;

  @include screen.from(screen.$xl) {
    margin-bottom: 30px;
    text-align: left;
  }
}

.catalog__subsections {
  margin-bottom: 24px;

  @include screen.from(screen.$sm) {
    margin-bottom: 36px;
  }

  @include screen.from(screen.$md) {
    margin-bottom: 48px;
  }

  @include screen.from(screen.$xl) {
    margin-bottom: 64px;
  }
}

.catalog__description {
  margin-bottom: 20px;

  @include screen.from(screen.$sm) {
    margin-bottom: 30px;
  }
}

.catalog__panel {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.catalog__sort {
  width: 100%;
  max-width: 226px;

  @include screen.from(screen.$xl) {
    max-width: 274px;
    margin-left: auto;
  }
}

.catalog__section {
  margin-bottom: 24px;

  @include screen.from(screen.$sm) {
    margin-bottom: 36px;
  }

  @include screen.from(screen.$md) {
    margin-bottom: 48px;
  }

  @include screen.from(screen.$xl) {
    margin-bottom: 64px;
  }
}

.catalog__section-heading {
  margin-bottom: 20px;
  color: var(--color-accent);

  @include screen.from(screen.$md) {
    margin-bottom: 24px;
  }

  @include screen.from(screen.$xl) {
    margin-bottom: 32px;
  }
}

.catalog__section-subheading {
  margin-bottom: 18px;
  color: var(--color-accent);
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: normal;
  text-transform: uppercase;

  @include screen.from(screen.$md) {
    margin-bottom: 24px;
    font-size: 16px;
  }

  @include screen.from(screen.$xl) {
    margin-bottom: 32px;
  }
}

.catalog__list {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;

  @include screen.from(350px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 8px;
  }

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 14px;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: 16px;
  }
}

.catalog__item {
  display: flex;
}

.catalog__load-more-wrap {
  display: flex;
  justify-content: center;
  margin-top: 30px;

  &[hidden] {
    display: none;
  }
}

.catalog__pagination {
  margin-top: 30px;
}
