@use "../common/screen";

.container {
  display: block;
  width: 100%;
  max-width: var(--size-container-width);
  margin: auto;
  padding: 0 var(--size-container-sidegap);
}

.mobile-fullwidth-container {
  display: block;
  width: 100%;

  @include screen.from(screen.$md) {
    max-width: var(--size-container-width);
    margin: auto;
    padding: 0 var(--size-container-sidegap);
  }
}
