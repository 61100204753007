@use "../common/screen";

.text-line {
  padding: 10px var(--size-container-sidegap);
  background: var(--color-accent-light);
  color: #fff;
  font-weight: 600;
  text-align: center;

  @include screen.from(screen.$sm) {
    font-size: 16px;
  }

  @include screen.from(screen.$md) {
    padding: 10px 0;
  }

  @include screen.from(screen.$xl) {
    padding: 8px;
    font-size: 20px;
  }
}z

.text-line--multiline {
  padding: 20px var(--size-container-sidegap);
  text-align: left;

  @include screen.from(screen.$md) {
    padding: 24px 20px;
  }

  @include screen.from(screen.$xl) {
    padding: 24px 70px;
  }
}
