@use "../common/screen";

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page__content {
  flex: 1;
}

.page__generic-content {
  padding-top: 24px;
  padding-bottom: 80px;

  @include screen.from(screen.$sm) {
    padding-top: 30px;
  }

  @include screen.from(screen.$md) {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  @include screen.from(screen.$xl) {
    padding-top: 24px;
  }
}

.page__heading {
  margin-bottom: 20px;
  text-align: center;

  @include screen.from(screen.$sm) {
    margin-bottom: 30px;
  }
}

.page__section {
  margin-top: 20px;
  margin-bottom: 20px;

  @include screen.from(screen.$sm) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @include screen.from(screen.$md) {
    margin-top: 60px;
    margin-bottom: 40px;
  }

  @include screen.from(screen.$xl) {
    margin-top: 80px;
    margin-bottom: 50px;
  }
}

.page__section-heading {
  margin-bottom: 20px;
  text-align: center;

  @include screen.from(screen.$md) {
    margin-bottom: 30px;
  }

  @include screen.from(screen.$xl) {
    margin-bottom: 40px;
  }
}

.education-discounts {
  display: grid;
  grid-template:
    'text'
    'cards'
    / 100%;
  gap: 20px;

  @include screen.from(screen.$md) {
    grid-template:
      'cards text'
      / 1fr 1fr;
  }

  @include screen.from(screen.$xl) {
    grid-template:
      'cards text .'
      / 5fr 6fr 1fr;
  }
}

.education-discounts__cards {
  grid-area: cards;
  max-width: 320px;
  margin: 0 auto;
  transform: translateX(-20px);

  @include screen.from(screen.$md) {
    max-width: 100%;
    margin: 0;
    transform: none;
  }
}

.education-discounts__text {
  grid-area: text;
  text-align: center;
  font-weight: 700;

  @include screen.from(screen.$md) {
    padding-top: 30px;
    text-align: left;
  }
}
