@use "../../common/screen";

.main-banner {
  margin-top: 24px;
}

.main-banner__swiper {
  --swiper-pagination-bottom: 35px;
  --swiper-navigation-size: 20px;
  --swiper-pagination-color: #fff;

  background: #ebf0ff;

  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: rgb(255 255 255 / 80%);
    color: var(--color-accent);
    transition: background-color var(--transition), color var(--transition);

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background: currentcolor;
      mask-repeat: no-repeat;
      mask-size: 100% 100%;
    }

    @include screen.to(screen.$md) {
      display: none;
    }
  }

  .swiper-button-prev::after {
    mask-image: url(svg/icons/angle-left-20.svg);
  }

  .swiper-button-next::after {
    mask-image: url(svg/icons/angle-right-20.svg);
  }

  &[data-swiper-theme='light'] {
    --swiper-pagination-color: var(--color-accent);

    .swiper-button-prev,
    .swiper-button-next {
      background-color: rgb(49 92 193 / 60%);
      color: #fff;
    }
  }

  &[data-swiper-theme='black'] {
    --swiper-pagination-color: #000;

    .swiper-button-prev,
    .swiper-button-next {
      background-color: rgb(0 0 0 / 50%);
      color: #d8d8d8;
    }
  }

  @include screen.from(screen.$md) {
    --swiper-pagination-bottom: 25px;

    border-radius: 30px;
  }

  @include screen.from(screen.$xl) {
    --swiper-pagination-bottom: 25px;

    border-radius: 50px;
  }
}

.main-banner__banner {
  display: grid;
  grid-template-columns: 100%;
  overflow: hidden;
  height: 500px;

  @include screen.from(screen.$md) {
    height: 320px;
    border-radius: 30px;
  }

  @include screen.from(screen.$xl) {
    height: 490px;
    border-radius: 50px;
  }
}

.main-banner__picture {
  display: block;
  grid-row: 1 / -1;
  grid-column: 1 / -1;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.main-banner__content {
  display: flex;
  flex-direction: column;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  padding: 36px var(--size-container-sidegap) 88px;

  @include screen.from(screen.$md) {
    padding: 30px 60px 100px 44%;
  }

  @include screen.from(screen.$xl) {
    padding: 35px 100px 100px 44%;
  }
}

.main-banner__title {
  align-self: flex-end;
  max-width: 290px;
  margin-bottom: 40px;
  color: var(--color-accent);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
  text-transform: uppercase;

  @include screen.to(450px) {
    br {
      display: none;
    }
  }

  @include screen.from(screen.$sm) {
    align-self: center;
  }

  @include screen.from(screen.$md) {
    align-self: start;
    max-width: 100%;
  }

  @include screen.from(screen.$xl) {
    font-size: 32px;
  }
}

.main-banner__warn {
  align-self: center;
  width: 274px;
  margin-top: auto;
  padding: 14px 36px;
  border-radius: 16px;
  background: var(--color-accent);
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  text-transform: uppercase;

  em {
    display: block;
    font-style: normal;
    font-size: 30px;
  }

  @include screen.from(screen.$md) {
    align-self: start;
    margin-bottom: 36px;
  }

  @include screen.from(screen.$xl) {
    width: auto;
    padding: 17px 48px;
    font-size: 24px;

    em {
      font-size: 36px;
    }
  }
}

.main-banner__button {
  align-self: center;
  width: 100%;
  max-width: 271px;
  margin-top: auto;

  @include screen.from(screen.$md) {
    align-self: start;
    margin-top: 0;
  }
}

.main-banner__slide--is-23feb {
  background: #fae4d7;
  aspect-ratio: 1 / 1;

  .main-banner__banner {
    height: 100%;
  }

  .main-banner__picture img {
    object-fit: contain;
  }

  @include screen.from(screen.$sm) {
    aspect-ratio: 1160 / 490;
  }
}

.main-banner__slide--is-ny2024 {
  background: #2f2f2f;
  aspect-ratio: 480 / 500;

  .main-banner__banner {
    height: 100%;
  }

  .main-banner__picture img {
    object-fit: contain;
  }

  @include screen.from(screen.$sm) {
    aspect-ratio: 1160 / 490;
  }
}

.main-banner__banner--is-education {
  background: #3d65a3;

  .main-banner__picture img {
    @include screen.from(screen.$sm) {
      object-position: left center;
    }
  }

  .main-banner__content {
    padding: 36px var(--size-container-sidegap) 88px;

    @include screen.from(screen.$md) {
      padding: 30px 60px 100px 30%;
    }

    @include screen.from(screen.$xl) {
      padding: 35px 100px 100px 30%;
    }
  }

  .main-banner__title {
    color: #fff;

    @include screen.from(screen.$sm) {
      align-self: center;
    }

    @include screen.from(screen.$md) {
      align-self: center;
      max-width: 100%;
      text-align: center;
    }

    @include screen.from(screen.$xl) {
      font-size: 30px;
    }
  }

  .main-banner__button {
    align-self: center;
    width: 100%;
    max-width: 320px;
    border-color: #fff;

    @include screen.from(screen.$md) {
      align-self: center;
    }
  }
}

.main-banner__banner--is-catalog {
  background: #1d1d1d;

  .main-banner__picture img {
    object-fit: contain;
    object-position: center top;

    @include screen.from(screen.$sm) {
      object-fit: cover;
      object-position: right center;
    }
  }

  .main-banner__content {
    padding: 30% var(--size-container-sidegap) 88px;

    @include screen.from(420px) {
      padding: 180px var(--size-container-sidegap) 88px;
    }

    @include screen.from(screen.$md) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-self: end;
      padding: 30px 60px 60px 24px;
    }

    @include screen.from(screen.$xl) {
      padding: 35px 100px 80px 24px;
    }
  }

  .main-banner__title {
    color: #000;

    @include screen.to(screen.$sm) {
      br {
        display: none;
      }
    }

    @include screen.from(screen.$sm) {
      align-self: center;
    }

    @include screen.from(screen.$md) {
      max-width: 100%;
      margin: 0;
      color: #fff;
      text-align: left;
    }

    @include screen.from(screen.$xl) {
      font-size: 30px;
    }
  }

  .main-banner__button {
    align-self: center;
    width: 100%;
    max-width: 320px;
    border-color: #fff;
    background: linear-gradient(249.11deg, rgb(153 153 153 / 66%) 36.26%, #999 65.76%);

    &:hover,
    &:focus-visible {
      background: linear-gradient(249.11deg, rgb(141 141 141 / 66%) 36.26%, #909090 65.76%);
      color: #fff;
    }

    @include screen.from(screen.$md) {
      margin-top: 0;
      background: linear-gradient(180deg, rgb(0 0 0 / 43.3%) 52.9%, rgb(37 37 37 / 24.3%) 100%);
    }

    @include screen.from(screen.$md) {
      align-self: center;
    }
  }
}

.main-banner__banner--is-contract {
  background: #f8ffff;

  .main-banner__picture img {
    @include screen.from(screen.$sm) {
      object-position: right center;
    }
  }

  .main-banner__content {
    padding: 36px var(--size-container-sidegap) 88px;

    @include screen.from(screen.$md) {
      padding: 30px 60px 100px;
    }

    @include screen.from(screen.$xl) {
      padding: 35px 100px 100px 110px;
    }
  }

  .main-banner__title {
    max-width: 100%;
    color: #000;
    text-align: center;
    text-shadow: 2px 2px 0 #fff, -2px 2px 0 #fff, -2px -2px 0 #fff, 2px -2px 0 #fff;

    @include screen.from(450px) {
      font-size: 24px;
      line-height: 1.2;
    }

    @include screen.from(screen.$sm) {
      align-self: center;

      span {
        font-size: 64px;
      }
    }

    @include screen.from(screen.$md) {
      align-self: flex-start;
      max-width: 100%;
      text-align: left;
    }

    @include screen.from(screen.$xl) {
      font-size: 48px;

      span {
        font-size: 96px;
      }
    }
  }

  .main-banner__button {
    align-self: center;
    width: 100%;
    max-width: 320px;
    border-color: #fff;

    @include screen.from(screen.$md) {
      align-self: flex-start;
      margin-left: 5%;
    }
  }
}

.main-banner__banner--is-contract-2 {
  background: #f8ffff;

  .main-banner__picture img {
    @include screen.from(screen.$sm) {
      object-position: right center;
    }
  }

  .main-banner__content {
    padding: 36px var(--size-container-sidegap) 88px;

    @include screen.from(screen.$md) {
      padding: 30px 60px 100px;
    }

    @include screen.from(screen.$xl) {
      padding: 35px 100px 100px 110px;
    }
  }

  .main-banner__title {
    max-width: 100%;
    color: #000;
    text-align: center;
    text-shadow: 2px 2px 0 #fff, -2px 2px 0 #fff, -2px -2px 0 #fff, 2px -2px 0 #fff;

    @include screen.from(450px) {
      font-size: 24px;
      line-height: 1.2;
    }

    @include screen.from(screen.$sm) {
      align-self: center;

      span {
        font-size: 64px;
      }
    }

    @include screen.from(screen.$md) {
      align-self: flex-start;
      max-width: 100%;
      text-align: left;
    }

    @include screen.from(screen.$xl) {
      font-size: 48px;

      span {
        font-size: 96px;
      }
    }
  }

  .main-banner__button {
    align-self: center;
    width: 100%;
    max-width: 330px;
    border: 3px solid #fff;
    background: #8e9095;

    &:hover,
    &:focus-visible {
      background: #7b7e82;
      color: #fff;
    }

    @include screen.from(screen.$md) {
      align-self: flex-start;
      margin-left: 5%;
    }
  }
}
