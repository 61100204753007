@use "screen";

// Include at the very end of all styles

.overflow-hidden {
  overflow: hidden;
}

.screen-reader-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(50%);
}

.hidden {
  display: none;
}

.max-md\:hidden {
  @include screen.to(screen.$md) {
    display: none;
  }
}


.md\:hidden {
  @include screen.from(screen.$md) {
    display: none;
  }
}

.w-full {
  width: 100%;
}

.text-center {
  text-align: center;
}

.text-paragraphs {
  & > * + * {
    margin-top: 1.3em;
  }
}

.select-none {
  user-select: none;
}

.responsive {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  padding: 0;

  &::before {
    content: '';
    display: block;
  }

  & > * {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.responsive--21by9 {
  &::before {
    padding-top: 42.857%;
  }
}

.responsive--16by9 {
  &::before {
    padding-top: 56.25%;
  }
}

.responsive--4by3 {
  &::before {
    padding-top: 75%;
  }
}

.responsive--1by1 {
  &::before {
    padding-top: 100%;
  }
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-3 {
  margin-bottom: 12px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-5 {
  margin-bottom: 20px;
}

.mb-6 {
  margin-bottom: 24px;
}

.mb-8 {
  margin-bottom: 32px;
}

.mb-10 {
  margin-bottom: 40px;
}

.mb-12 {
  margin-bottom: 48px;
}

.mb-24 {
  margin-bottom: 96px;
}

.mt-auto {
  margin-top: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-4 {
  margin-top: 16px;
}

.mt-3 {
  margin-top: 12px;
}

.mt-6 {
  margin-top: 24px;
}

.mt-9 {
  margin-top: 36px;
}

.mt-12 {
  margin-top: 48px;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-4 {
  margin-right: 16px;
}

.mr-8 {
  margin-right: 32px;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}
