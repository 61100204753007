@use "../../common/screen";

.news {
}

.news__list {
  margin-bottom: 40px;
}

.news__item {
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

.news__link {
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 20px 10px;
  padding: 12px;
  border-radius: 20px;
  background: #fff;
  transition: box-shadow var(--transition);

  &:hover,
  &:focus-visible {
    box-shadow: 0 8px 36px #0003;
  }

  @include screen.from(screen.$sm) {
    grid-template-columns: 90px 1fr;
    gap: 14px 20px;
    padding: 20px;
    font-size: 16px;
  }

  @include screen.from(screen.$md) {
    gap: 16px 40px;
  }

  @include screen.from(screen.$xl) {
    padding: 36px 60px;
    font-size: 20px;
  }
}

.news__date {
  grid-column: 1 / 2;
  color: var(--color-accent);
  font-weight: 600;
  line-height: 130%;
  text-transform: uppercase;
}

.news__name {
  grid-column: 2 / 3;
  font-weight: 700;
  line-height: 130%;
}

.news__text {
  grid-row: 2 / 3;
  grid-column: 2 / 3;
}
