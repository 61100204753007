@use "../common/screen";

.breadcrumb {
  overflow: visible;
  margin-top: 24px;

  @include screen.from(screen.$xl) {
    overflow: hidden;
  }
}

.breadcrumb__item {
  display: flex;
  align-items: center;
  color: var(--color-text-alt2);

  &:not(:last-of-type) {
    &::after {
      content: '\00a0/\00a0';
    }
  }

  @include screen.from(screen.$xl) {
    color: #626a7d;

    &:not(:last-of-type) {
      &::after {
        content: '';
        display: block;
        width: 28px;
        height: 16px;
        background: url(svg/icons/arrow-right-16.svg) no-repeat center;
      }
    }
  }
}

.breadcrumb__link {
  transition: color var(--transition);

  &:hover,
  &:focus-visible {
    color: var(--color-links-hover);
  }
}

.breadcrumb__text {
  @include screen.from(screen.$xl) {
    display: block;
    padding: 4px 11px;
    background: #e1e1e1;

    &.is-last {
      color: var(--color-text-main);
      font-weight: 500;
    }
  }
}
