@use "../common/screen";

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 65px;
  padding: 18px 20px;
  border: 1px solid #315cc1;
  border-radius: 16px;
  background: #315cc1;
  color: #fff;
  outline: none;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 140%; /* 28px */
  letter-spacing: 0.4px;
  text-align: center;
  text-transform: uppercase;
  transition: background-color var(--transition), color var(--transition);
  appearance: none;

  &:disabled {
    border-color: #878787;
    background-color: #ededed;
    color: #878787;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    background-color: #e8efff;
    color: #315cc1;
  }

  &:active:not(:disabled) {
    background-color: #fff;
    color: #315cc1;
  }

  @include screen.from(screen.$sm) {
    padding: 18px 25px;
    font-size: 20px;
  }
}

.button__left-icon {
  margin-right: 30px;
}

img.button__left-icon {
  width: auto;
  height: 28px;
}

.button__right-icon {
  margin-left: 30px;
}

.button--sm {
  min-height: 48px;
  padding: 13px 20px;
  font-weight: 600;
  font-size: 14px;
}

.button--yellow {
  border-color: #ffdd2d;
  background: #ffdd2d;
  color: #333;

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    background-color: #fcc521;
    color: #333;
  }

  &:active:not(:disabled) {
    background-color: #fcc521;
    color: #333;
  }
}
