@use "../../common/screen";

.education-block {
  position: relative;
  display: block;
  height: 500px;

  @include screen.from(screen.$md) {
    height: 640px;
  }
}

.education-block__bg {
  position: absolute;
  inset: 0;
  display: block;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.education-block__heading {
  position: relative;
  max-width: 250px;
  padding-top: 25px;
  color: var(--color-accent);
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 140%; /* 28px */
  text-transform: uppercase;

  @include screen.from(screen.$sm) {
    max-width: 365px;
    padding-top: 50px;
    padding-left: 20px;
  }

  @include screen.from(screen.$md) {
    padding-top: 200px;
    padding-left: 30px;
  }

  @include screen.from(screen.$xl) {
    max-width: 610px;
    padding-top: 90px;
    padding-left: 70px;
    font-size: 32px;
  }
}
