@use "../../common/screen";

.product-gallery {
}

.product-gallery--has-many-photos {
  @include screen.from(screen.$xl) {
    display: grid;
    grid-template:
      'thumbnails main'
      / minmax(0, 3fr) minmax(0, 9fr);
    gap: 16px;
  }
}

.product-gallery__thumbnails {
  display: none;

  .product-gallery--has-many-photos & {
    @include screen.from(screen.$xl) {
      position: relative;
      display: block;
      grid-area: thumbnails;
    }
  }
}

.product-gallery__main {
  .product-gallery--has-many-photos & {
    @include screen.from(screen.$xl) {
      grid-area: main;
    }
  }
}

.product-gallery__main-swiper {
  --swiper-navigation-sides-offset: 0;
  --swiper-navigation-size: 56px;
  --swiper-pagination-bottom: -24px;

  display: block;
  overflow: visible;
  border: 1px solid var(--color-lines-dark);
  border-radius: 20px;
  background: #fff;

  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    color: var(--color-text-main);
    opacity: 0;
    transition: color var(--transition), opacity var(--transition);

    &:hover {
      color: var(--color-accent);
    }

    &::after {
      content: '';
      width: 32px;
      height: 32px;
      background: currentcolor;
      mask-repeat: no-repeat;
      mask-size: 100% 100%;
    }
  }

  &:hover {
    .swiper-button-prev,
    .swiper-button-next {
      opacity: 1;
    }
  }

  .swiper-button-prev {
    &::after {
      mask-image: url(svg/icons/angle-left-20.svg);
    }
  }

  .swiper-button-next {
    &::after {
      mask-image: url(svg/icons/angle-right-20.svg);
    }
  }

  @include screen.to(screen.$xl) {
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  @include screen.from(screen.$xl) {
    overflow: hidden;

    .swiper-pagination {
      display: none;
    }
  }
}

.product-gallery__slide {
  overflow: hidden;
  border-radius: 20px;

  &::before {
    content: '';
    display: block;
    padding-top: calc(3 / 5 * 100%);

    .product-gallery--has-many-photos & {
      padding-top: calc(3 / 4 * 100%);
    }
  }
}

.product-gallery__link {
  display: block;
}

.product-gallery__picture,
.product-gallery__video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  iframe {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.product-gallery__video .plyr {
  height: 100%;
}

.product-gallery__thumbnails-swiper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.product-gallery__thumbnails--has-hidden-photos {
  margin-top: 32px;
  margin-bottom: 32px;
}

.product-gallery__thumbnails-prev,
.product-gallery__thumbnails-next {
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 24px;
  color: var(--color-text-main);
  cursor: pointer;
  transition: color var(--transition), opacity var(--transition);

  &.swiper-button-disabled {
    opacity: 0.4;
  }

  &:hover:not(.swiper-button-disabled) {
    color: var(--color-accent);
  }

  &::after {
    content: '';
    width: 20px;
    height: 20px;
    background: currentcolor;
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
  }
}

.product-gallery__thumbnails-prev {
  top: -32px;

  &::after {
    transform: rotate(180deg);
    mask-image: url(svg/icons/angle-down-16.svg);
  }
}

.product-gallery__thumbnails-next {
  bottom: -32px;

  &::after {
    mask-image: url(svg/icons/angle-down-20.svg);
  }
}

.product-gallery__thumbnail-slide {
  position: relative;
  overflow: hidden;
  border: 1px solid var(--color-lines);
  border-radius: 20px;
  background: #fff;
  cursor: pointer;
  transition: border-color var(--transition);

  &:hover {
    border-color: var(--color-links-hover);
  }

  &.swiper-slide-thumb-active {
    border-color: var(--color-lines-dark);
  }
}

.product-gallery__thumbnail-slide--is-video {}

.product-gallery__thumbnail-play {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background: var(--color-text-disabled);
  box-shadow: 0 2px 8px 0 rgb(44 30 9 / 10%);
  transition: background-color var(--transition);
  transform: translate(-50%, -50%);

  .product-gallery__thumbnail-slide.swiper-slide-thumb-active & {
    background: var(--color-accent);
  }

  &::after {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background: #fff;
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    mask-image: url(svg/icons/play-20.svg);
  }
}

.product-gallery__thumbnail-picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;

    .product-gallery__thumbnail-slide--is-video & {
      object-fit: cover;
    }
  }
}
