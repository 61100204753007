@use "../../common/screen";

.dialog,
.dialog__overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.dialog {
  z-index: var(--z-index-dialog);
  display: flex;
}

.dialog[aria-hidden='true'] {
  display: none;
}

.dialog__overlay {
  @include screen.from(400px) {
    background: rgb(28 28 28 / 70%);
    animation: fade-in 200ms both;
    backdrop-filter: blur(2.5px);
  }
}

.dialog__content {
  position: relative;
  z-index: 2;
  display: flex;
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-lines-dark);
  background-color: #fff;
  box-shadow: 0 -2px 30px 0 rgb(0 0 0 / 10%);
  animation: fade-in 400ms 200ms both, slide-up 400ms 200ms both;

  @include screen.from(400px) {
    width: 374px;
    max-width: 90%;
    height: auto;
    max-height: 90%;
    margin: auto;
    border-radius: 10px;
  }
}

.dialog__content--large {
  width: 800px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(10%);
  }
}

.dialog__close {
  position: absolute;
  top: 14px;
  right: 12px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 0;
  background-color: transparent;
  color: var(--color-pink-main);
  cursor: pointer;
  transition: color var(--transition);

  &:hover,
  &:focus-visible {
    color: var(--color-accent);
  }

  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-color: currentcolor;
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    mask-image: url(svg/icons/close-30.svg);
  }

  @include screen.from(540px) {
    top: -5px;
    right: -50px;
    color: #fff;

    &:hover,
    &:focus-visible {
      color: var(--color-links-hover-light);
    }

    &::before {
      width: 30px;
      height: 30px;
    }
  }
}

.dialog__inner {
  overflow-y: auto;
  width: 100%;
  // max-height: 100%;
}

.dialog__container {
  height: 100%;
  padding: 24px;
}

.dialog__heading {
  margin-bottom: 24px;
  font-weight: 700;
  font-size: 16px;

  @include screen.from(540px) {
    font-size: 20px;
    text-align: center;
  }
}
