@use "../../common/screen";

.partnership {
}

.partnership__banner {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 410px;
  padding: 20px var(--size-gutter) 68px;

  @include screen.from(screen.$sm) {
    height: 500px;
    padding: 40px 40px 88px;
  }

  @include screen.from(screen.$md) {
    overflow: hidden;
    height: 320px;
    padding: 50px;
    border-radius: 20px;
  }

  @include screen.from(screen.$xl) {
    height: 490px;
    padding: 70px;
    border-radius: 50px;
  }
}

.partnership__banner-picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;

  img {
    object-fit: cover;
    object-position: top center;
    width: 100%;
    height: 100%;
  }
}

.partnership__banner-text {
  position: relative;
  color: var(--color-accent);
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 140%; /* 28px */
  text-transform: uppercase;

  @include screen.from(screen.$xl) {
    font-size: 32px;
  }
}

.partnership__banner-button {
  align-self: center;
  width: 100%;
  max-width: 320px;
  margin-top: auto;

  @include screen.from(screen.$md) {
    align-self: start;
  }
}

.partnership__earn {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;
  justify-items: center;

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: start;
  }

  @include screen.from(screen.$xl) {
    gap: 50px;
  }
}

.partnership__kit {
  width: 100%;
  max-width: 354px;
  padding: 12px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 20px;
  background: #fff;

  @include screen.from(screen.$sm) {
    max-width: 100%;
    padding: 20px;
  }

  @include screen.from(screen.$xl) {
    padding: 24px 73px;
  }
}

.partnership__kit-picture {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 20px;

  &::before {
    content: '';
    display: block;
    padding-top: calc(165 / 296 * 100%);
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.partnership__kit-button {
  display: flex;
  align-items: center;
  padding: 0;
  background: var(--color-accent-light);
  color: #fff;
  text-align: center;
}

.partnership__kit-button-icon {
  display: flex;
  flex: 0 1 40px;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.partnership__kit-button-text {
  flex: 1 0 0;
  min-height: 40px;
  padding: 11px;
  border: 1px solid var(--color-accent-light);
  border-left: 1px solid #fff;
  background: var(--color-accent-light);
  font-weight: 500;
  text-transform: uppercase;
  transition: color var(--transition), background-color var(--transition),
    border-color var(--transition);

  .partnership__kit:hover &,
  .partnership__kit:focus-visible & {
    border-left-color: var(--color-accent-light);
    background: #fff;
    color: var(--color-accent-light);
  }
}

.partnership__earn-subheading {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--color-lines);
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: 130%;

  @include screen.from(screen.$xl) {
    margin-bottom: 24px;
    padding-bottom: 24px;
  }
}

.partnership__earn-text {
  @include screen.from(screen.$xl) {
    font-size: 20px;
  }
}

.partnership__videos {
  background: #ebf0ff;

  @include screen.from(screen.$xl) {
    background: transparent;
  }
}

.partnership__videos-inner {
  padding: 20px;
  background: #ebf0ff;

  @include screen.from(screen.$md) {
    padding: 30px 113px;
  }

  @include screen.from(screen.$xl) {
    padding: 50px 60px;
    border-radius: 20px;
  }
}

.partnership__videos-list {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;

  @include screen.from(screen.$sm) {
    gap: 40px;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0;
  }
}
