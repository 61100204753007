@use '../../common/screen';

.select {
  position: relative;
  display: block;
  min-width: 260px;
}

.select__label {
  display: block;
  margin-bottom: 8px;
  color: var(--color-text-main);
}

.ts-hidden-accessible {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  word-wrap: normal !important;
  clip-path: inset(50%);
}

.select__wrapper {
  position: relative;
  display: flex;
  transition: box-shadow var(--transition);

  &.dropdown-active {
    box-shadow: 0 20px 35px 0 rgb(40 33 37 / 6%);
  }

  &.plugin-dropdown_input.has-items .items-placeholder {
    display: none !important;
  }
}

select.select__select {
  // original select input should have the same style is TOM's control, to avoid FOUC
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  min-height: 40px;
  padding: 11px 64px 10px 18px;
  border: 1px solid var(--color-accent);
  border-radius: 0;
  background: var(--color-bg) url(svg/icons/chevron-down-16.svg) no-repeat right 26px center;
  outline: none;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: border-color var(--transition), box-shadow var(--transition);
  appearance: none;

  &:hover,
  &:focus {
    // border-color: var(--color-grey-60);
  }
}

.select__control {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  width: 100%;
  min-height: 40px;
  padding: 11px 64px 10px 18px;
  border: 1px solid var(--color-accent-light);
  border-radius: 0;
  outline: none;
  cursor: pointer;
  transition: border-color var(--transition), box-shadow var(--transition);

  .select__wrapper:hover & {
    // border-color: var(--color-grey-60);
  }

  .select__wrapper.focus & {
    // border-color: var(--color-grey-60);
  }

  .select__wrapper.single & {
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 18px;
      display: block;
      width: 16px;
      height: 16px;
      background-color: var(--color-text-main);
      transition: transform 0.5s ease;
      transform: translateY(-50%);
      mask-image: url(svg/icons/chevron-down-16.svg);
    }
  }

  .select.has-error & {
    border-color: var(--color-error);

    &:focus {
      box-shadow: var(--shadow-error);
    }
  }

  .select__wrapper.single.dropdown-active & {
    &::after {
      transform: translateY(-50%) scaleY(-1);
    }
  }

  .items-placeholder {
    &::placeholder {
      color: var(--color-text-main);
      opacity: 1;
    }
  }

  & > * {
    display: inline-block;
    vertical-align: baseline;
  }

  & > input {
    display: inline-block !important;
    flex: 1 1 auto;
    min-width: 7rem;
    max-width: 100% !important;
    min-height: 0 !important;
    max-height: none !important;
    margin: 0 !important;
    padding: 0 !important;
    border: 0 none !important;
    background: none !important;
    box-shadow: none !important;
    font-size: 14px;
    line-height: 20px;
    text-indent: 0 !important;
    user-select: auto !important;

    &:focus {
      outline: none !important;
    }

    &::-ms-clear {
      display: none;
    }

    .select__wrapper.has-items & {
      margin: 0 4px !important;
    }

    .select__wrapper:not(.has-items) &:not(.items-placeholder) {
      position: absolute;
      left: -10000px;
      opacity: 0;
    }

    .select__wrapper.input-hidden &:not(.items-placeholder) {
      position: absolute;
      left: -10000px;
      opacity: 0;
    }
  }
}

.select__item {
  display: flex;
  align-items: center;

  @include screen.from(screen.$md) {
    font-size: 14px;
  }
}

.select__dropdown {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  z-index: 10;
  box-sizing: border-box;
  width: 100%;
  min-width: 100%;
  padding: 5px 18px;
  border: 1px solid transparent;
  border-radius: 0;
  background: var(--color-bg);
  box-shadow: 0 8px 36px 0 rgb(0 0 0 / 20%);

  &[data-selectable] {
    overflow: hidden;
    cursor: pointer;
  }

  .select__wrapper.dropdown-active & {
    transform-origin: top center;
    animation-name: select-dropdown-animation;
    animation-duration: 0.2s;
    animation-timing-function: var(--transition-ease-in-out);
    animation-fill-mode: both;
  }
}

@keyframes select-dropdown-animation {
  0% {
    opacity: 0;
    transform: scaleY(0);
  }

  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}

.select__dropdown-content {
  overflow-x: hidden;
  overflow-y: auto;
  overflow-scrolling: touch;
  max-height: 216px;
  scroll-behavior: smooth;
}

.select__option {
  position: relative;
  padding: 7px 0;
  color: var(--color-black);
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  transition: color var(--transition);

  &.selected {
    font-weight: 700;
    text-decoration: underline;
  }

  &:hover,
  &:focus-visible {
    color: var(--color-links-hover);
  }
}
