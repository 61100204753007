@use "../../common/screen";

.directions__text {
  margin-bottom: 20px;
  font-size: 16px;

  @include screen.from(screen.$sm) {
    margin-bottom: 40px;
  }

  @include screen.from(screen.$md) {
    max-width: 550px;
    margin: 0 auto 40px;
    text-align: center;
  }
}

.directions__tabs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 18px;

  @include screen.from(screen.$xl) {
    margin-bottom: 40px;
  }
}

.directions__tab {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 25px;
  border-bottom: 3px solid #9B9B9B;
  background: transparent;
  color: #9B9B9B;
  outline: none;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 140%; /* 28px */
  letter-spacing: 0.4px;
  text-align: center;
  transition: border-color var(--transition), color var(--transition);
  appearance: none;

  &.is-active {
    border-color: var(--color-text-main);
    color: var(--color-text-main);
  }

  @include screen.from(screen.$xl) {
    font-size: 20px;
  }
}

.directions__select {
  width: 100%;
  max-width: 210px;

  @include screen.from(screen.$sm) {
    max-width: 50%;
  }

  @include screen.from(screen.$lg) {
    max-width: 274px;
  }
}

.directions__content {
  & > * + * {
    margin-top: 1.5em;
  }

  h4 {
    font-weight: 700;
    text-decoration: underline;
  }

  @include screen.from(screen.$md) {
    width: 50%;
  }
}
