@use "../common/screen";

.bx-soa-more-btn {
  display: flex;
  justify-content: space-between;
}

#bx-soa-region {
  .bx-soa-custom-label {
    display: block;
    margin-bottom: 8px;
  }
}

.bx-soa-pp {
  .bx-soa-pp-desc-container {
    padding-top: 0;
  }

  .bx-soa-pp-company-subTitle {
    margin-bottom: 8px;
  }

  .bx-soa-pp-item-container {
    .row {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 12px 8px;
      margin: 0;

      @include screen.from(screen.$xl) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    }

    .bx-soa-pp-company {
      width: auto;
      padding: 0;
    }

    .bx-soa-pp-company-image {
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
    }
  }
}

.bx-soa-customer-field {
  margin-bottom: 16px;
  
  .bx-soa-custom-label {
    display: block;
    margin-bottom: 4px;
  }
}
