@use "../common/screen";

.form-block {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;
  justify-items: center;

  @include screen.from(screen.$sm) {
    gap: 30px;
    padding: 0 50px;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: 455px minmax(0, 1fr);
    gap: 20px;
    justify-items: start;
    padding: 0;
  }
}

.form-block__text {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 130%;
  text-align: center;

  @include screen.from(screen.$xl) {
    font-size: 20px;
    text-align: left;
  }
}

.form-block__form {
  width: 100%;
  padding: 20px 14px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 -2px 30px 0 rgb(0 0 0 / 10%);

  @include screen.from(screen.$sm) {
    padding: 24px 14px;
  }

  @include screen.from(screen.$md) {
    max-width: 626px;
    padding: 24px 72px;
  }
}

.form-block__form-heading {
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: normal;
  text-align: center;
}

.form-block__form-text {
  margin-top: 8px;
  margin-bottom: 20px;
  color: #acacac;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
}
