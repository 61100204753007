@use 'screen';

:root {
  --color-red: #f93939;
  --color-dark: #25314c;
  --color-bg: #fff;
  --color-text-main: #25314c;
  --color-text-alt: #969eaf;
  --color-text-alt2: #9b9b9b;
  --color-text-disabled: #b6b6b6;
  --color-accent: #315cc1;
  --color-accent-light: #6189e9;
  --color-outline: #315cc1;
  --color-lines: #c7c7c7;
  --color-lines-dark: #324989;
  --color-links: #324989;
  --color-links-hover: var(--color-accent);
  --color-links-hover-light: #ebf0ff;
  --size-container-width: 480px;
  --size-container-sidegap: 16px;
  --size-gutter: 20px;
  --transition: 0.3s ease;
  --z-index-dropdown: 500;
  --z-index-popup: 500;
  --z-index-mobile-bar: 500;
  --z-index-dialog: 600;

  @include screen.from(screen.$xs) {
    --size-container-sidegap: 20px;
  }

  @include screen.from(screen.$md) {
    --size-container-width: 768px;
  }

  @include screen.from(screen.$xl) {
    --size-container-width: 1200px;
  }
}

html {
  width: 100%;
  min-height: 100%;
  scroll-padding-top: 50px;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

body {
  min-height: 100%;
  background: linear-gradient(
    171deg,
    #fff 1.53%,
    #d3d3d3 28.29%,
    #fff 49.89%,
    #d2d2d2 72.43%,
    #fbfbfb 91.68%
  );
  color: var(--color-text-main);
  font-weight: 400;
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 1.2;

  &::-webkit-scrollbar-thumb {
    border-radius: 999px;
    background-color: var(--color-text-main);
  }
}

::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 999px;
  background-color: rgb(0 0 0 / 8%);
}

::-webkit-scrollbar-track {
  border-radius: 999px;
  background-color: transparent;
}

::selection {
  background-color: var(--color-accent);
  color: #fff;
}

* {
  // show outline only with keyboard interaction
  outline: none;

  &:focus-visible {
    // replace with shadowbox on rounded objects!
    outline: 3px solid var(--color-outline);
    outline-offset: 2px;
  }
}

img:not([src], [srcset]) {
  visibility: hidden;
}

// alpine.js
[x-cloak] {
  display: none !important;
}
