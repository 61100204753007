@use "../../common/screen";

.main-menu {
  @include screen.to(screen.$xl) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: var(--z-index-popup);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    width: 100%;
    max-height: 100vh;
    background: #fff;
    box-shadow: 0 8px 36px 0 rgb(0 0 0 / 20%);
    visibility: hidden;
    transform: translateY(-100%);

    &.is-opened {
      visibility: visible;
      animation: menu-open 0.3s ease both;
    }
  }
}

@keyframes menu-open {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: none;
  }
}

.main-menu__inner {
  @include screen.to(screen.$xl) {
    position: relative;
    display: block;
    width: 100%;
    max-width: var(--size-container-width);
    margin: auto;
    padding: 24px var(--size-container-sidegap) 24px;
  }
}

.main-menu__close {
  position: absolute;
  top: 20px;
  right: var(--size-container-sidegap);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  background: transparent;
  color: var(--color-text-main);
  outline: none;
  transition: color var(--transition);
  appearance: none;

  &:hover,
  &:focus-visible {
    color: var(--color-links-hover);
  }

  @include screen.from(screen.$xl) {
    display: none;
  }
}

.main-menu__title {
  margin-bottom: 40px;
  color: var(--color-accent);
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 140%; /* 28px */
  text-align: center;
  text-transform: uppercase;

  @include screen.from(screen.$md) {
    text-align: left;
  }

  @include screen.from(screen.$xl) {
    display: none;
  }
}

.main-menu__list {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include screen.from(screen.$xl) {
    display: flex;
    gap: 0;

    .i {
      display: none;
    }
  }
}

.main-menu__item {
  &.is-mobile-only {
    @include screen.from(screen.$xl) {
      display: none;
    }
  }

  &.is-desktop-only {
    @include screen.to(screen.$xl) {
      display: none;
    }
  }

  @include screen.from(screen.$xl) {
    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}

.main-menu__link {
  display: block;

  @include screen.to(screen.$xl) {
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    min-height: 60px;
    padding: 16px 30px;
    border: 1px solid #315cc1;
    border-radius: 16px;
    background: #315cc1;
    color: #fff;
    outline: none;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    transition: background-color var(--transition), color var(--transition);
    appearance: none;

    &:hover:not(:disabled),
    &:focus-visible:not(:disabled) {
      background-color: #e8efff;
      color: #315cc1;
    }

    &:active:not(:disabled) {
      background-color: #fff;
      color: #315cc1;
    }

    .i {
      width: 16px;
      height: 16px;
      margin-right: 20px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 28px;
      display: block;
      width: 20px;
      height: 20px;
      background-color: currentcolor;
      transform: translateY(-50%);
      mask-repeat: no-repeat;
      mask-size: 100% 100%;
      mask-image: url(svg/icons/angle-right-20.svg);
    }
  }

  @include screen.from(screen.$xl) {
    padding: 14px 0;
    transition: color var(--transition);

    &:hover,
    &:focus-visible {
      color: var(--color-links-hover);
    }
  }
}
