.smart-filter {
  width: 100%;
  min-width: 220px;
  margin-bottom: 20px;
}

.smart-filter-section {
  position: relative;
}

.smart-filter-title {
  margin-bottom: 10px;
  color: #000;
  font-size: 18px;
}

.smart-filter-parameters-box {
  position: relative;
  border-bottom: 1px solid var(--color-lines);

  &:first-of-type {
    border-top: 1px solid var(--color-lines);
  }

  &.bx-active {
    padding-bottom: 15px;
  }
}

.smart-filter-parameters-box-title {
  position: relative;
  display: block;
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: normal;
}

.smart-filter-parameters-box-title-text {
  display: block;
  padding: 15px 0;
  cursor: pointer;
}

.bx-active .smart-filter-parameters-box-title-text {
  color: #333;
}

/* region Angles */
.smart-filter-angle {
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: var(--color-text-main);
  transition: transform 0.5s ease;
  transform: translateY(-50%);
  mask-image: url(svg/icons/chevron-down-16.svg);
}

.smart-filter-angle-up {
  transform: translateY(-50%) scaleY(-1);
}

/* endregion */

/* region hint */
.smart-filter-hint {
  position: relative;
  display: inline-block;
  margin-right: 6px;
}

.smart-filter-hint-icon {
  display: block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
  padding: 0 5px;
  border-radius: 50%;
  background-color: var(--primary);
  color: #fff;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}

.smart-filter-hint-popup {
  position: absolute;
  top: calc(100% + 8px);
  left: -7px;
  z-index: 10;
  display: none;
  min-width: 200px;
  max-width: 300px;
  padding: 10px;
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 0 5px 15px 0 rgb(0 0 0 / 14%);
}

.smart-filter-hint:hover .smart-filter-hint-popup {
  display: block;
}

.smart-filter-hint-popup-angle {
  position: absolute;
  top: -5px;
  left: 10px;
  width: 10px;
  height: 10px;
  border-top: 1px solid #dfdfdf;
  border-left: 1px solid #dfdfdf;
  background-color: #fff;
  transform: rotate(45deg);
}

/* endregion */

.smart-filter-block {
  display: none;
  box-sizing: content-box;
  transition: padding 0.3s ease;
}

.smart-filter-parameters-box.bx-active .smart-filter-block {
  display: block;
}

.smart-filter-button-box {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-top: 8px;
}

.smart-filter__submit,
.smart-filter__reset {
  padding: 12px;
  border: 0;
  background: transparent;
  outline: 0;
  font-weight: 500;
  cursor: pointer;
  transition: color var(--transition);
  appearance: none;

  &:hover,
  &:focus-visible {
    color: var(--color-links-hover);
  }
}

.smart-filter__reset {
  color: var(--color-text-alt);
}

.smart-filter-parameters-box.bx-active .smart-filter-block i.bx-ft-sub {
  display: block;
  padding-bottom: 5px;
  font-style: normal;
  font-size: 14px;
}

/* region NUMBERS_WITH_SLIDER */
.smart-filter-input-group-number {
}

/* endregion */

/* region TRACK FOR TYPE NUMBER */

.smart-filter-slider-track-container {
  overflow: hidden;
  padding: 25px 0;
}

.smart-filter-slider-track {
  position: relative;
  height: 6px;
  background: var(--color-lines);
}

.smart-filter-slider-ruler {
  display: none;
}

.smart-filter-slider-range {
  z-index: 100;
}

.smart-filter-slider-range,
.smart-filter-slider-price-bar,
.smart-filter-slider-price-bar-vd,
.smart-filter-slider-price-bar-vn,
.smart-filter-slider-price-bar-v {
  position: absolute;
  top: 0;
  bottom: 0;
}

.smart-filter-slider-price-bar {
  z-index: 100;
}

.smart-filter-slider-price-bar-vd {
  z-index: 60;
  background: #6189e9;
}

.smart-filter-slider-price-bar-vn {
  z-index: 70;
  background-color: var(--theme-color-light);
}

.smart-filter-slider-price-bar-v {
  z-index: 80;
  background-color: var(--theme-color-primary);
}

.smart-filter-slider-handle {
  position: absolute;
  top: -3px;
  z-index: 1;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: var(--color-dark);
}

.smart-filter-slider-handle:hover {
  cursor: grab;
}

.smart-filter-slider-handle:active {
  cursor: grabbing;
}

.smart-filter-slider-handle.left {
  left: 0;
}

.smart-filter-slider-handle.right {
  right: 0;
}

/* endregion */

/* region Select */
.smart-filter-input-group-dropdown {
  display: block;
  height: 31px;
  border: 1px solid #ccd5db;
  border-radius: 3px;
}

.smart-filter-input-group-dropdown.bx-active,
.smart-filter-input-group-dropdown:focus {
  border-color: var(--primary);
}

.smart-filter-dropdown-block {
  position: relative;
  display: block;
  width: 100%;
  height: 29px;
  margin: 0;
  padding: 0 29px 0 10px;
  border: none;
  border-radius: 2px;
  background: #fff;
}

.smart-filter-dropdown-text {
  vertical-align: middle;
  overflow: hidden;
  max-width: 100%;
  height: 29px;
  line-height: 29px;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.smart-filter-dropdown-text.fix {
  min-width: 120px;
}

.smart-filter-dropdown-text label {
  cursor: pointer;
}

.smart-filter-dropdown-arrow {
  position: absolute;
  top: 0;
  right: 0;
  width: 29px;
  height: 29px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAECAYAAABCxiV9AAAAG0lEQVQY02OYOXPmf1yYAQRwSsAATglkBch8ADy7LKHqFrgoAAAAAElFTkSuQmCC)
    no-repeat center;
  cursor: pointer;
}

.smart-filter-input-group-dropdown-flex {
  display: flex;
  align-items: center;
}

.smart-filter-input-group-dropdown-flex .smart-filter-checkbox-btn-image {
  margin-right: 5px;
}

/*
*	Select popup
*
*/

.smart-filter-dropdown-popup {
  min-width: 173px;
  padding: 0;
  background: #fff;
}

.smart-filter-dropdown-popup ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.smart-filter-dropdown-popup ul li {
  display: block;
  vertical-align: middle;
}

.smart-filter-dropdown-popup ul li:first-child {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e5e5e5;
}

.smart-filter-dropdown-popup ul li label {
  display: flex;
  align-items: center;
  overflow: hidden;

  /* padding: 0 5px; */
  width: 100%;
  color: #575757;
  white-space: nowrap;
  cursor: pointer;
}

.smart-filter-dropdown-popup ul li label.selected,
.smart-filter-dropdown-popup ul li label:hover {
  background: #f3f8fa;
  color: #000;
}

.smart-filter-dropdown-popup ul li label.disabled {
  background: transparent;
  color: #b8b8b8;
}

.smart-filter-dropdown-popup ul li .smart-filter-dropdown-text {
  padding-left: 5px;
}

/* endregion */

/* region TAGS */

.smart-filter-tag {
  padding: 18px;
  border-top: 1px solid var(--theme-color-light);
  border-bottom: 1px solid var(--theme-color-light);
}

.smart-filter .bx-tag-link {
  color: var(--theme-color-light);
  font-size: 12px;
  text-decoration: none;
  text-shadow: none;
}

.smart-filter .bx-tag-link.bx-active,
.smart-filter .bx-tag-link:hover {
  color: #1d3d46;
}

/* endregion */

/* region CHECKBOXES_WITH_PICTURES */
.smart-filter-input-group-checkbox-pictures {
}

.smart-filter-input-group-checkbox-pictures .smart-filter-param-label {
  display: block;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
}

/* endregion */

/* region CHECKBOXES_WITH_PICTURES_AND_LABELS */
.smart-filter-input-group-checkbox-pictures-text {
}

.smart-filter-input-group-checkbox-pictures-text .smart-filter-checkbox-label {
  display: flex;
  align-items: center;
}

.smart-filter-input-group-checkbox-pictures-text .smart-filter-checkbox-text {
  padding-left: 5px;
}

/* endregion */

/* region LABEL */
.smart-filter-checkbox-btn,
.smart-filter-checkbox-text-btn {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 31px;
  height: 31px;
  padding: 3px;
  border: 1px solid #ccd5db;
  border-radius: 2px;
  background: #fff;
  color: #000;
  font-weight: normal;
  line-height: 23px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: border 0.3s ease, box-shadow 0.1s ease, color 0.3s ease;
}

.smart-filter-checkbox-btn:hover,
.smart-filter-checkbox-btn:active,
.smart-filter-checkbox-btn:focus,
.smart-filter-checkbox-label.bx-active .smart-filter-checkbox-btn,
.smart-filter-checkbox-label.bx-active .smart-filter-checkbox-btn,
.smart-filter-checkbox-text-label.bx-active .smart-filter-checkbox-btn {
  border-color: var(--primary);
}

.smart-filter-checkbox-text,
.smart-filter-dropdown-text {
  font-size: 14px;
}

.smart-filter-checkbox-label.disabled .smart-filter-checkbox-text,
.smart-filter-dropdown-label.disabled .smart-filter-dropdown-text,
.smart-filter-checkbox-text-label.disabled .smart-filter-checkbox-text,
.smart-filter-input-group-checkbox-list.disabled .smart-filter-checkbox-text {
  color: #6c6c6c;
}

.smart-filter-checkbox-label.disabled .smart-filter-checkbox-btn,
.smart-filter-checkbox-text-label.disabled .smart-filter-checkbox-btn {
  position: relative;
  opacity: 0.7;
}

.smart-filter-checkbox-label.disabled .smart-filter-checkbox-btn::before,
.smart-filter-checkbox-text-label.disabled .smart-filter-checkbox-btn::before {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAA/UlEQVQ4y5WSQQ6CQAxFOSBuwAtoAitc4R6MkKgYJULUi8Ax4DgKm9o/45BxBBNJmkB/33T6qUVEHkfDUXHY1sQD7V2DWg+JJox3lOZ3enYdf5IzAjnQkvxGa64Fg2S9PV3JXa4ozoovWEGbY0nOIqDkfIVeQ5hBAOQsAy5guO8FbEJxVqqDZ+rUuQ6rziJ3VLkBcs05BBxlF1nIADrJESYgE0YhAER0KH5DphEKFDNPuG1AhTAi4jnRWV17FNYhcT3NHLzLzuUnjI0YHP203EWY8ENqNsAq5Y3Qf4NuhIT7wTBsDxgIbRjvCfCUe6ozoFCuXIukj5c/lhy1/gvSDM3b8PrjegAAAABJRU5ErkJggg==)
    no-repeat center;
  opacity: 0.7;
}

.smart-filter-number-label,
.smart-filter-dropdown-label,
.smart-filter-checkbox-label,
.smart-filter-checkbox-text-label {
  min-height: 20px;
  font-weight: normal;
  cursor: pointer;
}

.smart-filter-checkbox-btn-image {
  display: block;
  vertical-align: middle;
  width: 23px;
  height: 23px;
  border-radius: 2px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 5%);
  line-height: 24px;
}

.smart-filter-checkbox-btn-image.all {
  position: relative;
  display: inline-block;
  border: none;
  box-shadow: none;
}

.smart-filter-checkbox-btn-image.all::after,
.smart-filter-checkbox-btn-image.all::before {
  content: ' ';
  position: absolute;
  width: 60%;
  height: 60%;
  background: #fff;
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 20%);
}

.smart-filter-checkbox-btn-image.all::after {
  right: 0;
  bottom: 0;
}

.smart-filter-checkbox-btn-image.all::before {
  top: 0;
  left: 0;
}

/* endregion */

/* region Checkbox / Radio */
.smart-filter-input-group-checkbox-list {
  display: grid;
  grid-template-columns: 100%;
  gap: 9px;
}

.smart-filter-checklist {
  margin: 0;
  padding: 0;
  list-style: none;
}

.smart-filter__checkbox {
  position: relative;
}

.smart-filter__checkbox-input {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  white-space: nowrap;
  clip-path: inset(50%);
}

.smart-filter__checkbox-label {
  padding-left: 25px;
  font-weight: 500;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 14px;
    height: 14px;
  }

  &::before {
    background: url(svg/checkbox.svg) no-repeat center;
  }

  &::after {
    background: url(svg/checkbox-checked.svg) no-repeat center;
    opacity: 0;
    transition: opacity var(--transition);
  }

  .smart-filter__checkbox-input:checked + & {
    &::after {
      opacity: 1;
    }
  }
}

/* endregion */

/* COLOR */

/* --- colors */
.smart-filter-param-btn.bx-color-sl {
}

.smart-filter-param-label.disabled .smart-filter-param-btn.bx-color-sl {
  position: relative;
  background: transparent;
  box-shadow: none;
}

.smart-filter-param-label.disabled .smart-filter-param-btn.bx-color-sl::before {
  content: ' ';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: block;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAA/UlEQVQ4y5WSQQ6CQAxFOSBuwAtoAitc4R6MkKgYJULUi8Ax4DgKm9o/45BxBBNJmkB/33T6qUVEHkfDUXHY1sQD7V2DWg+JJox3lOZ3enYdf5IzAjnQkvxGa64Fg2S9PV3JXa4ozoovWEGbY0nOIqDkfIVeQ5hBAOQsAy5guO8FbEJxVqqDZ+rUuQ6rziJ3VLkBcs05BBxlF1nIADrJESYgE0YhAER0KH5DphEKFDNPuG1AhTAi4jnRWV17FNYhcT3NHLzLzuUnjI0YHP203EWY8ENqNsAq5Y3Qf4NuhIT7wTBsDxgIbRjvCfCUe6ozoFCuXIukj5c/lhy1/gvSDM3b8PrjegAAAABJRU5ErkJggg==)
    no-repeat center;
  opacity: 0.7;
}

.smart-filter-param-label .smart-filter-param-btn:hover,
.smart-filter-param-label.bx-active .smart-filter-param-btn {
  border-color: var(--primary);
}

/*
* BTN P/M
*
*/

.smart-filter-param-btn.bx-spm {
  vertical-align: top;
  width: 23px;
  min-width: 23px;
  height: 23px;
  padding: 0;
  border-radius: 50%;
  background-color: transparent;
  color: #767b7e;
  font-weight: normal;
  font-size: 17px;
  line-height: 23px;
}

.smart-filter-param-btn.bx-spm.bx-plus {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAMCAYAAAC0qUeeAAAANUlEQVQoz2NgwAQsQMzJQCSwAeKGQa7YFKoAhmOgim3QsAxIcSFUkhC2GaqhgQ4UgNgFmwQAij8OUFmBFssAAAAASUVORK5CIIA=)
    no-repeat center;
}

.smart-filter-param-btn.bx-spm.bx-minus {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAACCAYAAABhYU3QAAAAG0lEQVQYV2NgYGDgBuI4AlgGiBn4gLicAFYGAAFkBwE95V42AAAAAElFTkSuQmCC)
    no-repeat center;
}

.smart-filter-param-btn.bx-spm:active {
  box-shadow: inset 0 2px 3px rgb(0 0 0 / 12%);
  outline: none !important;
}

.smart-filter span.bx-spm {
  display: inline-block;
  vertical-align: middle;
  height: 23px;
  margin: 0 10px;
  color: #000;
  font-size: 18px;
  line-height: 23px;
}

/*
*	icon
*
*/

.smart-filter-select-popup .smart-filter-select-text-icon,
.smart-filter-select-text-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 23px;
  height: 23px;
  margin-top: -4px;
  margin-right: 2px;
  border-radius: 2px;
  line-height: 33px;
}

.smart-filter-select-popup .smart-filter-btn-color-icon,
.smart-filter-btn-color-icon {
  display: block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background-size: cover;
  box-shadow: inset 0 0 0 1px rgb(0 0 0 / 5%);
  line-height: 24px;
}

.smart-filter-select-popup .smart-filter-btn-color-icon {
  top: 0 !important;
  width: 20px;
  height: 20px;
  margin: 2px 0;
}

.smart-filter-select-block .smart-filter-btn-color-icon {
  top: -1px !important;
}

.smart-filter-param-label.disabled .smart-filter-btn-color-icon {
  opacity: 0.2;
}

/*
* 	BIZ
*
*/

.smart-filter-parameter-biz {
  display: inline-block;
  margin-right: 10px;
  border-bottom: 1px dashed;
  color: #1485ce;
  font-size: 14px;
  line-height: 15px;
  text-decoration: none;
}

.smart-filter-parameter-biz:hover {
  border-bottom: 1px solid;
  text-decoration: none;
}

.smart-filter-parameter-biz.bx-active {
  position: relative;
  border-color: transparent;
  color: #000;
}

/*
*	BTN result and reset
*
*/

.btn-themes {
  color: #fff;
}

.btn-themes.bx-active,
.btn-themes.focus,
.btn-themes:active,
.btn-themes:focus,
.btn-themes:hover {
  color: #fff;
}

/*
* 	Result Popup
*
*/

.smart-filter-popup-result {
  position: absolute;
  z-index: 915;
  display: none;
  min-height: 37px;
  margin-top: -9px;
  margin-left: 10px;
  padding: 6px 18px 8px 24px;
  border: 0;
  background: #6189e9;
  color: #fff;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  white-space: nowrap;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    width: 0;
    height: 0;
    border-width: 6px 6px 6px 0;
    border-style: solid;
    border-color: transparent #6189e9 transparent transparent;
    transform: translateY(-50%);
  }
}

.smart-filter-popup-result.right {
  left: 97%;
}

.smart-filter-popup-result.left {
  right: 103%;
}

.smart-filter-horizontal .smart-filter-popup-result {
  position: relative;
  right: auto;
  bottom: -7px;
  left: auto;
  display: inline-block;
}

.smart-filter-popup-result a {
  color: #fff;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  text-decoration: underline;
}

.smart-filter-popup-result a:hover {
}

.smart-filter-popup-result-close {
  display: none;
}

@media (width <= 767px) {
  .bx-touch .smart-filter-popup-result {
    position: fixed !important;
    top: auto !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    z-index: 1001;
    width: auto;
    height: auto;
    margin-top: 0;
    margin-left: 0;
    padding: 10px;
    border: 1px solid #c6dae7;
    border-radius: 2px;
    background: rgb(255 255 255 / 90%);
    font-size: 12px;
    line-height: 14px;
  }

  .bx-touch .smart-filter-popup-result br {
    display: none;
  }

  .smart-filter-popup-result .arrow {
    display: none;
  }
}

.smart-filter__number-group {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  gap: 9px;
  align-items: center;
}

.smart-filter__number-input {
  max-width: 100%;
  min-height: 30px;
  padding: 5px 10px;
  border: 1px solid #acacac;
  background: transparent;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  appearance: none;

  &:disabled {
    opacity: 0.6;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border-color: var(--color-accent-light);
  }

  &::-ms-clear {
    display: none;
  }

  &::placeholder {
    color: var(--color-text-alt);
    font-weight: 400;
    opacity: 1;
  }
}
