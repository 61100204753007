@use "../common/screen";

.generic-content {
  & > * + * {
    margin-top: 1.5em;
  }

  h2 {
    margin: 2rem 0;
    color: var(--color-accent);
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;

    @include screen.from(screen.$xl) {
      font-size: 24px;
    }
  }

  a:not([class]) {
    color: var(--color-links);
    transition: color var(--transition);

    &:hover,
    &:focus-visible {
      color: var(--color-links-hover);
    }
  }

  ul:not([class]) li {
    position: relative;
    padding-left: 24px;

    &::before {
      content: '';
      position: absolute;
      top: 7px;
      left: 6px;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: var(--color-accent);
    }
  }

  ol:not([class]) {
    counter-reset: list;

    li {
      position: relative;
      margin-top: 1em;
      counter-increment: list;

      &::before {
        content: counters(list, '.') ".\00a0";
      }
    }
  }

  @include screen.from(screen.$sm) {
    font-size: 16px;
  }

  @include screen.from(screen.$xl) {
    font-size: 20px;
  }
}

.generic-content--is-centered {
  max-width: 700px;
  margin: 0 auto;
}

.generic-content__date {
  display: block;
  margin-top: 30px;
  color: var(--color-accent);
  font-weight: 600;
  line-height: 130%;
  text-align: right;
  text-transform: uppercase;
}
