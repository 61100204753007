@use "../common/screen";

.white-card-block {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 456px;
  padding: 20px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 30px;
  background: #fff;

  @include screen.from(screen.$sm) {
    min-height: 490px;
  }

  @include screen.from(screen.$md) {
    padding: 40px 40px 60px;
  }

  @include screen.from(screen.$xl) {
    padding: 50px 128px;
  }
}

.white-card-block__picture {
  display: none;

  @include screen.from(screen.$sm) {
    position: absolute;
    right: -110px;
    bottom: 0;
    display: block;
    width: 266px;

    img {
      position: relative;
    }
  }

  @include screen.from(screen.$md) {
    right: 20px;
    width: 328px;
  }

  @include screen.from(screen.$xl) {
    right: 80px;
    bottom: -40px;
    width: 450px;
    height: 100%;
  }
}

.white-card-block__text {
  position: relative;
  margin-bottom: 50px;

  @include screen.from(screen.$sm) {
    max-width: 315px;
  }

  @include screen.from(screen.$xl) {
    max-width: 520px;
    font-size: 20px;
  }
}

.white-card-block__more {
  margin-top: auto;
}

.white-card-block__button {
  width: 100%;

  @include screen.from(screen.$sm) {
    max-width: 274px;
  }
}
