@use "../common/screen";

.reviews {
  margin-bottom: 30px;
}

.reviews__list {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;

  @include screen.from(screen.$xl) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 40px;
  }
}

.reviews__item {
  display: grid;
  grid-template:
    'photo name'
    'photo props'
    'text text' 1fr
    'more more'
    / 60px minmax(0, 1fr);
  gap: 15px 20px;
  height: auto;
  padding: 20px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 20px;
  background: #fff;

  @include screen.from(screen.$md) {
    grid-template:
      'photo name'
      'photo props'
      'text text' 1fr
      'more more'
      / 80px minmax(0, 1fr);
  }

  @include screen.from(screen.$xl) {
    padding: 28px;

    &:nth-of-type(5) {
      grid-column: span 2;
    }
  }
}

.reviews__photo {
  grid-area: photo;
  overflow: hidden;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: url(blocks/reviews-preview/photo-bg.svg) no-repeat center;
  background-size: cover;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @include screen.from(screen.$md) {
    width: 80px;
    height: 80px;
  }
}

.reviews__name {
  grid-area: name;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: normal;

  @include screen.from(screen.$xl) {
    font-size: 20px;
  }
}

.reviews__props {
  grid-area: props;
  color: var(--color-text-alt);
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: normal;

  @include screen.from(screen.$xl) {
    font-size: 14px;
  }
}

.reviews__text {
  grid-area: text;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 130%;

  iframe {
    width: 100%;
  }
}
