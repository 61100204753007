@use "../../common/screen";

.about {
}

.about__heading {
  margin-bottom: 20px;

  @include screen.from(screen.$sm) {
    margin-bottom: 30px;
  }
}

.about__text-line {
  padding: 10px var(--size-container-sidegap);
  background: var(--color-accent-light);
  color: #fff;
  font-weight: 600;
  text-align: center;

  @include screen.from(screen.$sm) {
    font-size: 16px;
  }

  @include screen.from(screen.$md) {
    padding: 10px 0;
  }

  @include screen.from(screen.$xl) {
    padding: 8px;
    font-size: 20px;
  }
}

.about__main {
  display: grid;
  grid-template:
    'text2'
    'photo'
    'text1'
    'text3'
    / 100%;
  gap: 20px;
  align-items: start;
  line-height: 130%;

  @include screen.from(screen.$md) {
    display: grid;
    grid-template:
      'photo text2'
      'text1 text3'
      / minmax(0, 1fr) minmax(0, 1fr);
  }

  @include screen.from(screen.$xl) {
    display: grid;
    grid-template:
      'photo text1'
      'photo text2'
      'photo text3'
      / 493px minmax(0, 1fr);
    row-gap: 16px;
    column-gap: 50px;
  }
}

.about__main-text1 {
  grid-area: text1;

  @include screen.from(screen.$xl) {
    padding-top: 20px;
    border-top: 1px solid #9b9b9b;
  }
}

.about__main-text2 {
  grid-area: text2;
}

.about__main-text3 {
  grid-area: text3;
}

.about__main-photo {
  display: grid;
  grid-area: photo;
  overflow: hidden;
  border-radius: 20px;
  isolation: isolate;

  @include screen.from(screen.$xl) {
    margin-top: 21px;
  }
}

.about__production {
  display: grid;
  grid-template:
    'text1'
    'text2'
    'photo'
    'text3'
    / 100%;
  gap: 20px;
  align-items: start;
  line-height: 130%;

  @include screen.from(screen.$md) {
    display: grid;
    grid-template:
      'text1 photo'
      'text2 photo'
      'text3 text3'
      / minmax(0, 1fr) minmax(0, 1fr);
  }

  @include screen.from(screen.$xl) {
    display: grid;
    grid-template:
      'text1 photo'
      'text2 photo'
      'text3 photo'
      / minmax(0, 1fr) 438px;
    row-gap: 16px;
    column-gap: 50px;
  }
}

.about__production-text1 {
  grid-area: text1;

  @include screen.from(screen.$xl) {
    padding-top: 20px;
    border-top: 1px solid #9b9b9b;
  }
}

.about__production-text2 {
  grid-area: text2;
}

.about__production-text3 {
  grid-area: text3;
}

.about__production-photo {
  display: grid;
  grid-area: photo;
  overflow: hidden;
  border-radius: 20px;

  @include screen.from(screen.$xl) {
    margin-top: 21px;
  }
}

.about__owner {
  display: grid;
  grid-template:
    'photo'
    'text'
    'block'
    / 100%;
  gap: 20px;
  align-items: stretch;
  line-height: 130%;

  @include screen.from(screen.$md) {
    grid-template:
      'photo text block'
      / minmax(0, 1fr) 230px minmax(0, 1fr);
  }

  @include screen.from(screen.$xl) {
    grid-template:
      'photo text block'
      / minmax(0, 1fr) 440px minmax(0, 1fr);
    row-gap: 16px;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 130%;
    column-gap: 50px;
  }
}

.about__owner-text {
  grid-area: text;
}

.about__owner-block {
  display: flex;
  grid-area: block;
  align-items: center;
  padding: 12px 20px;
  border-radius: 20px;
  background: #fff;
}

.about__owner-photo {
  display: grid;
  grid-area: photo;
  overflow: hidden;
  max-width: 290px;
  margin: 0 auto;
  border-radius: 20px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.about__managers-text {
  margin-bottom: 20px;

  @include screen.from(screen.$md) {
    margin-bottom: 30px;
  }

  @include screen.from(screen.$xl) {
    margin-bottom: 46px;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 130%;
  }
}

.about__managers {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  gap: 20px;

  @include screen.from(screen.$sm) {
    padding: 0 20px;
  }

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding: 0;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  &.has-three-photos {
    @include screen.from(screen.$xl) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
}

.about__managers-item {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  overflow: hidden;
  border-radius: 20px;
}

.about__managers-photo {
  position: relative;
  display: block;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-top: calc(200 / 158 * 100%);
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 120px;
    background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
  }

  @include screen.from(screen.$md) {
    &::before {
      padding-top: calc(260 / 229 * 100%);
    }
  }

  @include screen.from(screen.$xl) {
    &::before {
      padding-top: calc(260 / 300 * 100%);
    }

    &::after {
      height: 90px;
    }
  }
}

.about__managers-info {
  position: relative;
  z-index: 1;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  align-self: end;
  padding: 0 4px 20px;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.about__managers-name {
  margin-bottom: 4px;
  font-weight: 700;
}

.about__managers-rank {
  font-size: 12px;
}

.about__exhibitions {
  margin-top: 20px;
  padding: 20px;
  border-radius: 20px;
  background: #ebf0ff;

  @include screen.from(screen.$md) {
    margin-top: 30px;
    padding: 30px 20px;
  }

  @include screen.from(screen.$xl) {
    margin-top: 50px;
    padding: 50px 134px;
  }
}

.about__exhibitions-text {
  max-width: 636px;
  margin: 0 auto 20px;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 130%;
  text-align: center;

  @include screen.from(screen.$md) {
    margin-bottom: 30px;
  }
}

.about__exhibitions-list {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 30px;
  }

  @include screen.from(screen.$xl) {
    row-gap: 54px;
    column-gap: 40px;
  }
}

.about__exhibitions-item {
  display: grid;
  grid-template:
    'year    name'
    'picture picture' 1fr
    / max-content 1fr;
  gap: 14px;
}

.about__exhibitions-year {
  grid-area: year;
  color: var(--color-accent-light);
  font-weight: 700;
  font-style: normal;
  font-size: 40px;
  line-height: 130%; /* 52px */
  text-transform: uppercase;

  @include screen.from(screen.$sm) {
    font-size: 54px;
  }

  @include screen.from(screen.$md) {
    font-size: 40px;
  }

  @include screen.from(screen.$xl) {
    font-size: 54px;
  }
}

.about__exhibitions-name {
  grid-area: name;
  padding-top: 10px;

  em {
    font-style: normal;
    text-transform: uppercase;
  }

  @include screen.from(screen.$sm) {
    padding-top: 15px;
    font-size: 16px;
  }

  @include screen.from(screen.$md) {
    padding-top: 10px;
    font-size: 14px;
  }

  @include screen.from(screen.$xl) {
    padding-top: 15px;
    font-size: 16px;
  }
}

.about__exhibitions-picture {
  position: relative;
  display: block;
  grid-area: picture;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-top: calc(326 / 440 * 100%);
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.about__vendors-list {
  display: grid;
  grid-template-columns: 100%;
  gap: 35px;

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 20px;
  }
}

.about__vendors-item {
  padding: 14px;
  border: 1px solid var(--color-accent-light);
  border-radius: 20px;

  @include screen.from(screen.$sm) {
    padding: 20px;
  }

  @include screen.from(screen.$md) {
    padding: 24px;
  }

  @include screen.from(screen.$md) {
    padding: 26px;
    font-size: 20px;
  }
}

.about__vendors-logo {
  display: block;
  margin: 0 auto 16px;

  @include screen.from(screen.$sm) {
    margin-bottom: 24px;
  }

  @include screen.from(screen.$md) {
    img {
      object-fit: contain;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }
}

.about__vendors-logo--nussle {
  width: 234px;

  @include screen.from(screen.$sm) {
    width: 314px;
  }

  @include screen.from(screen.$md) {
    width: 100%;
    height: 61px;
  }

  @include screen.from(screen.$md) {
    width: 330px;
  }
}

.about__vendors-logo--ultra {
  width: 134px;

  @include screen.from(screen.$sm) {
    width: 168px;
  }

  @include screen.from(screen.$md) {
    width: 100%;
    height: 61px;
  }
}

.about__providers {
  @include screen.to(screen.$md) {
    br {
      display: none;
    }
  }
}

.about__dol {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;

  &:first-of-type {
    padding-bottom: 90px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 100%;
      max-width: 600px;
      height: 90px;
      background: url(blocks/about/line.svg) no-repeat center;
      background-size: 100% 100%;
      transform: translateX(-50%);
    }
  }

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include screen.from(screen.$xl) {
    gap: 60px;
  }
}

.about__dol-heading {
  margin-bottom: 12px;
  color: var(--color-accent-light);
  font-weight: 700;
  font-style: normal;
  font-size: 48px;
  line-height: 1;

  span {
    display: block;
    margin-top: 8px;
    font-size: 16px;
  }

  @include screen.from(screen.$xl) {
    margin-bottom: 40px;
    font-size: 72px;

    span {
      font-size: 24px;
    }
  }
}

.about__dol-text {
  @include screen.from(screen.$xl) {
    font-size: 20px;
  }
}

.about__dol-picture {
  display: block;
}

.about__dol-picture--1 {
  order: 1;

  @include screen.from(screen.$md) {
    order: -1;
  }
}

.about__dol-picture--2 {
  order: -1;

  @include screen.from(screen.$md) {
    order: 1;
  }
}

.about__history {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;

  @include screen.from(screen.$md) {
    grid-template-columns: 354px minmax(0, 1fr);
    gap: 0;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: 580px minmax(0, 1fr);
  }
}

.about__history-gallery {
  --swiper-pagination-bottom: 0;

  overflow: visible;
  width: 100%;
  padding-bottom: 30px;

  @include screen.from(screen.$md) {
    padding: 0;

    .swiper-wrapper {
      height: auto;
    }

    .swiper-pagination {
      display: none;
    }
  }
}

.about__history-picture {
  position: relative;
  display: block;
  width: 100%;

  &::before {
    content: '';
    display: block;
    padding-top: calc(260 / 440 * 100%);
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @include screen.from(screen.$md) {
    margin-bottom: 50px;

    &::before {
      padding-top: calc(442 / 580 * 100%);
    }
  }

  @include screen.from(screen.$xl) {
    margin-bottom: 70px;
  }
}

.about__history-item {
  position: relative;
  padding-bottom: 20px;
  padding-left: 33px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 9px;
    display: block;
    width: 2px;
    background: var(--color-accent-light);
  }

  &::after {
    content: '';
    position: absolute;
    top: 12px;
    left: 0;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: var(--color-accent-light);
  }

  &:first-of-type {
    &::before {
      top: 14px;
    }
  }

  &:last-of-type {
    padding-bottom: 0;

    &::before {
      bottom: calc(100% - 14px);
    }
  }

  @include screen.from(screen.$sm) {
    padding-left: 60px;

    &::before {
      left: 19px;
    }

    &::after {
      left: 10px;
    }
  }

  @include screen.from(screen.$md) {
    padding-left: 78px;

    &::before {
      left: 38px;
    }

    &::after {
      left: 29px;
    }
  }

  @include screen.from(screen.$xl) {
    padding-bottom: 70px;
    padding-left: 111px;
    font-size: 20px;

    &::before {
      left: 58px;
    }

    &::after {
      left: 49px;
    }
  }
}

.about__history-heading {
  margin-bottom: 8px;
  color: var(--color-accent-light);
  font-weight: 700;
  font-style: normal;
  font-size: 32px;
  line-height: 130%;
}
