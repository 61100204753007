@use "../common/screen";

.h {
  color: var(--color-accent);
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;

  @include screen.from(screen.$xl) {
    font-size: 24px;
  }
}

.h-large {
  color: var(--color-accent);
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;

  @include screen.from(screen.$xl) {
    font-size: 32px;
  }
}

.h2 {
  color: var(--color-accent);
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 140%;
  text-transform: uppercase;

  @include screen.from(screen.$xl) {
    font-size: 32px;
  }
}

.h3 {
  margin-bottom: 18px;
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  line-height: normal;
  text-transform: uppercase;

  @include screen.from(screen.$xl) {
    font-size: 20px;
  }
}
