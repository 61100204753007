@use "../common/screen";

.pagination {
  display: flex;
  justify-content: center;
}

.pagination__list {
  display: flex;
  align-items: center;
}

.pagination__item {
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: normal;

  &.is-active {
    color: var(--color-accent);
    font-weight: 700;
  }

  &.is-prev {
    order: -1;
  }

  &.is-disabled {
    color: #969eaf;
  }
}

.pagination__link {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 34px;
  height: 36px;
  transition: color var(--transition);

  &[href] {
    &:hover,
    &:focus-visible {
      color: var(--color-links-hover);
    }
  }

  @include screen.from(360px) {
    min-width: 36px;
  }
}
