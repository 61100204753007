@use "../../common/screen";

.partners-preview {
  padding: 40px 0;

  @include screen.from(screen.$md) {
    padding: 60px 0;
  }
}

.previews-previews__heading {
  margin-bottom: 24px;
  text-align: center;

    @include screen.from(screen.$xl) {
      margin-bottom: 40px;
    }
}

.partners-preview__list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 25px;
  padding: 0 16px;

  @include screen.from(screen.$sm) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    padding: 0;
  }

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    gap: var(--size-gutter);
  }

  @include screen.from(screen.$xl) {
    gap: 26px;
    padding: 0 60px;
  }
}

.partners-preview__item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 84px;
  padding: 10px 15px;
  border: 1px solid #324989;
  border-radius: 18px;
  background: #fff;

  @include screen.from(screen.$xl) {
    min-height: 120px;
    padding: 20px 15px;
  }
}

.partners-preview__picture {
  display: block;
  width: 100%;
  height: 100%;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.partners-preview__link {
  display: block;
  margin-top: 25px;
  color: var(--color-link);
  text-align: center;
  text-decoration: underline;
  transition: color var(--transition);

  &:hover,
  &:focus-visible {
    color: var(--color-links-hover);
  }
}
