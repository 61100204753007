@use "../common/screen";

.catalog-menu {
  display: none;

  @include screen.from(screen.$xl) {
    position: relative;
    display: block;
    color: #fff;

    &::after {
      // out of container background
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: -1;
      display: block;
      width: 100vw;
      height: 40px;
      background: #505050;
      transform: translateX(-50%);
    }
  }
}

.catalog-menu__list {
  @include screen.from(screen.$xl) {
    display: flex;
    align-items: center;
  }
}

.catalog-menu__item {
  @include screen.from(screen.$xl) {
    &:nth-child(2) {
      margin-left: 26px;
    }

    &:not(:last-child) {
      margin-right: 23px;
    }
  }
}

.catalog-menu__link {
  @include screen.from(screen.$xl) {
    display: flex;
    align-items: center;
    min-height: 40px;
    font-size: 14px;
    transition: color var(--transition);

    &:hover,
    &:focus-visible {
      color: var(--color-links-hover-light);
    }

    .i {
      margin-right: 9px;
    }
  }
}
