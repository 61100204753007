@use "../../common/screen";

.whyus {
  max-width: 402px;
  margin: 40px auto;

  @include screen.from(screen.$md) {
    max-width: 100%;
  }

  @include screen.from(screen.$xl) {
    margin: 40px 58px 50px;
  }
}

.whyus__heading {
  margin-bottom: 42px;
  text-align: center;
}

.whyus__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -10px;

  @include screen.from(screen.$md) {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    row-gap: 45px;
    margin: 0;
    column-gap: var(--size-gutter);
  }
}

.whyus__list--6 {
  @include screen.from(screen.$md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.whyus__item {
  position: relative;
  flex: 0 0 calc(50% - var(--size-gutter));
  height: 90px;
  margin: 0 10px 36px;
  padding-top: 50px;
  border-radius: 18px;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 8%);
  text-align: center;

  @include screen.from(screen.$md) {
    margin: 0;
    font-size: 11px;
  }

  @include screen.from(screen.$xl) {
    font-size: 13px;
  }
}

.whyus__picture {
  position: absolute;
  top: -30%;
  left: 50%;
  display: block;
  height: 74px;
  transform: translateX(-50%);

  img {
    object-fit: contain;
    width: auto;
    height: 100%;
  }
}
