@use "../common/screen";

.p404 {
  padding-top: 24px;
  padding-bottom: 80px;

  @include screen.from(screen.$sm) {
    padding-top: 30px;
  }

  @include screen.from(screen.$md) {
    display: grid;
    grid-template:
      'img title'
      'img text'
      'button button'
      / 1fr 370px;
    padding-top: 40px;
    padding-bottom: 100px;
    column-gap: 20px;
  }

  @include screen.from(screen.$xl) {
    grid-template:
      'img title'
      'img text'
      'button button'
      / 1fr 460px;
    padding-top: 60px;
  }
}

.p404__title {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 24px;
  line-height: normal;
  letter-spacing: 0.72px;
  text-align: center;
  text-transform: uppercase;

  @include screen.from(screen.$sm) {
    margin-bottom: 40px;
  }

  @include screen.from(screen.$md) {
    grid-area: title;
    align-self: center;
    margin: 0;
    font-size: 32px;
    text-align: left;
  }
}

.p404__img {
  width: 206px;
  margin: 0 auto 20px;

  @include screen.from(screen.$sm) {
    width: 260px;
    margin-bottom: 40px;
  }

  @include screen.from(screen.$md) {
    grid-area: img;
    width: 354px;
    margin: 0;
  }

  @include screen.from(screen.$xl) {
    width: 400px;
    justify-self: center;
  }
}

.p404__text {
  max-width: 286px;
  margin: 0 auto 40px;

  @include screen.from(screen.$sm) {
    max-width: 380px;
    font-size: 16px;
  }

  @include screen.from(screen.$md) {
    grid-area: text;
    max-width: 100%;
    margin: 0;
  }

  @include screen.from(screen.$xl) {
    font-size: 20px;
  }
}

.p404__button {
  display: flex;
  max-width: 320px;
  margin: 0 auto;

  @include screen.to(screen.$sm) {
    padding: 18px;
    font-size: 16px;
  }

  @include screen.from(screen.$sm) {
    max-width: 380px;
  }

  @include screen.from(screen.$md) {
    grid-area: button;
    justify-self: center;
    margin: 100px 0 0;
  }

  @include screen.from(screen.$xl) {
    justify-self: start;
  }
}
