@use "../common/screen";

.catalog-subsections {
}

.catalog-subsections--is-parent {
  @include screen.to(screen.$xl) {
    display: none;
  }
}

.catalog-subsections__list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 12px;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: repeat(6, minmax(0, 1fr));

    .catalog-subsections--is-parent & {
      gap: 8px;
    }
  }
}

.catalog-subsections__item {
  display: flex;
}

.catalog-subsections__link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 20px;
  background: #fff;
  transition: box-shadow var(--transition);

  &:hover,
  &:focus-visible {
    box-shadow: 0 8px 36px 0 rgb(0 0 0 / 20%);
  }

  .catalog-subsections--is-parent & {
    padding: 8px;
    border-color: var(--color-lines);
  }
}

.catalog-subsections__link--has-picture {
  min-height: 100px;
  padding: 12px;

  @include screen.from(screen.$xl) {
    min-height: 60px;
    padding: 14px;
  }
}

.catalog-subsections__picture {
  display: block;
  flex-shrink: 0;
  order: 1;
  height: 60px;
  margin: 4px auto 0;

  img {
    object-fit: contain;
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin: auto;

    @supports (object-fit: scale-down) {
      object-fit: scale-down;
    }
  }

  .catalog-subsections--is-parent & {
    height: 40px;
  }
}

.catalog-subsections__text {
  display: block;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 1.3;
  text-align: center;
  text-transform: uppercase;

  .catalog-subsections--is-parent & {
    font-size: 10px;
  }
}
