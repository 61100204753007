@use "../common/screen";

.bx_order_list {
}

.bx_order_list h3 {
  font-weight: bold;
  font-size: 24px;
}

.bx_order_list .bx_order_list_table {
  overflow: hidden;
  width: 100%;
  border: 0;
  border-collapse: collapse;
  border-radius: 20px;
  background: #fff;
}

.bx_order_list .bx_order_list_table tr td {
  box-sizing: border-box;
}

.bx_order_list .bx_order_list_table thead tr td {
  padding: 20px 10px;
  font-weight: bold;
  font-size: 15px;

  &:first-child {
    padding-left: 20px;
  }

  &:last-child {
    padding-right: 20px;
  }
}

.bx_order_list .bx_order_list_table tbody tr td {
  vertical-align: top;
  padding: 10px;
  color: #3f3f3f;
  font-size: 13px;

  &:first-child {
    padding-left: 20px;
  }

  &:last-child {
    padding-right: 20px;
  }
}

.bx_order_list .bx_order_list_table tbody tr td[colspan='2'] {
  padding: 20px;
  font-weight: bold;
  font-size: 15px;
}

.bx_order_list .bx_order_list_table tbody tr td:nth-child(2) {
  width: 75%;
}

.bx_order_list .bx_order_list_table_order {
  overflow: hidden;
  width: 100%;
  border-collapse: collapse;
  border-radius: 20px;
  background: #fff;
}

.bx_order_list .bx_order_list_table_order tr td {
  box-sizing: border-box;
}

.bx_order_list .bx_order_list_table_order thead tr td {
  padding: 20px 10px;
  border-bottom: 1px solid var(--color-lines);
  font-weight: bold;
  font-size: 15px;

  &:first-child {
    padding-left: 20px;
  }

  &:last-child {
    padding-right: 20px;
  }
}

.bx_order_list .bx_order_list_table_order tbody tr td {
  vertical-align: top;
  padding: 20px 10px;
  font-size: 13px;

  &:first-child {
    padding-left: 20px;
  }

  &:last-child {
    padding-right: 20px;
  }
}

.bx_order_list .bx_order_list_table_order tbody tr td.name {
  min-width: 60%;
}

.bx_order_list .bx_order_list_table_order tbody tr td a {
  color: #0073a3;
}

.bx_order_list .bx_order_list_table_order tbody tr td a:hover {
  text-decoration: none;
}

.bx_order_list .bx_order_list_table_order tbody tr td img {
  /*
  width: 100%;
  max-width: 65px;
  min-width: 50px;
  height: auto;
  */
}

.bx_order_list .amount,
.bx_order_list .price {
  color: #5f6a71;
  text-align: right;
  white-space: nowrap;
}

.bx_order_list .amount {
  text-align: center;
}

.bx_order_list .fm {
  display: none;
  color: #5f6a71;
}

.bx_order_list .bx_ordercart_order_sum tr td {
  padding: 1px;
  font-size: 13px;
  text-align: right;
}

.bx_order_list .bx_ordercart_order_sum tr td.custom_t1 {
  width: 100%;
}

.bx_order_list .bx_ordercart_order_sum tr td.custom_t2 {
  white-space: nowrap;
}

.bx_order_list .bx_fwb {
  font-weight: bold;
}

.bx_control_table {
  width: 100%;
}

.bx_control_table td {
  width: 50%;
}

@media (width <= 550px) {
  .bx-touch .bx_order_list .bx_order_list_table tr,
  .bx-touch .bx_order_list .bx_order_list_table td {
    display: block;
    width: 100%;
  }

  .bx-touch .bx_order_list .bx_order_list_table tbody tr td:nth-child(1) {
    padding-bottom: 2px;
    font-weight: bold;
  }

  .bx-touch .bx_order_list .bx_order_list_table tbody tr td:nth-child(2) {
    padding-top: 2px;
  }

  .bx-touch .bx_order_list .bx_order_list_table tbody tr td[colspan='2'] {
    padding: 10px;
  }

  .bx-touch .bx_order_list .bx_order_list_table_order thead {
    display: none;
  }

  .bx-touch .bx_order_list .bx_order_list_table_order td {
    display: block;
    width: 100%;
  }

  .bx-touch .bx_order_list .bx_order_list_table_order tbody tr {
    clear: both;
    margin-bottom: 10px;
  }

  .bx-touch .bx_order_list .bx_order_list_table_order tbody tr td {
    padding: 2px 10px;
  }

  .bx-touch .bx_order_list .bx_order_list_table_order tbody tr td.name {
    padding: 2px 10px;
    font-weight: bold;
  }

  .bx-touch .bx_order_list .bx_order_list_table_order tbody tr td.img {
    float: left;
    width: 70px;
    padding-right: 10px;
  }

  .bx-touch .bx_order_list .bx_order_list_table_order tbody tr td:last-child {
    padding: 2px 10px 30px;
  }

  .bx-touch .bx_order_list .fm {
    display: inline-block;
  }

  .bx-touch .bx_order_list .amount,
  .bx-touch .bx_order_list .price {
    text-align: left;
  }

  .bx-touch .bx_order_list .bx_ordercart_order_sum {
    width: 100% !important;
  }

  .bx-touch .bx_order_list .bx_ordercart_order_sum tr td.custom_t1 {
    width: 50% !important;
  }

  .bx-touch .bx_order_list .bx_ordercart_order_sum tr td.custom_t2 {
    width: 50% !important;
    text-align: left !important;
    white-space: nowrap;
  }

  .bx-touch .bx_order_list .bx_control_table td {
    padding: 10px;
    text-align: center !important;
  }

  .bx_order_list .bx_order_list_table_order tbody tr td img {
    width: 100%;
    min-width: 50px;
    max-width: 65px;
    height: auto;
  }
}

.bx_order_list .bx_bt_button_type_2 {
  display: inline-block;
  border-radius: 3px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.bx_order_list .bx_shadow.bx_bt_button_type_2 {
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%);
}

.bx_order_list .bx_shadow.bx_bt_button_type_2:active {
  box-shadow: none;
}

.bx_order_list .bx_bt_button_type_2 {
  border: 1px solid #e9e9e9;
  background: #fcfcfc;
  background: linear-gradient(to bottom, #fcfcfc 0%, #f1f1f1 100%);
  color: #656565;
  text-shadow: 0 1px 0 #fff;
}

.bx_order_list .bx_bt_button_type_2:hover {
  background: #fff;
}

.bx_order_list .bx_bt_button_type_2:active {
  background: #f1f1f1;
  background: linear-gradient(to bottom, #f1f1f1 0%, #fcfcfc 100%);
  box-shadow: inset 0 2px 2px 0 rgb(0 0 0 / 5%);
}

.bx_order_list .bx_small.bx_bt_button_type_2 {
  display: inline-block;
  min-width: 20px;
  line-height: 20px;
}

.bx_order_list .bx_small.bx_bt_button_type_2 {
}

.bx_order_list .bx_small.bx_bt_button_type_2:hover {
}

.bx_order_list .bx_small.bx_bt_button_type_2:active {
}

.bx_order_list .bx_medium.bx_bt_button_type_2 {
  height: 25px;
  padding: 0 13px;
  font-weight: bold;
  line-height: 25px;
}

.bx_order_list .bx_medium.bx_bt_button_type_2 {
  font-weight: bold;
}

.bx_order_list .bx_medium.bx_bt_button_type_2:hover {
}

.bx_order_list .bx_medium.bx_bt_button_type_2:active {
}

.bx_order_list .bx_big.bx_bt_button_type_2 {
  height: 34px;
  padding: 0 13px;
  font-weight: bold;
  line-height: 34px;
}

.bx_order_list .bx_big.bx_bt_button_type_2 {
}

.bx_order_list .bx_big.bx_bt_button_type_2:hover {
}

.bx_order_list .bx_big.bx_bt_button_type_2:active {
}

.bx_order_list_table_white_header {
  background-color: #fff !important;
}

.bx_order_list .bx_ol_store {
  margin-top: 10px;
}

.bx_order_list .bx_ol_store .bx_old_s_row {
  margin-bottom: 10px;
}

.bx_order_list .bx_ol_store .bx_old_s_row_title {
  margin-bottom: 20px;
}

.bx_order_list .bx_ol_store .bx_ild_s_desc {
  margin-top: 5px;
  color: gray;
  font-size: 12px;
}

.bx_old_s_map {
  width: 100%;
  margin-top: 20px;
}

.bx_old_s_map .bx_bt_button_type_2 {
  margin-bottom: 10px;
}

.bx_map_buttons {
  height: 40px;
}

.bx_map_buttons a {
  display: inline-block;
}

#bx_old_s_map {
  margin-bottom: 10px;
}

#map-container {
  height: 200px;
}

.bx_ol_sku_prop img {
  width: auto !important;
  min-width: 0 !important;
  max-width: 50px !important;
  height: auto !important;
  max-height: 50px !important;
  margin: 3px 3px 3px 0;
  padding: 1px;
  border: 1px solid gray;
}

.bx_ol_sku_prop td {
  padding: 1px 1px 10px !important;
  border: 0;
}

.bx_my_order_switch {
  margin-bottom: 15px;
  line-height: 30px;
}

.bx_my_order_switch .bx_mo_link {
  margin-right: 20px;
}
