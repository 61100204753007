@use "../../common/screen";

.catalog-sections {
  display: block;
  margin: 40px 0;

  @include screen.from(screen.$md) {
    margin: 50px 0;
  }
}

.catalog-sections__list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 10px;

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 20px;
  }

  @include screen.from(screen.$xl) {
    gap: 42px;
    padding: 0 100px;
  }
}

.catalog-sections__item {
  display: flex;

  @include screen.from(screen.$md) {
    grid-column: span 2;

    &.has-description {
      grid-column: span 3;
    }
  }
}

.catalog-sections__link {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  padding: 10px 8px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 16px;
  background: #fff;
  transition: box-shadow var(--transition);

  &:hover,
  &:focus-visible {
    box-shadow: 0 8px 36px 0 rgb(0 0 0 / 20%);
  }

  @include screen.from(screen.$md) {
    padding: 16px 20px;

    .catalog-sections__item.has-description & {
      display: grid;
      grid-template:
        'picture title'
        'picture description' 1fr
        / 110px minmax(0, 1fr);
      column-gap: 16px;
    }
  }

  @include screen.from(screen.$xl) {
    padding: 22px 30px;
    min-height: 220px;
    border-radius: 20px;

    .catalog-sections__item.has-description & {
      grid-template:
        'picture title'
        'picture description' 1fr
        / 155px minmax(0, 1fr);
      min-height: 200px;
    }
  }
}

.catalog-sections__title {
  margin-bottom: 8px;
  padding: 0 16px;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 120%; /* 19.2px */
  text-align: center;
  text-transform: uppercase;

  @include screen.from(screen.$md) {
    .catalog-sections__item.has-description & {
      grid-area: title;
      margin-bottom: 16px;
      padding: 0;
      text-align: left;
    }
  }

  @include screen.from(screen.$xl) {
    padding: 0;
    font-size: 20px;
  }
}

.catalog-sections__picture {
  display: block;
  margin-top: auto;

  img {
    max-width: 100%;
    margin: 0 auto;
  }

  @include screen.from(screen.$md) {
    .catalog-sections__item.has-description & {
      grid-area: picture;
      align-self: center;
      margin-top: 0;
    }
  }

  @include screen.from(screen.$xl) {
    .catalog-sections__item.has-description & {
      max-width: 130px;
      margin: 0 auto;
    }
  }
}

.catalog-sections__description {
  display: none;

  @include screen.from(screen.$md) {
    display: block;
    grid-area: description;
    font-size: 12px;

    & > p + p {
      margin-top: 1em;
    }
  }

  @include screen.from(screen.$xl) {
    font-size: 14px;
  }
}
