@use "../common/screen";

.big-numbers-list {
  counter-reset: list;
}

.big-numbers-list__item {
  position: relative;
  min-height: 60px;
  padding-left: 54px;
  line-height: 130%;
  counter-increment: list;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  em {
    color: var(--color-accent);
    font-style: normal;
  }

  &::before {
    content: counter(list);
    position: absolute;
    top: -5px;
    left: 0;
    display: block;
    color: #a7baed;
    font-weight: 700;
    font-size: 70px;
    line-height: 1;
  }

  @include screen.from(screen.$sm) {
    font-size: 16px;
  }

  @include screen.from(screen.$xl) {
    min-height: 50px;
    padding-left: 65px;
    font-size: 20px;

    &:not(:last-of-type) {
      margin-bottom: 36px;
    }
  }
}

.education-why__list {
  display: grid;
  grid-template-columns: 100%;
  gap: 16px;

  li {
    display: flex;
    align-items: center;
    min-height: 60px;

    &:not(:last-of-type) {
      margin-bottom: 0;
    }
  }

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 30px 20px;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: repeat(2, 440px);
    gap: 60px 80px;
    justify-content: center;
  }
}
