@use "../../common/screen";

.tech-how {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;
  align-items: start;

  @include screen.from(screen.$xl) {
    grid-template-columns: 685px 1fr;
    gap: 36px;
  }
}

.tech-how__car {
  display: grid;
  grid-template-columns: 100%;
  overflow: hidden;
  margin: 0 calc(-1 * var(--size-container-sidegap));
  border-radius: 8px;

  @include screen.from(screen.$sm) {
    margin: 0;
    border-radius: 12px;
  }

  @include screen.from(screen.$md) {
    border-radius: 16px;
  }
}

.tech-how__picture {
  display: block;
  grid-row: 1 / -1;
  grid-column: 1 / -1;

  img {
    width: 100%;
    height: auto;
  }
}

.tech-history {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;
  align-items: stretch;

  @include screen.from(screen.$md) {
    grid-template-columns: 1fr 1fr;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: 1fr 560px;
    gap: 40px;
  }
}

.tech-history__picture {
  margin: 0 calc(-1 * var(--size-container-sidegap));

  @include screen.from(screen.$sm) {
    margin: 0;
  }

  @include screen.from(screen.$md) {
    order: 1;
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.tech-systems {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;
}

.tech-systems__title {
  margin-bottom: 18px;
  font-weight: 700;
  font-size: 20px;
  line-height: 130%; /* 26px */
  text-align: center;
  text-transform: uppercase;
}

.tech-systems__layout {
  @include screen.from(screen.$md) {
    display: grid;
    grid-template-columns: 277px 1fr;
    gap: 20px;
    align-items: start;
  }

  @include screen.from(screen.$xl) {
    gap: 40px;
  }
}

.tech-systems__picture {
  display: block;
  overflow: hidden;
  max-width: 319px;
  margin: 0 auto 18px;
  border-radius: 12px;

  @include screen.from(screen.$md) {
    margin: 0;
  }
}

.tech-systems__video {
  overflow: hidden;
  margin-bottom: 24px;
  border-radius: 12px;
}

.tech-systems__narrow {
  @include screen.from(screen.$md) {
    max-width: 478px;
    margin: 0 auto;
  }

  @include screen.from(screen.$xl) {
    max-width: 712px;
  }
}

.tech-numbers {
  max-width: 777px;
}

.tech-masters {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;
  align-items: start;

  @include screen.from(screen.$md) {
    grid-template-columns: 1fr 1fr;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: 1fr 638px;
    gap: 50px;
  }
}

.tech-masters__picture {
  overflow: hidden;
  margin: 0 calc(-1 * var(--size-container-sidegap));
  border-radius: 16px;

  @include screen.from(screen.$sm) {
    margin: 0;
  }

  @include screen.from(screen.$md) {
    order: 1;
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.tech-about__description {
  margin-bottom: 20px;
  font-weight: 700;
  text-align: center;

  @include screen.from(screen.$md) {
    max-width: 728px;
    margin: 0 auto 20px;
  }

  @include screen.from(screen.$xl) {
    max-width: 921px;
  }
}

.tech-about__question {
  color: var(--color-accent);
  font-weight: 600;
  font-size: 28px;
  line-height: 134%;
  text-align: center;
  text-transform: uppercase;

  @include screen.from(screen.$sm) {
    font-size: 40px;
  }

  @include screen.from(screen.$md) {
    font-size: 64px;
  }

  @include screen.from(screen.$xl) {
    line-height: 194%;
    text-align: left;
  }
}

.tech-about__layout {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;

  @include screen.from(screen.$xl) {
    grid-template-columns: 436px 1fr;
  }
}

.tech-about__list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 20px;
}

.tech-about__item {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.tech-about__icon {
  display: block;
  align-self: center;
  width: 127px;
  margin-bottom: 12px;

  @include screen.from(screen.$md) {
    width: 170px;
  }
}

.tech-how2 {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;
  align-items: start;

  @include screen.from(screen.$md) {
    grid-template-columns: 1fr 1fr;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: 1fr 638px;
    gap: 50px;
  }
}

.tech-how2__picture {
  overflow: hidden;
  margin: 0 calc(-1 * var(--size-container-sidegap));
  border-radius: 16px;

  @include screen.from(screen.$sm) {
    margin: 0;
  }

  @include screen.from(screen.$md) {
    height: 100%;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.tech-features {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;
  align-items: start;
  text-align: center;

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.tech-features__icon {
  max-width: 229px;
  margin: 0 auto 20px;

  @include screen.from(screen.$xl) {
    max-width: 334px;
  }
}

.tech-features__title {
  margin-bottom: 0.8em;
  font-weight: 700;
}
