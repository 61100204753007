@use "../common/screen";

.bx-form {
  .errortext,
  .notetext {
    display: block;
    margin-top: 14px;

    @include screen.from(screen.$sm) {
      margin-top: 34px;
    }
  }
}

.bx-form--has-side-labels .bx-form__list {
  display: grid;
  grid-template-columns: 100%;

  @include screen.from(screen.$sm) {
    grid-template-columns: 120px 1fr;
    row-gap: 12px;
    column-gap: 18px;
  }
}

.bx-form__label {
}

.bx-form__value,
.bx-form .form-group {
  display: block;
  margin-bottom: 14px;

  input[type='text'],
  textarea {
    position: relative;
    width: 100%;
    min-height: 40px;
    margin-top: 6px;
    padding: 10px 16px;
    border: 1px solid #9d9d9d;
    background: #f5f5f5;
    outline: none;
    font-size: 14px;
    line-height: normal;
    resize: none;
    transition: border-color var(--transition), opacity var(--transition);
    appearance: none;
    scrollbar-width: none;

    &:disabled {
      opacity: 0.3;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      border-color: var(--color-links-hover);
    }

    &::-ms-clear {
      display: none;
    }

    &::placeholder {
      color: #d0d0d0;
      opacity: 1;
    }

    .bx-form--has-side-labels & {
      @include screen.from(screen.$sm) {
        margin-top: 0;
      }
    }
  }

  .bx-form--has-side-labels & {
    @include screen.from(screen.$sm) {
      margin-bottom: 0;
    }
  }
}

.bx-form__required {
  color: var(--color-text-alt);

  sup {
    color: var(--color-red);
  }

  @include screen.from(screen.$sm) {
    grid-column: 2;
    margin-top: 8px;
  }
}

.bx-form__submit {
  width: 100%;
  margin-top: 14px;

  @include screen.from(screen.$sm) {
    margin-top: 34px;
  }
}

.bx-form input[type='submit'] {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 65px;
  margin-top: 14px;
  padding: 18px 20px;
  border: 1px solid #315cc1;
  border-radius: 16px;
  background: #315cc1;
  color: #fff;
  outline: none;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.4px;
  text-align: center;
  text-transform: uppercase;
  transition: background-color var(--transition), color var(--transition);
  appearance: none;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    background-color: #e8efff;
    color: #315cc1;
  }

  &:active:not(:disabled) {
    background-color: #fff;
    color: #315cc1;
  }

  @include screen.from(screen.$sm) {
    margin-top: 34px;
    padding: 18px 25px;
    font-size: 20px;
  }
}
