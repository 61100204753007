@use "../common/screen";

.catalog-element {
  padding-top: 24px;
  padding-bottom: 80px;

  @include screen.from(screen.$sm) {
    padding-top: 30px;
  }

  @include screen.from(screen.$md) {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  @include screen.from(screen.$xl) {
    padding-top: 24px;
  }
}

.catalog-element__heading {
  margin-bottom: 20px;
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 140%;

  @include screen.from(screen.$md) {
    margin-bottom: 30px;
  }
}

.catalog-element__top {
  display: grid;
  grid-template-columns: 100%;
  gap: 64px;
  margin-bottom: 20px;

  @include screen.from(screen.$sm) {
    gap: 40px;
    margin-bottom: 40px;
  }

  @include screen.from(screen.$md) {
    grid-template-columns: minmax(0, 7fr) minmax(0, 5fr);
    gap: 20px;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: minmax(0, 8fr) minmax(0, 4fr);
    gap: 16px;
    margin-bottom: 60px;
  }
}

.catalog-element__panel {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 20px;
  background: #fff;

  @include screen.from(screen.$xl) {
    padding: 24px;
  }
}

.catalog-element__discount {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  grid-area: discount;
  justify-content: center;
  align-items: center;
  align-self: end;
  width: 40px;
  height: 40px;
  border: 1px solid var(--color-lines);
  border-radius: 12px;
  color: #9b9b9b;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  user-select: none;

  @include screen.from(screen.$xl) {
    top: 20px;
    right: 20px;
  }
}

.catalog-element__params {
  clear: both;
  margin-bottom: 12px;
  border-bottom: 1px solid #e7e7e7;
}

.catalog-element__param-name {
  float: left;
  padding-bottom: 1em;
  font-weight: 700;

  &::after {
    content: '\00a0';
  }
}

.catalog-element__param-value {
  padding-bottom: 1em;
}

.catalog-element__availability {
  margin-bottom: 30px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e7e7e7;
}

.catalog-element__controls {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 14px 20px;
  align-items: end;
  margin-top: auto;

  @include screen.from(screen.$xl) {
    gap: 24px 16px;
  }
}

.catalog-element__prices {
  @include screen.from(screen.$xl) {
    padding-bottom: 3px;
  }
}

.catalog-element__old-price {
  margin-bottom: 12px;
  color: var(--color-text-disabled);
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: normal;
  text-decoration: line-through;

  @include screen.from(screen.$xl) {
    margin-bottom: 8px;
    font-size: 20px;
  }
}

.catalog-element__price {
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  line-height: normal;

  @include screen.from(screen.$xl) {
    font-size: 26px;
  }
}

.catalog-element__buy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 42px;
  padding: 10px;
  border: 1px solid var(--color-accent-light);
  background: var(--color-accent-light);
  color: #fff;
  outline: 0;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  text-transform: uppercase;
  transition: color var(--transition), background-color var(--transition);
  appearance: none;

  &:hover,
  &:focus-visible {
    background: #fff;
    color: var(--color-accent-light);
  }

  @include screen.from(screen.$xl) {
    min-height: 52px;
    padding: 13px;
    font-size: 16px;
  }
}

.catalog-element__tinkoff {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 42px;
  padding: 8px;
  border: 1px solid transparent;
  background: #ffdd2d;
  color: #333;
  outline: 0;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  text-transform: uppercase;
  transition: color var(--transition), background-color var(--transition);
  appearance: none;

  &:hover,
  &:focus-visible {
    background: #fcc521;
  }

  @include screen.from(screen.$sm) {
    justify-content: start;
    padding: 8px 0 8px 18px;
  }

  @include screen.from(screen.$md) {
    padding-left: 8px;
  }

  @include screen.from(screen.$xl) {
    min-height: 52px;
    padding: 7px 16px;
    font-size: 16px;
  }
}

.catalog-element__tinkoff-logo {
  width: 30px;
  margin-right: 8px;
}

.catalog-element__quantity {
  display: flex;
  align-items: center;
  justify-self: end;
  max-width: 132px;

  &[hidden] {
    display: none;
  }
}

.catalog-element__minus,
.catalog-element__plus {
  position: relative;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  border: 1px solid #9b9b9b;
  outline: none;
  font-weight: 400;
  font-style: normal;
  font-size: 26px;
  line-height: normal;
  transition: color var(--transition), border-color var(--transition);
  appearance: none;

  &:hover,
  &:focus-visible {
    z-index: 1;
    border-color: var(--color-links-hover);
    color: var(--color-links-hover);
  }
}

.catalog-element__minus {
  margin-right: -1px;
}

.catalog-element__plus {
  margin-left: -1px;
}

.catalog-element__input {
  position: relative;
  width: 100%;
  min-width: 0;
  min-height: 38px;
  padding: 5px;
  border: 1px solid #9b9b9b;
  background: transparent;
  outline: 0;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: normal;
  text-align: center;
  transition: border-color var(--transition);
  appearance: none;

  &:hover,
  &:focus {
    z-index: 1;
    border-color: var(--color-links-hover);
  }
}

.catalog-element__features-heading {
  margin-bottom: 42px;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: normal;

  @include screen.from(screen.$xl) {
    margin-bottom: 50px;
    font-size: 26px;
  }
}

.catalog-element__features {
  margin-bottom: 20px;

  @include screen.from(screen.$sm) {
    margin-bottom: 30px;
  }

  @include screen.from(screen.$xl) {
    padding: 0 70px;
  }
}

.catalog-element__description {
  ul {
    margin-left: 1em;
    list-style: disc outside;

    li + li {
      margin-top: 0.5em;
    }
  }

  @include screen.from(screen.$md) {
    width: 60%;
  }

  @include screen.from(screen.$xl) {
    width: 50%;
  }
}

.catalog-element__additional-products {
  margin-top: 20px;
  @include screen.from(screen.$sm) {
    margin-top: 30px;
  }

  @include screen.from(screen.$md) {
    margin-top: 60px;
  }

  @include screen.from(screen.$xl) {
    margin-top: 80px;
  }
}
