@use "../../common/screen";

.education-topnums {
  max-width: 579px;
  margin: 0 auto;

  @include screen.from(screen.$xl) {
    max-width: 737px;
  }
}

.education-programs {
  display: grid;
  grid-template-columns: 275px;
  row-gap: 20px;
  justify-content: center;

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(2, 275px);
    gap: 20px;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: repeat(4, 275px);
  }
}

.education-programs__card {
  display: flex;
  flex-direction: column;
  min-height: 490px;
  padding: 20px 12px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 20px;
  background: #fff;
}

.education-programs__name {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 56px;
  margin-bottom: 14px;
  color: var(--color-accent);
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 140%; /* 28px */
  text-align: center;
  text-transform: uppercase;
}

.education-programs__description {
  min-height: 70px;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
}

.education-programs__top {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #c7c7c7;
}

.education-programs__time {
  margin-bottom: 16px;
  color: var(--color-accent);
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
}

.education-programs__price {
  display: block;
  width: 100%;
  margin-top: auto;
  margin-bottom: 8px;
  padding: 9px 20px;
  border-radius: 12px;
  background: var(--color-accent);
  color: #fff;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: 140%; /* 28px */
  letter-spacing: 0.4px;
  text-align: center;
  text-transform: uppercase;
}

.education-programs__link {
  display: block;
  margin-top: 8px;
  text-align: center;
}

.education-programs__tip {
  font-size: 12px;
  line-height: 120%;
  text-align: center;
}

.education-programs__free {
  margin-top: 40px;
  margin-bottom: 18px;
  text-align: center;

  em {
    color: var(--color-accent);
    font-style: normal;
  }

  @include screen.to(screen.$md) {
    br {
      display: none;
    }
  }

  @include screen.from(screen.$xl) {
    margin-top: 60px;
    margin-bottom: 24px;
  }
}

.education-programs__disks {
  display: grid;
  grid-template-columns: minmax(0, 290px);
  gap: 24px;
  justify-content: center;

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(3, minmax(0, 290px));
  }

  @include screen.from(screen.$lg) {
    gap: 40px;
  }
}

.education-masters {
}

.education-masters__item {
  &:not(:last-of-type) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #b8b8b8;
  }

  @include screen.from(screen.$md) {
    display: grid;
    grid-template-columns: 2fr 4fr;
    align-items: start;
    column-gap: 30px;

    &:not(:last-of-type) {
      margin-bottom: 30px;
      padding-bottom: 30px;
    }
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: 4fr 8fr;
    column-gap: 40px;

    &:not(:last-of-type) {
      margin-bottom: 50px;
      padding-bottom: 50px;
    }
  }
}

.education-masters__master {
  position: relative;
  display: block;
  overflow: hidden;
  width: 230px;
  margin: 0 auto 20px;
  border-radius: 20px;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  @include screen.from(screen.$md) {
    width: 100%;
    margin: 0;
  }
}

.education-masters__picture {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    height: 40%;
    background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.education-masters__master-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding: 36px 10px;
  color: #fff;
  line-height: 130%;
  text-align: center;

  @include screen.from(screen.$xl) {
    font-size: 18px;

    br {
      display: none;
    }
  }
}

.education-masters__name {
  font-weight: 700;
}

.education-masters__list {
  @include screen.from(screen.$sm) {
    font-size: 16px;
  }

  @include screen.from(screen.$xl) {
    font-size: 20px;
  }
}

.education-form {
  max-width: 274px;

  @include screen.from(screen.$md) {
    max-width: 326px;
  }
}

.education-program__description {
  margin-bottom: 20px;
  text-align: center;

  @include screen.from(screen.$sm) {
    margin-bottom: 50px;
  }
}

.education-program__tip {
  margin-top: 20px;
  color: var(--color-accent);
}

.education-how {
  display: grid;
  grid-template:
    'text1'
    'photo1'
    'dent'
    'text2'
    'photo2'
    / 100%;

  @include screen.from(screen.$md) {
    grid-template:
      'photo1 photo1 text1 text1' 80px
      'photo1 photo1 photo2 photo2' 170px
      'dent   text2  text2 photo3' 280px
      / 265px 110px 136px 1fr;
  }
  @include screen.from(screen.$xl) {
    grid-template:
      'photo1 photo1 text1  text1  text1' 115px
      'photo1 photo1 photo2 photo2 photo4' 185px
      'dent   text2  text2  photo3 photo3' 350px
      / 380px 45px 205px 115px 1fr;
  }
}

.education-how__text1 {
  grid-area: text1;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  text-align: center;

  @include screen.to(screen.$xl) {
    br {
      display: none;
    }
  }

  @include screen.from(screen.$sm) {
    font-size: 20px;
  }

  @include screen.from(screen.$md) {
    text-align: left;
  }

  @include screen.from(screen.$xl) {
    padding-left: 50px;
  }
}

.education-how__text2 {
  grid-area: text2;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 130%;
  text-align: center;

  @include screen.from(screen.$sm) {
    font-size: 20px;
  }

  @include screen.from(screen.$md) {
    align-self: end;
    margin-bottom: 0;
    text-align: left;
  }
}

.education-how__photo1 {
  display: block;
  grid-area: photo1;
  margin-bottom: 60px;

  img {
    width: 100%;
  }

  @include screen.from(screen.$md) {
    margin: 0;
    padding-right: 20px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  @include screen.from(screen.$xl) {
    padding-right: 0;
  }
}

.education-how__photo2 {
  display: block;
  grid-area: photo2;

  img {
    width: 100%;
  }

  @include screen.from(screen.$md) {
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  @include screen.from(screen.$xl) {
    padding-left: 50px;
  }
}

.education-how__photo3 {
  display: none;

  @include screen.from(screen.$md) {
    display: block;
    grid-area: photo3;
    padding-top: 20px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  @include screen.from(screen.$xl) {
    padding-top: 50px;
  }
}

.education-how__photo4 {
  display: none;

  @include screen.from(screen.$xl) {
    display: block;
    grid-area: photo4;
    padding-left: 50px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
}

.education-how__dent {
  grid-area: dent;
  margin-bottom: 10px;
  color: #6189e9;
  font-weight: 700;
  font-size: 50px;
  line-height: 130%;
  text-align: center;

  @include screen.from(screen.$md) {
    align-self: end;
    margin-bottom: -13px;
    font-size: 64px;
  }

  @include screen.from(screen.$xl) {
    margin-bottom: -23px;
    padding-top: 50px;
    padding-right: 20px;
    font-size: 97px;
  }
}

.education-how__50 {
  display: block;
  margin-bottom: 20px;
  font-size: 142px;

  @include screen.from(screen.$md) {
    font-size: 182px;
  }

  @include screen.from(screen.$xl) {
    font-size: 274px;
  }
}

.education-what__list {
  display: grid;
  grid-template-columns: 100%;
  grid-auto-rows: 1fr;
  row-gap: 14px;

  @include screen.from(screen.$sm) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 20px;
  }

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(2, 252px);
    gap: 40px 20px;
    justify-content: center;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: repeat(4, 252px);
    gap: 20px;
  }
}

.education-certs__swiper {
  --swiper-pagination-bottom: 0;

  margin-top: 30px;
  padding-bottom: 30px;

  @include screen.from(screen.$sm) {
    margin-top: 40px;
  }

  @include screen.from(screen.$md) {
    margin-top: 50px;
  }

  @include screen.from(screen.$xl) {
    padding: 0 50px;

    .swiper-wrapper {
      justify-content: space-between;
    }

    .swiper-pagination {
      display: none;
    }
  }
}

.education-what__item {
  display: flex;
  align-items: center;
  min-height: 96px;
  padding: 12px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 20px;
  background: #fff;
}

.education-certs__item {
  width: 221px;
}

.education-works__images {
  display: grid;
  grid-template-columns: repeat(auto-fill, min(100%, 428px));
  gap: 30px;
  justify-content: center;
}

.education-works__link {
  display: block;
  margin-top: 30px;
  text-align: center;

  @include screen.from(screen.$xl) {
    margin-top: 50px;
  }
}

.education-support {
  @include screen.from(screen.$md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @include screen.from(screen.$xl) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

.education-support__item {
  margin-bottom: 40px;
  text-align: center;

  @include screen.from(screen.$md) {
    width: 353px;
    padding: 0 25px;
    text-align: left;
  }

  @include screen.from(screen.$xl) {
    width: 360px;
    margin-bottom: 50px;
    padding: 0;
  }
}

.education-support__icon {
  width: auto;
  height: 91px;
  margin: 0 auto 20px;
}

.education-when {
  display: grid;
  grid-template:
    'list'
    'calendar'
    'form'
    / 100%;
  gap: 40px;
  align-items: start;

  @include screen.from(screen.$md) {
    grid-template:
      'list list'
      'calendar form'
      / 1fr 1fr;
    gap: 50px 20px;
  }

  @include screen.from(screen.$xl) {
    grid-template:
      'calendar list'
      'calendar form'
      / 5fr 7fr;
    gap: 50px;
  }
}

.education-when__item {
  position: relative;
  padding-left: 2em;

  &:not(:last-of-type) {
    margin-bottom: 1em;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0.2em;
    left: 0;
    display: block;
    width: 1.5em;
    height: 1.5em;
    background: url(svg/icons/checkmark-20.svg) no-repeat center;
    background-size: contain;
  }
}

.education-when__calendar {
  grid-area: calendar;
  padding-right: 40px;
  padding-left: 20px;

  @include screen.from(screen.$md) {
    align-self: center;
    padding: 0;
  }
  @include screen.from(screen.$xl) {
    align-self: start;
  }
}

.education-when__list {
  grid-area: list;

  @include screen.from(screen.$md) {
    justify-self: center;
    max-width: 480px;
  }

  @include screen.from(screen.$xl) {
    justify-self: start;
    max-width: 100%;
  }
}

.education-when__phone {
  display: block;
  margin-top: 20px;
  color: var(--color-accent);
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: 100%;

  @include screen.from(screen.$sm) {
    font-size: 26px;
  }

  @include screen.from(screen.$xl) {
    font-size: 32px;
  }
}

.education-when__form {
  grid-area: form;
  max-width: 326px;
  margin: 0 auto;

  @include screen.from(screen.$md) {
    margin: 0;
    padding-right: 20px;
  }
}

.education-where {
  overflow: hidden;
}

.education-where__list {
  @include screen.from(screen.$md) {
    max-width: 430px;
    margin: 0 auto;
  }

  @include screen.from(screen.$xl) {
    max-width: 580px;
  }
}

.education-where__item {
  display: flex;
  align-items: flex-start;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  @include screen.from(screen.$sm) {
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
  }

  @include screen.from(screen.$xl) {
    &:not(:last-of-type) {
      margin-bottom: 60px;
    }
  }
}

.education-where__icon {
  display: block;
  flex: 0 0 auto;
  width: 44px;
  margin-right: 20px;

  @include screen.from(screen.$sm) {
    width: 66px;
    margin-right: 30px;
  }

  @include screen.from(screen.$xl) {
    width: 86px;
    margin-right: 50px;
  }
}

.education-where__swiper {
  --swiper-pagination-bottom: 0;

  overflow: visible;
  margin-top: 40px;
  padding-bottom: 30px;

  @include screen.from(screen.$md) {
    overflow: hidden;
    margin-top: 60px;

    .swiper-pagination {
      display: none;
    }
  }
}

.education-where__slide {
  width: auto;
  height: 300px;

  @include screen.from(screen.$sm) {
    height: 500px;
  }
}

.education-where__photo {
  display: block;
  height: 100%;

  img {
    width: auto;
    height: 100%;
  }
}

.education-links__list {
  display: grid;
  grid-template-columns: min(100%, 354px);
  gap: 20px;
  justify-content: center;

  @include screen.from(screen.$sm) {
    gap: 24px;
  }

  @include screen.from(screen.$md) {
    grid-template-columns: 1fr 1fr;
  }
}

.education-links__item {
  display: flex;
}

.education-links__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 30px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 16px;
  background: #fff;
  color: var(--color-links);
  text-decoration: underline;
  transition: color var(--transition);

  &:hover,
  &:focus-visible {
    color: var(--color-links-hover);
  }

  @include screen.from(screen.$sm) {
    padding: 36px 30px;
  }

  @include screen.from(screen.$md) {
    padding: 25px 35px;
  }

  @include screen.from(screen.$xl) {
    padding: 20px 80px;
  }
}
