@use "../../common/screen";

.business {
  padding-top: 40px;
  padding-bottom: 80px;

  @include screen.from(screen.$xl) {
    padding-bottom: 100px;
  }
}

.business__heading {
  margin-bottom: 40px;
  text-align: center;
}
