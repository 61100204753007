.title-search-result {
  z-index: 1000;
}

.search-title {
  padding: 16px;
  border: 1px solid #25314c;
  background: #fff;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 100%;
  gap: 8px;
}

.search-title-result-item {
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 8px;
  align-items: start;
}

.search-title-result-item-image-container {
  border: 1px solid var(--color-lines);
  border-radius: 4px;
  overflow: hidden;
}
