@use "../common/screen";

.content-blue-block {
  background: #ebf0ff;

  @include screen.from(screen.$xl) {
    background: transparent;
  }
}

.content-blue-block--is-mobile-rounded {
  border-radius: 20px;

  @include screen.from(screen.$xl) {
    border-radius: 0;
  }
}

.content-blue-block__inner {
  padding: 20px 0;
  background: #ebf0ff;

  @include screen.from(screen.$md) {
    padding: 30px 0;
  }

  @include screen.from(screen.$xl) {
    padding: 50px 20px;
    border-radius: 20px;
  }
}

.content-blue-block__inner--narrow {
  @include screen.from(screen.$md) {
    padding: 30px 113px;
  }

  @include screen.from(screen.$xl) {
    padding: 50px 60px;
  }
}
