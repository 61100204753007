.video-player {
  .plyr {
    --plyr-font-family: var(--font-main);
    --plyr-color-main: var(--color-accent);
    --plyr-badge-background: #fff;
  }

  .plyr__control--overlaid {
    border-radius: 12px;
    background: var(--color-accent);
    box-shadow: 0 2px 8px 0 rgb(44 30 9 / 10%);
    opacity: 1;
  }

  .plyr__poster {
    background-size: cover;
  }
}

.video-player--are-controls-hidden {
  .plyr--video.plyr--stopped {
    // hide controls initially
    .plyr__controls {
      display: none;
    }
  }
}
