@use "../../common/screen";

.order-list {
}

.order-list__item {
  padding: 12px;
  border-radius: 20px;
  background: #fff;
  font-size: 14px;

  @include screen.from(screen.$sm) {
    padding: 20px;
  }

  @include screen.from(screen.$xl) {
    padding: 36px 60px;
  }
}

.order-list__menu {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.order-list__reveal-prices {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border: 1px solid #315cc1;
  border-radius: 4px;
  background: #315cc1;
  color: #fff;
  outline: none;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  text-transform: uppercase;
  transition: background-color var(--transition), color var(--transition);
  appearance: none;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 36px;
    height: 36px;
    background: linear-gradient(
        -45deg,
        transparent 50%,
        currentcolor 50%,
        currentcolor calc(50% + 2px),
        transparent calc(50% + 2px)
    );
  }

  &.is-hidden {
    &::after {
      display: none;
    }
  }

  &:hover,
  &:focus-visible {
    background: #fff;
    color: #315cc1;
  }
}

.order-list__title {
  margin-bottom: 20px;
  color: var(--color-accent);
  font-weight: 600;
  font-style: normal;
  font-size: 20px;
  line-height: 140%;

  @include screen.from(screen.$md) {
    margin-bottom: 30px;
  }
}

.order-list__subsection {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid var(--color-lines);
  border-radius: 8px;
}

.order-list__subtitle {
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
}

.order-list__subsection-title {
  margin-bottom: 16px;
  font-weight: 600;

  @include screen.from(screen.$md) {
    display: flex;
  }
}

.sale-order-list-status-restricted,
.sale-order-list-status-alert,
.sale-order-list-status-success {
  display: block;
  width: max-content;
  margin-top: 8px;
  padding: 5px 10px 5px 8px;
  border: 1px solid #eab3b9;
  border-radius: 2px;
  background: #ffe5e8;
  color: #d0021b;

  @include screen.from(screen.$md) {
    margin-top: 0;
    margin-left: auto;
  }
}

.sale-order-list-status-success {
  border: 1px solid #bed277;
  background: #e3f0bd;
  color: #597707;
}

.sale-order-list-status-restricted {
  border: 1px solid rgb(128 134 142 / 20%);
  background: #f3f4f5;
  color: #535c69;
}

.sale-order-list-status-restricted-message {
  color: #9e9fa2;
}

.sale-order-state-green {
  background: #c2f388;
}

.sale-order-state-yellow {
  background: #d7dd39;
}

.sale-order-state-red {
  background: #dd6239;
}

.sale-order-state-grey {
  background: #f2f2f2;
}

.sale-order-list-accomplished-date.canceled-order {
  color: #f00;
}

.sale-order-list-inner-row-template {
  display: none;
}
