@use "../../common/screen";

.swiper {
  --swiper-theme-color: var(--color-accent);
  --swiper-navigation-color: var(--color-text-main);
  --swiper-pagination-color: var(--swiper-theme-color);
  --swiper-pagination-bottom: 8px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-horizontal-gap: 3px;
  --swiper-pagination-bullet-inactive-color: transparent;
  --swiper-pagination-bullet-inactive-opacity: 1;

  .swiper-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-pagination-bullet {
    border: 1px solid var(--swiper-pagination-color);
  }

  .swiper-button-prev.is-round,
  .swiper-button-next.is-round {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: rgb(255 255 255 / 80%);
    color: var(--color-accent);
    transition: background-color var(--transition), color var(--transition);

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background: currentcolor;
      mask-repeat: no-repeat;
      mask-size: 100% 100%;
    }

    @include screen.to(screen.$md) {
      display: none;
    }
  }

  .swiper-button-prev::after {
    mask-image: url(svg/icons/angle-left-20.svg);
  }

  .swiper-button-next::after {
    mask-image: url(svg/icons/angle-right-20.svg);
  }

  @include screen.from(screen.$xl) {
    --swiper-pagination-bullet-size: 14px;
    --swiper-pagination-bullet-horizontal-gap: 3px;

    .swiper-pagination-bullet {
      border-width: 2px;
    }
  }
}
