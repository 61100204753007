@use '../../common/screen';

.compare {
  --position: 50%;

  position: relative;
  display: grid;
  grid-template-columns: 100%;
  overflow: hidden;
  user-select: none;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: var(--position);
    z-index: 1;
    width: 3px;
    height: 100%;
    background: rgb(255 255 255 / 80%);
    transform: translateX(-50%);
    backdrop-filter: blur(3px);
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    bottom: 0;
    left: var(--position);
    z-index: 1;
    width: 74px;
    height: 34px;
    background: url(svg/compare-arrows.svg) no-repeat center;
    opacity: 0.7;
    transition: opacity var(--transition);
    transform: translate(-50%, -50%);
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
}

.compare__before,
.compare__after {
  display: block;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  pointer-events: none;
  user-select: none;

  img {
    width: 100%;
    height: auto;
  }
}

.compare__after {
  clip-path: polygon(var(--position) 0, 100% 0, 100% 100%, var(--position) 100%);
}
