@use "../../common/screen";

.program-description {
}

.program-description__banner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  min-height: 410px;
  margin-bottom: 40px;
  padding: 20px 20px 40px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 30px;
  background: #fff;

  @include screen.from(screen.$sm) {
    min-height: 500px;
    padding-top: 40px;
  }

  @include screen.from(screen.$md) {
    min-height: 320px;
    padding: 40px 40px 60px;
  }

  @include screen.from(screen.$xl) {
    min-height: 480px;
    margin-bottom: 50px;
    padding: 70px 90px;
  }
}

.program-description__banner-picture {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: block;
  width: 207px;
  transform: translateX(-50%);

  @include screen.from(screen.$sm) {
    width: 269px;

    img {
      position: relative;
    }
  }

  @include screen.from(screen.$md) {
    top: 0;
    right: 54px;
    bottom: 0;
    left: auto;
    width: 235px;
    transform: none;
  }

  @include screen.from(screen.$xl) {
    right: 126px;
    width: 347px;
  }
}

.program-description__banner-title {
  position: relative;
  color: var(--color-accent);
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;

  @include screen.from(screen.$md) {
    text-align: left;
  }

  @include screen.from(screen.$xl) {
    font-size: 32px;
  }
}

.program-description__banner-text {
  position: relative;
  max-width: 360px;
  padding: 20px;
  border-radius: 16px;
  background: var(--color-accent);
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%; /* 28px */
  text-transform: uppercase;

  @include screen.from(screen.$xl) {
    max-width: 540px;
    padding: 35px 45px;
    font-size: 32px;
  }
}

.program-description__list {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;
  margin-bottom: 60px;

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.program-description__item {
  padding: 20px 12px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 20px;
  background: #fff;
  font-size: 14px;
  line-height: 120%;
}

.program-description__title {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 66px;
  margin-bottom: 12px;
  color: var(--color-accent);
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
}

.program-description__img {
  display: block;
  width: 100%;
  height: 179px;
  margin-bottom: 12px;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}
