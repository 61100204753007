@use "../../common/screen";

.delivery {
}

.delivery__subheading {
  margin-bottom: 18px;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: normal;
  text-transform: uppercase;

  @include screen.from(screen.$xl) {
    margin-bottom: 24px;
    font-size: 20px;
  }
}

.delivery__main {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #c7c7c7;

  @include screen.from(screen.$md) {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }

  @include screen.from(screen.$xl) {
    margin-bottom: 50px;
    padding-bottom: 50px;
  }
}

.delivery__main-layout {
  display: grid;
  grid-template:
    'text'
    'prices'
    'truck'
    'notice'
    'schedule' 1fr
    / 100%;
  gap: 36px;

  @include screen.from(screen.$md) {
    grid-template:
      'text     truck'
      'prices   truck'
      'notice   notice'
      'schedule schedule' 1fr
      / 441px minmax(0, 1fr);
  }

  @include screen.from(screen.$xl) {
    grid-template:
      'text     truck'
      'prices   truck'
      'notice   notice'
      'schedule schedule' 1fr
      / 580px minmax(0, 1fr);
    max-width: 965px;
  }
}

.delivery__main-text {
  grid-area: text;

  @include screen.from(screen.$md) {
    font-size: 16px;
  }

  @include screen.from(screen.$xl) {
    font-size: 20px;
  }
}

.delivery__prices-table {
  grid-area: prices;
  max-width: 400px;

  th {
    padding-bottom: 18px;
    font-weight: 700;
    font-style: normal;
    font-size: 18px;
    line-height: normal;
    text-align: left;
    text-decoration-line: underline;

    &:not(:last-of-type) {
      padding-right: 10px;
    }
    @include screen.from(screen.$md) {
      &:not(:last-of-type) {
        padding-right: 40px;
      }
    }

    @include screen.from(screen.$xl) {
      font-size: 20px;
    }
  }

  td {
    padding-bottom: 18px;

    &:not(:last-of-type) {
      padding-right: 10px;
    }

    @include screen.from(screen.$md) {
      font-size: 16px;

      &:not(:last-of-type) {
        padding-right: 40px;
      }
    }

    @include screen.from(screen.$xl) {
      font-size: 20px;
    }
  }

  tr:last-of-type td {
    padding-bottom: 0;
  }

  @include screen.from(screen.$md) {
    max-width: 100%;
  }
}

.delivery__truck {
  grid-area: truck;
  justify-self: center;
  max-width: 240px;
}

.delivery__notice {
  grid-area: notice;
}

.delivery__schedule {
  grid-area: schedule;
  font-size: 16px;

  @include screen.from(screen.$xl) {
    font-size: 20px;
  }
}

.delivery__schedule-link {
  display: block;
  margin-top: 14px;
  color: var(--color-links);
  transition: color var(--transition);

  &:hover,
  &:focus-visible {
    color: var(--color-links-hover);
  }

  .delivery__schedule-link-text {
    text-decoration: underline;
  }

  @include screen.from(screen.$md) {
    display: inline-block;
  }
}

.delivery__payment-text {
  margin-bottom: 20px;
  font-size: 16px;

  @include screen.from(screen.$md) {
    margin-bottom: 30px;
  }

  @include screen.from(screen.$xl) {
    margin-bottom: 40px;
    font-size: 20px;
  }
}

.delivery__card {
  display: block;
  width: 100%;
  max-width: 160px;
  margin: 40px auto 0;
}

.delivery__credit {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 16px;

  @include screen.from(screen.$md) {
    flex-direction: row;
    align-items: center;
    margin-top: 60px;
    margin-bottom: 30px;
  }

  @include screen.from(screen.$xl) {
    max-width: 80%;
    margin: 40px 0;
    font-size: 20px;
  }
}

.delivery__credit-logo {
  display: block;
  align-self: center;
  margin-bottom: 14px;

  @include screen.from(screen.$md) {
    margin-right: 35px;
    margin-bottom: 0;
  }
}

.delivery__legal {
  margin-top: 20px;
  font-size: 16px;

  @include screen.from(screen.$sm) {
    margin-top: 40px;
  }

  @include screen.from(screen.$md) {
    margin-top: 50px;
  }

  @include screen.from(screen.$xl) {
    max-width: 90%;
    font-size: 20px;
  }
}
