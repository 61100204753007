@use "../common/screen";

.tabs__swiper {
  position: relative;
  overflow: visible;
  margin-bottom: 18px;

  &::before {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--color-text-main);
  }

  @include screen.from(screen.$xl) {
    margin-bottom: 40px;
  }
}

.tabs__tabs {
  position: relative;
  display: flex;

  &::before {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    height: 1px;
    background: var(--color-text-main);
  }
}

.tabs__tab {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 13px;
  border-bottom: 3px solid transparent;
  background: transparent;
  color: var(--color-text-main);
  outline: none;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 140%; /* 28px */
  letter-spacing: 0.4px;
  text-align: center;
  transition: border-color var(--transition), color var(--transition);
  appearance: none;

  &.is-active {
    border-color: var(--color-text-main);
    font-weight: 700;
  }

  @include screen.from(screen.$xl) {
    font-size: 20px;
  }
}
