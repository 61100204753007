@use "../../common/screen";

.altasib_reservation_form {
  width: 99%;
  margin: 0;
  padding: 10px 0 0;
}

.altasib_reservation_form form {
  margin: 0;
  padding: 0;
}

.altasib_reservation_error_block {
  margin: 10px 0 0;
  padding: 10px 10px 8px;
  border: 1px solid #959595 !important;
  background-image: url(blocks/altasib_reservation/images/bg_mess.png);
  background-position: 0 0;
}

.altasib_reservation_error_block td {
  vertical-align: top;
  background: transparent !important;
  text-align: left;
}

.altasib_reservation_error_pic {
  vertical-align: top;
  width: 1%;
  padding-top: 3px !important;
  padding-right: 11px !important;
  padding-left: 1px !important;
  border-right: 1px solid #d7d7d7 !important;
  text-align: left;
}

.altasib_reservation_error_block .altasib_reservation_title_error {
  margin: 0 0 4px;
}

.altasib_reservation_error_block .altasib_reservation_error_td_list {
  padding-left: 10px !important;
}

.altasib_reservation_error_block .altasib_reservation_mess_ok_td_list {
  vertical-align: middle;
  padding-left: 10px !important;
}

.altasib_reservation_error_block ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.altasib_reservation_error_block ul li {
  margin: 0;
  padding: 0 0 4px 8px;
  list-style: none;
}

.altasib_reservation_error_block ul li span {
  position: relative;
  z-index: 1;
  margin-left: -8px;
}

.altasib_reservation_form .altasib_reservation_poles {
  margin: 0;
  padding: 0;
}

.altasib_reservation_form .altasib_reservation_poles .altasib_reservation_item_pole {
  margin: 0 0 10px;
  padding: 0;
}

.altasib_reservation_form
  .altasib_reservation_poles
  .altasib_reservation_item_pole
  .altasib_reservation_name {
  margin: 0 0 6px;
  padding: 0;
}

.altasib_reservation_form
  .altasib_reservation_poles
  .altasib_reservation_item_pole
  .altasib_reservation_inptxt_bg {
  padding: 5px 8px;
  border: 1px solid #959595;
  background: #fff;
}

.altasib_reservation_form
  .altasib_reservation_poles
  .altasib_reservation_error_pole
  .altasib_reservation_inptxt_bg {
  background: url(blocks/altasib_reservation/images/bg_input.gif) 0 0;
}

.altasib_reservation_form
  .altasib_reservation_poles
  .altasib_reservation_item_pole
  .altasib_reservation_inptxt_bg
  input {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
}

.altasib_reservation_form
  .altasib_reservation_poles
  .altasib_reservation_item_pole
  .altasib_reservation_inptxt_bg
  textarea {
  z-index: 100;
  overflow: auto;
  width: 100% !important;
  height: 77px;
  margin: 0;
  padding: 0 !important;
  border: 0;
  background: transparent;
}

.altasib_reservation_form .altasib_reservation_poles .altasib_reservation_submit,
.altasib_reservation_run_win_mini .altasib_reservation_submit {
  display: block;
  float: left;
  margin: 23px 10px 0 0;
  padding: 0;
  border: 1px solid #959595;
  background: url(blocks/altasib_reservation/images/bg_submit.gif) 0 0 repeat-x;
}

.altasib_reservation_form .altasib_reservation_poles .altasib_reservation_submit.submit_send {
  border: 1px solid #297b52;
  background: url(blocks/altasib_reservation/images/bg_submit_send.gif) repeat-x scroll 0 0 #029c02;
  color: #fff;
}

.altasib_reservation_iesubmit_send input {
  color: #fff !important;
}

.altasib_reservation_form .altasib_reservation_poles .altasib_reservation_submit input,
.altasib_reservation_run_win_mini .altasib_reservation_submit input {
  overflow: visible;
  height: 28px;
  padding: 0 40px !important;
  border: 0;
  background: transparent;
  color: black;
  font: normal 12px tahoma, sans-serif;
  cursor: pointer;
}

.altasib_reservation_form .altasib_reservation_poles .altasib_reservation_inptxt_bg select {
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0;
}

.altasib_reservation_form .altasib_reservation_item_elem {
  margin: 0;
  padding: 0;
}

.altasib_reservation_form .altasib_reservation_item_elem table {
  width: 100%;
}

.altasib_reservation_form .altasib_reservation_item_elem table td {
  vertical-align: top;
  margin: 0;
  padding: 0;
  background: transparent !important;
  text-align: left;
}

.altasib_reservation_form .altasib_reservation_item_elem table td.altasib_reservation_pic {
  width: 20px;
  padding: 0 15px 0 0 !important;
}

.altasib_reservation_form .altasib_reservation_item_elem table td.altasib_reservation_pic img {
  padding: 1px;
}

.altasib_reservation_form .altasib_reservation_item_elem table td.altasib_reservation_name_elem {
  padding: 0;
  text-align: left;
}

.altasib_reservation_form
  .altasib_reservation_item_elem
  table
  td.altasib_reservation_name_elem
  .altasib_reservation_name {
  color: #b80000;
  font-size: 17px;
}

.altasib_reservation_form #altasib_reservation_infoclk_elem {
  margin: 0 0 15px;
  padding: 0 0 10px;
  border-bottom: 1px dotted #959595;
}

#altasib_reservation_res,
#altasib_reservation_mini {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  display: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: rgb(28 28 28 / 70%);
  // animation: fade-in 200ms both;
  backdrop-filter: blur(2.5px);
}

.altasib_reservation_run_window,
.altasib_reservation_run_win_mini {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  display: none;
  width: 100%;
  height: 100%;
  margin: 0;
  background: #fff;
  font-size: 1.2em;

  @include screen.from(400px) {
    top: 50%;
    left: 50%;
    width: 374px;
    max-width: 90%;
    height: auto;
    max-height: 90%;
    border: 1px solid var(--color-lines-dark);
    border-radius: 10px;
    box-shadow: 0 -2px 30px 0 rgb(0 0 0 / 10%);
    transform: translate(-50%, -50%);
  }
}

img.altasib_reservation_btn_close {
  float: right;
  margin: -55px -55px 0 0;
}

.altasib_reservation_form {
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 24px;
}

.altasib_reservation_error_block {
  border-radius: 3px;
  background-color: #fff;
  color: #fff;
  font-size: 12px;
}

.altasib_reservation_error_list {
  color: #000;
  font-size: 12px;
}

.altasib_reservation_title_err {
  color: #a90000;
  font-size: 12px;
}

.altasib_reservation_mess_ok {
  color: #963258;
  font-size: 12px;
}

.altasib_reservation_form .altasib_reservation_poles .altasib_reservation_name {
  color: #000;
  font-size: 12px;
}

.altasib_reservation_form .altasib_reservation_poles .altasib_reservation_inptxt_bg,
.altasib_reservation_form .altasib_reservation_poles .altasib_reservation_submit {
  border-radius: 3px;
}

.altasib_reservation_form .altasib_reservation_poles .altasib_reservation_inptxt_bg input,
.altasib_reservation_form .altasib_reservation_poles .altasib_reservation_inptxt_bg textarea,
.altasib_reservation_form .altasib_reservation_poles .altasib_reservation_inptxt_bg select {
  color: #727272 !important;
  font-size: 12px;
}

.altasib_reservation_form .altasib_reservation_poles .altasib_reservation_finp_add {
  color: #727272;
  font-size: 12px;
}

.altasib_reservation_form .altasib_reservation_poles .altasib_reservation_required_text {
  color: red;
}

.altasib_reservation_item_elem_title {
  margin: -10px 0 10px;
  padding: 10px;
  border-radius: 3px;
  background-color: #4d6990;
  color: #fff;
  font-size: 14px;
}

.altasib_reservation_item_elem_title a.altasib_reservation_close {
  display: block;
  float: right;
  width: 16px;
  height: 16px;
  background: url(blocks/altasib_reservation/images/close.png) no-repeat scroll 0 0 transparent;
}
