@use "../common/screen";

.popup-window {
  border: 1px solid var(--color-lines-dark);
  border-radius: 10px;
  background-color: #fff;
  color: var(--color-text-main);
  box-shadow: 0 -2px 30px 0 rgb(0 0 0 / 10%);
  font-weight: 400;
  font-size: 14px;
  font-family: Inter, sans-serif;
  line-height: 1.2;
  animation: fade-in 400ms 200ms both, slide-up 400ms 200ms both;
}

.popup-window.popup-window-with-titlebar {
  padding: 24px;
}

.popup-window-overlay {
  background: rgb(28 28 28 / 70%);
  opacity: 1;
  animation: fade-in 200ms both;
  backdrop-filter: blur(2.5px);
}

.popup-window-titlebar-text {
  display: block;
  margin-bottom: 24px;
  color: var(--color-text-main);
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;

  @include screen.from(540px) {
    padding: 0;
    font-size: 20px;
    text-align: center;
  }
}

.popup-window-titlebar-close-icon {
  position: absolute;
  top: 14px;
  right: 12px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 0;
  background-color: transparent;
  color: var(--color-pink-main);
  opacity: 1;
  cursor: pointer;
  transition: color var(--transition);

  &:hover,
  &:focus-visible {
    color: var(--color-accent);
  }

  &::after {
    display: none;
  }

  &::before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background-color: currentcolor;
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    mask-image: url(svg/icons/close-30.svg);
  }

  @include screen.from(540px) {
    top: -5px;
    right: -50px;
    color: #fff;

    &:hover,
    &:focus-visible {
      color: var(--color-links-hover-light);
    }

    &::before {
      width: 30px;
      height: 30px;
    }
  }
}

.popup-window-product {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  img {
    margin-bottom: 24px;
  }

  p {
    font-weight: 500;
  }
}
