.form-label {
  display: block;
  font-size: 14px;
}

.form-label__required {
  color: #ea4242;
}

.form-control {
  position: relative;
  width: 100%;
  min-height: 40px;
  padding: 10px 16px;
  border: 1px solid #9d9d9d;
  background: #f5f5f5;
  outline: none;
  font-size: 14px;
  line-height: normal;
  resize: none;
  transition: border-color var(--transition), opacity var(--transition);
  appearance: none;
  scrollbar-width: none;

  &:disabled {
    opacity: 0.3;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    border-color: var(--color-links-hover);
  }

  &::-ms-clear {
    display: none;
  }

  &::placeholder {
    color: #d0d0d0;
    opacity: 1;
  }
}

.form-control--is-transparent {
  background: transparent;

  &::placeholder {
    color: var(--color-text-disabled);
    opacity: 1;
  }
}
