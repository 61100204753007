.bullet-list {
  li {
    position: relative;
    padding-left: 1em;

    &::before {
      content: '';
      position: absolute;
      top: 0.35em;
      left: 0;
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: var(--color-accent);
    }

    &:not(:last-of-type) {
      margin-bottom: 0.6em;
    }

    b {
      font-weight: 700;
    }

    em {
      color: var(--color-accent);
      font-style: normal;
    }
  }
}
