@use "../../common/screen";

.products-slider {
  --swiper-navigation-sides-offset: -16px;

  overflow: hidden;

  .swiper-button-prev,
  .swiper-button-next {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-top: 0;
    border-radius: 100%;
    background-color: rgb(49 92 193 / 60%);
    color: #fff;
    transition: background-color var(--transition), color var(--transition), opacity var(--transition);

    &::after {
      content: '';
      width: 20px;
      height: 20px;
      background: currentcolor;
      mask-repeat: no-repeat;
      mask-size: 100% 100%;
    }

    &.swiper-button-disabled {
      opacity: 0;
    }

    @include screen.to(screen.$md) {
      display: none;
    }
  }

  .swiper-button-prev::after {
    mask-image: url(svg/icons/angle-left-20.svg);
  }

  .swiper-button-next::after {
    mask-image: url(svg/icons/angle-right-20.svg);
  }
}

.products-slider__inner {
  position: relative;
}

.products-slider__heading {
  text-align: center;
}

.products-slider__swiper {
  overflow: visible;
  padding: 40px 0;

  @include screen.from(screen.$md) {
    overflow: hidden;
  }
}

.products-slider__slide {
  display: flex;
  width: 208px;
  height: auto;

  @include screen.from(screen.$md) {
    width: auto;
  }
}
