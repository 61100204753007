.dropdown {
}

.dropdown__button {
  position: relative;
  display: inline-flex;
  justify-content: start;
  align-items: center;
  min-height: 22px;
  padding: 0 24px 0 0;
  border: transparent;
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  transition: color var(--transition);
  appearance: none;

  &:disabled {
    color: var(--color-text-alt);
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    color: var(--color-links-hover);
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: 20px;
    height: 20px;
    background-color: currentcolor;
    transition: transform var(--transition);
    transform: translateY(-50%);
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
    mask-image: url(svg/icons/angle-down-20.svg);
  }

  &[aria-expanded='true'] {
    &::after {
      transform: translateY(-50%) scaleY(-1);
    }
  }
}

.dropdown__body {
  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--z-index-dropdown);
  width: max-content;
  padding: 5px 0;
  background: #fff;
  box-shadow: 0 8px 36px 0 rgb(0 0 0 / 20%);
  opacity: 0;
  visibility: hidden;

  .dropdown__button[aria-expanded='true'] ~ &[data-dropdown-placement='top'] {
    animation: dropdown-body-top 0.2s ease both;
  }

  .dropdown__button[aria-expanded='true'] ~ &[data-dropdown-placement='bottom'] {
    animation: dropdown-body-bottom 0.2s ease both;
  }
}

@keyframes dropdown-body-top {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-5px);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}

@keyframes dropdown-body-bottom {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(5px);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: none;
  }
}

.dropdown__menu-link {
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  min-height: 22px;
  padding: 7px 14px;
  border: transparent;
  font-size: 14px;
  line-height: 1.2;
  text-align: left;
  cursor: pointer;
  transition: color var(--transition);
  appearance: none;

  .i {
    margin-right: 8px;
  }

  &:hover,
  &:focus-visible {
    color: var(--color-links-hover);
  }
}
