@use "../common/screen";

.text {
  line-height: 130%;

  @include screen.from(screen.$sm) {
    font-size: 16px;
  }

  @include screen.from(screen.$xl) {
    font-size: 20px;
  }
}
