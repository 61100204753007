@use "../../common/screen";

.warranty {
}

.warranty__subheading {
  margin-bottom: 18px;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: normal;
  text-transform: uppercase;

  @include screen.from(screen.$xl) {
    margin-bottom: 24px;
    font-size: 20px;
  }
}

.warranty__top {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;
  margin-bottom: 20px;

  @include screen.from(screen.$sm) {
    grid-template-columns: 234px 1fr;
    align-items: center;
  }

  @include screen.from(screen.$md) {
    grid-template-columns: 432px 1fr;
    margin-bottom: 30px;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: 520px 1fr;
  }
}

.warranty__top-text {
  font-size: 16px;
}

.warranty__top-picture {
  display: block;
  justify-self: center;
  max-width: 95px;

  @include screen.from(screen.$xl) {
    justify-self: start;
    max-width: 134px;
  }
}

.warranty__expire {
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 10px 8px;
  border: 2px solid #92c697;
  border-radius: 14px;
  background: #daefdc;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 130%;
  text-align: center;

  @include screen.from(screen.$sm) {
    margin-bottom: 30px;
    padding: 16px 40px;
    font-size: 16px;
  }

  @include screen.from(screen.$md) {
    padding: 16px 100px;
  }

  @include screen.from(screen.$xl) {
    margin-top: 40px;
    margin-bottom: 50px;
  }
}

.warranty__event {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;
  margin-bottom: 20px;

  @include screen.from(screen.$sm) {
    margin-bottom: 30px;
  }

  @include screen.from(screen.$md) {
    grid-template-columns: 309px 1fr;
    gap: 10px;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: 386px 1fr;
    gap: 40px;
    margin-bottom: 50px;
  }
}

.warranty__event-picture {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  border-radius: 38px;
  background: #d9d9d9;

  &::before {
    content: '';
    display: block;
    padding-top: calc(260 / 321 * 100%);
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @include screen.from(screen.$sm) {
    &::before {
      padding-top: calc(260 / 440 * 100%);
    }
  }

  @include screen.from(screen.$md) {
    &::before {
      padding-top: calc(460 / 309 * 100%);
    }
  }

  @include screen.from(screen.$xl) {
    border-radius: 50px;

    &::before {
      padding-top: calc(394 / 386 * 100%);
    }
  }
}

.warranty__event-list {
  counter-reset: list;
}

.warranty__event-item {
  position: relative;
  padding-left: 54px;
  line-height: 130%;
  counter-increment: list;

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  em {
    color: var(--color-accent);
    font-style: normal;
  }

  &::before {
    content: counter(list);
    position: absolute;
    top: -5px;
    left: 0;
    display: block;
    color: #a7baed;
    font-weight: 700;
    font-size: 70px;
    line-height: 1;
  }

  @include screen.from(screen.$sm) {
    font-size: 16px;
  }

  @include screen.from(screen.$xl) {
    font-size: 20px;

    &:not(:last-of-type) {
      margin-bottom: 36px;
    }
  }
}

.warranty__return-top {
  margin-bottom: 20px;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 130%;

  em {
    color: var(--color-accent);
    font-style: normal;
  }

  @include screen.from(screen.$md) {
    max-width: 500px;
    margin-bottom: 30px;
  }

  @include screen.from(screen.$xl) {
    margin-bottom: 50px;
    font-size: 20px;
  }
}

.warranty__defects {
  margin-top: 40px;
  margin-bottom: 32px;

  @include screen.from(screen.$md) {
    display: grid;
    grid-template-columns: 495px 1fr;
    gap: 20px;
    align-items: center;
    margin-bottom: 45px;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: 1fr 560px;
    gap: 40px;
    max-width: 960px;
  }
}

.warranty__defects-picture {
  display: none;

  @include screen.from(screen.$md) {
    display: block;
    order: 1;
  }

  @include screen.from(screen.$xl) {
    order: -1;
  }
}

.warranty__ordered-list {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 130%;
  counter-reset: list;

  li {
    counter-increment: list;

    &:not(:last-of-type) {
      margin-bottom: 1.5em;
    }

    &::before {
      content: counter(list) ')\00a0';
    }
  }
}

.warranty__order {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.warranty__order-heading {
  margin-bottom: 12px;
  color: var(--color-accent);
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 130%;

  @include screen.from(screen.$xl) {
    margin-bottom: 24px;
    font-size: 20px;
  }
}

.warranty__order-section {
  @include screen.to(screen.$xl) {
    &:not(:last-of-type) {
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #a3a3a3;
    }
  }

  @include screen.from(screen.$xl) {
    &:not(:first-of-type) {
      padding-left: 60px;
    }

    &:not(:last-of-type) {
      padding-right: 60px;
      border-right: 1px solid #a3a3a3;
    }
  }
}

.warranty__legal {
  margin-top: 20px;
  font-size: 16px;

  @include screen.from(screen.$sm) {
    margin-top: 40px;
  }

  @include screen.from(screen.$md) {
    margin-top: 50px;
  }

  @include screen.from(screen.$xl) {
    max-width: 90%;
    font-size: 20px;
  }
}
