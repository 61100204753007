.contacts-form {
  p {
    margin-bottom: 16px;
  }

  label {
    display: block;
  }

  .l-row:not(:last-of-type) {
    margin-bottom: 20px;
  }

  .f-input {
    position: relative;
    width: 100%;
    margin-top: 6px;
    padding: 12px;
    border: 1px solid #9d9d9d;
    background: #f5f5f5;
    outline: none;
    font-size: 16px;
    line-height: normal;
    resize: none;
    transition: border-color var(--transition), box-shadow var(--transition),
      opacity var(--transition);
    appearance: none;
    scrollbar-width: none;

    &:disabled {
      opacity: 0.3;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
    }

    &::-ms-clear {
      display: none;
    }

    &::placeholder {
      color: #d0d0d0;
      opacity: 1;
    }
  }

  .f-button {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 65px;
    padding: 18px 25px;
    border: 1px solid #315cc1;
    border-radius: 16px;
    background: #315cc1;
    color: #fff;
    outline: none;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 140%; /* 28px */
    letter-spacing: 0.4px;
    text-align: center;
    text-transform: uppercase;
    transition: background-color var(--transition), color var(--transition);
    appearance: none;

    &:disabled {
      background-color: #9d9d9d;
      color: var(--color-text-alt);
    }

    &:not(:disabled) {
      cursor: pointer;
    }

    &:hover:not(:disabled),
    &:focus-visible:not(:disabled) {
      background-color: #e8efff;
      color: #315cc1;
    }

    &:active:not(:disabled) {
      background-color: #fff;
      color: #315cc1;
    }
  }
}
