@use "../common/screen";

.product-manual {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;
  margin-bottom: 60px;

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.product-manual__item {
  padding: 20px 12px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 20px;
  background: #fff;
  font-size: 14px;
  line-height: 120%;
}

.manual__title {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 66px;
  margin-bottom: 12px;
  color: var(--color-accent);
  font-weight: 600;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
}
