@use "../../common/screen";

.info-cards {
  overflow: hidden;
}

.info-cards__swiper {
  --swiper-pagination-bottom: 0;

  overflow: visible;
  padding-bottom: 30px;

  @include screen.from(screen.$xl) {
    padding-bottom: 0;

    .swiper-pagination {
      display: none;
    }
  }
}

.info-cards__card {
  overflow: hidden;
  max-width: 260px;
  height: auto;
  margin-right: 20px;
  padding: 20px 14px;
  border-radius: 20px;

  &.is-grey {
    background: #f3f3f3;
  }

  &.is-yellow {
    background: #fbf9d9;
  }

  &.is-green {
    background: #daefdc;
  }

  &.is-blue {
    background: #d4ecf9;
  }

  @include screen.from(screen.$sm) {
    max-width: 374px;
  }

  @include screen.from(screen.$md) {
    max-width: 440px;
  }

  @include screen.from(screen.$xl) {
    max-width: 100%;
  }
}

.info-cards__card-title {
  margin-bottom: 12px;
  color: var(--color-accent);
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 130%;
  text-align: center;

  &:not(:first-of-type) {
    margin-top: 12px;
  }
}

.info-cards__card-list {
  li {
    padding-left: 15px;
    background: url(svg/list-checkmark-bullet.svg) no-repeat top 2px left;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }

  small {
    display: block;
    margin-top: 4px;
    font-size: 12px;
  }
}
