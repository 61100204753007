@use "../common/screen";

.kit-contents__list {
  display: grid;
  grid-template-columns: 100%;
  gap: 20px;

  @include screen.from(screen.$sm) {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }

  @include screen.from(screen.$md) {
    grid-template-columns: repeat(3, 1fr);
    gap: 14px;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
  }
}

.kit-contents__link {

}
