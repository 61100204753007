.icon-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, 252px);
  gap: 20px;
  justify-content: center;

  @supports (grid-template-columns: subgrid) {
    grid-auto-rows: auto auto;
  }
}

.icon-cards__item {
  position: relative;
  grid-column: span 1;
  padding-top: 90px;

  @supports (grid-template-columns: subgrid) {
    display: grid;
    grid-template-rows: subgrid;
    grid-template-columns: 100%;
    grid-row: span 2;
    gap: 0;
  }
}

.icon-cards__icon {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
}

.icon-cards__body {
  padding: 70px 20px 20px;
  border-radius: 18px;
  background: #fff;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 8%);
}

.icon-cards__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 130%;
  text-align: center;
}

.icon-cards__description {
  margin-top: 10px;
  line-height: 130%;
}

.icon-cards__tip {
  margin-top: 10px;
  padding-left: 20px;
  color: var(--color-accent);
}
