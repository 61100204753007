@use "../../common/screen";

.reviews-preview__swiper-wrap {
  position: relative;
}

.reviews-preview__swiper {
  --swiper-theme-color: var(--color-accent);
  --swiper-navigation-color: var(--color-text-main);
  --swiper-pagination-bottom: 0;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-horizontal-gap: 3px;
  --swiper-pagination-bullet-inactive-color: transparent;
  --swiper-pagination-bullet-inactive-opacity: 1;

  padding-bottom: 30px;

  .swiper-pagination-bullet {
    border: 1px solid var(--color-accent);
  }

  @include screen.to(screen.$xl) {
    overflow: visible;

    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }
  }

  @include screen.from(screen.$xl) {
    --swiper-navigation-sides-offset: 40px;

    position: unset;
    margin-right: 100px;
    margin-left: 100px;
    padding-bottom: 0;

    .swiper-pagination {
      display: none;
    }
  }
}

.reviews-preview__item {
  display: grid;
  grid-template:
    'photo name'
    'photo props'
    'text text' 1fr
    'more more'
    / 60px minmax(0, 1fr);
  gap: 15px 20px;
  overflow: hidden;
  height: 483px;
  padding: 20px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 20px;
  background: #fff;

  @include screen.from(screen.$md) {
    grid-template:
      'photo name'
      'photo props'
      'text text' 1fr
      'more more'
      / 80px minmax(0, 1fr);
  }
}

.reviews-preview__photo {
  grid-area: photo;
  overflow: hidden;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background: url(blocks/reviews-preview/photo-bg.svg) no-repeat center;
  background-size: cover;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @include screen.from(screen.$md) {
    width: 80px;
    height: 80px;
  }
}

.reviews-preview__name {
  grid-area: name;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: normal;
}

.reviews-preview__props {
  grid-area: props;
  color: var(--color-text-alt);
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: normal;
}

.reviews-preview__text {
  grid-area: text;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 130%;
}

.reviews-preview__link-wrap {
  grid-area: more;
  margin-top: 5px;
}

.reviews-preview__link {
  color: var(--color-text-alt);
  text-align: center;
  text-decoration: underline;
  transition: color var(--transition);

  &:hover,
  &:focus-visible {
    color: var(--color-links-hover);
  }
}

.reviews-preview__view-all {
  display: block;
  margin-top: 24px;
  text-align: center;
}
