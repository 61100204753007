.details-group {
  details {
    &[open] {
      padding-bottom: 1.2em;
    }
  }

  details:not(:last-child) {
    border-bottom: 1px solid #b8b8b8;
  }

  summary {
    position: relative;
    padding: 1em 2.2em 1em 0;
    outline: none;
    list-style: none;
    font-weight: 700;
    cursor: pointer;
    user-select: none;
    transition: color var(--transition);

    &::-webkit-details-marker {
      display: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 20px;
      height: 20px;
      background-color: currentColor;
      transition: transform 0.3s ease;
      transform: translateY(-50%);
      mask-image: url(svg/icons/plus-20.svg);
    }
  }

  details[open] summary {
    color: var(--color-accent);

    &::after {
      transform: translateY(-50%) rotate(45deg);
    }
  }

  .details__body {
    padding-top: 1em;

    & > *:first-child {
      margin-bottom: 0;
    }

    & > * + * {
      margin-top: 1em;
      margin-bottom: 0;
    }
  }
}

