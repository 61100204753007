@use "../../common/screen";

.basket {
}

.basket__total-line {
  display: flex;
  justify-content: end;
  align-items: end;
  margin-bottom: 40px;
  padding: 20px 0;
  border-bottom: 1px solid #acacac;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  text-align: right;
  text-transform: uppercase;
  white-space: nowrap;

  @include screen.from(screen.$xl) {
    margin-bottom: 80px;
    padding: 30px 0;
  }
}

.basket__total-price {
  margin-bottom: -2px;
  margin-left: 10px;
  font-size: 22px;

  @include screen.from(screen.$sm) {
    margin-left: 28px;
    font-size: 24px;
  }
}

.basket__checkout-button {
  display: flex;
  width: 100%;
  max-width: 510px;
  margin: 0 auto;

  @include screen.to(screen.$sm) {
    padding-right: 16px;
    padding-left: 16px;
  }
}

.basket-notification-section {
  width: 100%;
}

.basket-block-notification {
  position: relative;
  display: block;
  width: 100%;
  min-width: 100%;
  margin-top: 10px;
  padding: 8px 15px;
  border-radius: 1px;
  background-color: #edf8e0;
  color: #6a9997;
}

.basket-block-notification::after {
  content: '';
  position: absolute;
  top: -7px;
  right: 80px;
  width: 16px;
  height: 16px;
  background-color: #edf8e0;
  transform: rotate(45deg);
  transform-origin: 50% 50%;
}

/* endregion */

/* region Basket item list wrapper */
.basket-items-list-wrapper,
.basket-items-list-wrapper-compact {
}

/* header */
.basket-items-list-header {
  display: flex;
  align-items: center;
  padding: 13px 0;
  border-bottom: 1px solid #acacac;
  transition: 300ms padding ease;
  -ms-flex-align: center;
  -webkit-box-align: center;
}

.basket-items-list-header.basket-items-list-header-fixed {
}

.basket-items-list-wrapper-light .basket-items-list-header {
  display: none !important;
}

.basket-items-list-header-title {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: 300ms all ease;
  -webkit-box-flex: 1;
}

.basket-items-search-field + .basket-items-list-header-title {
  padding-left: 10px;
}

.basket-items-search-field + .basket-items-list-header-filter {
  flex: 3;
  padding-left: 10px;
  text-align: right;
  -webkit-box-flex: 3;
}

.basket-items-search-field .form-control {
  z-index: 1;
}

.basket-items-list-header-filter-item {
  margin-left: 5px;
  border-bottom: 1px dotted;
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
  transition: 220ms ease;
}

.basket-items-list-header-filter-item:hover {
  color: var(--theme-color-second);
  text-decoration: none;
}

.basket-items-list-header-filter-item.active {
  border-bottom: none;
  color: #2f3435;
  text-decoration: none;
  cursor: default;
}

.basket-items-search-field {
  position: relative;
  flex: 1;
  transition: 300ms all ease;
  -webkit-box-flex: 1;
}

.basket-items-search-field .input-group-addon {
  flex-wrap: wrap;
  white-space: nowrap;
}

.basket-items-search-clear-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 1;
  vertical-align: middle;
  border: none;
  background: none;
  color: #777;
  font-weight: var(--ui-font-weight-bold);
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;
  transition: 250ms linear all;
  transform: translateY(-50%);
}

.basket-items-search-clear-btn:hover {
  color: #c04e4e;
}

/* endregion */

.basket-items-list-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.basket-items-list-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #fff;
  opacity: 0.7;
  animation-name: ShowOverlay;
  animation-duration: 200ms;
}

@keyframes ShowOverlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.7;
  }
}

@keyframes ShowOverlay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.7;
  }
}

.basket-items-list {
  position: relative;
}

.basket-items-list-table {
  width: 100%;
  border-collapse: collapse;

  @include screen.to(screen.$sm) {
    display: block;

    tr {
      display: grid;
      grid-template:
        'picture title    title remove'
        'picture props    props props'
        'picture quantity sum   sum' 1fr
        / 92px 1fr 1fr 20px;
    }

    tbody,
    td {
      display: block;
    }
  }

  @include screen.to(screen.$md) {
    tr {
      display: grid;
      grid-template:
        'picture title    title remove'
        'picture props    props props'
        'picture quantity sum   sum' 1fr
        / 132px 1fr 1fr 20px;
    }
  }
}

.basket__picture {
  @include screen.to(screen.$md) {
    grid-area: picture;
  }
}

.basket__title {
  @include screen.to(screen.$md) {
    grid-area: title;
    padding-bottom: 0 !important;
    border-bottom: 0 !important;
  }
}

.basket__remove {
  @include screen.to(screen.$md) {
    grid-area: remove;
    padding-bottom: 0 !important;
    border-bottom: 0 !important;
  }
}

.basket__props {
  @include screen.to(screen.$md) {
    grid-area: props;
    padding: 0 !important;
    border: none !important;
    color: #9b9b9b;
    font-size: 12px;
  }
}

.basket__price {
  @include screen.to(screen.$md) {
    display: none !important;
  }
}

.basket__quantity {
  @include screen.to(screen.$md) {
    grid-area: quantity;
    align-self: end;
  }
}

.basket__sum {
  @include screen.to(screen.$sm) {
    font-size: 18px;
  }

  @include screen.to(screen.$md) {
    grid-area: sum;
    align-self: end;
    font-size: 22px;
    text-align: right;
  }
}

.basket-items-list-item-container {
  position: relative;
}

.basket-items-list-item-container > td {
  vertical-align: center;
  padding: 14px 0;
  border-bottom: 1px solid #acacac;
  font-weight: 600;

  @include screen.from(screen.$md) {
    font-size: 12px;

    &:first-of-type {
      width: 80px;
      padding-right: 20px;
    }

    &:not(:nth-of-type(-n + 2), :last-of-type) {
      min-width: 70px;
      text-align: center;
    }
  }

  @include screen.from(screen.$xl) {
    padding-right: 32px;
    font-size: 14px;

    &:first-of-type {
      width: 92px;
      padding-right: 32px;
    }

    &:not(:nth-of-type(-n + 2), :last-of-type) {
      min-width: 160px;
    }
  }
}

.basket-items-list-item-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #fff;
  opacity: 0.7;
}

.basket-items-list-item-container.basket-items-list-item-container-gift,
.basket-items-list-item-container.basket-items-list-item-container-gift > td,
.basket-items-list .basket-items-list-item-container:first-child > td {
  border-top: none !important;
}

.basket-items-list-item-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background-color: #fff;
  opacity: 0.7;
}

.basket-items-list-item-descriptions {
  vertical-align: center;
  order: 1;
  width: 100%;
  padding: 14px 0;
  font-weight: 600;

  @include screen.from(screen.$md) {
    font-size: 12px;
  }

  @include screen.from(screen.$xl) {
    font-size: 14px;
  }
}

.basket-items-list-item-descriptions-inner {
  position: relative;
  display: flex;
  align-items: center;
}

/* region Image */
.basket-item-block-image {
  position: relative;
  flex: 0 0 auto;
  align-items: center;
  order: 1;
}

.basket-items-list-wrapper-compact .basket-item-block-image {
  display: block;
  width: auto;
  min-width: 32px;
  max-width: 50px;
  height: auto;
  padding-bottom: 0;
}

.basket-item-image-link {
  position: relative;
  display: block;
  overflow: hidden;
  width: 80px;
  height: 80px;
  padding: 5px;
  border: 1px solid var(--color-lines-dark);
  border-radius: 10px;
  background: #fff;

  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  @include screen.from(screen.$sm) {
    width: 120px;
    height: 120px;
  }

  @include screen.from(screen.$md) {
    width: 60px;
    height: 60px;
  }
}

.basket-item-image {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  transition: 300ms all ease;
}

.basket-items-list-wrapper-compact .basket-item-image {
  max-width: 50px;
  max-height: 50px;
}

.basket-item-image:hover {
  border-color: #ee9200;
}

/* endregion */

/* region lable */

/* Label */
.basket-item-label-text,
.basket-item-label-ring {
  position: absolute;
  z-index: 90;
  opacity: 1;
  transition: opacity 450ms ease;
}

.basket-items-list-wrapper-compact .basket-item-label-text,
.basket-items-list-wrapper-compact .basket-item-label-ring {
  display: none;
}

.basket-item-label-text span,
.basket-item-label-ring {
  display: inline-block;
  vertical-align: middle;
  clear: both;
  margin-bottom: 2px;
  background: #f42c2c;
  color: #fff;
  text-align: center;
  transition: all 250ms ease;
}

.bx-red .basket-item-label-text span,
.bx-red .basket-item-label-ring {
  background-color: #fba90c;
}

.basket-item-label-text > div,
.basket-item-label-ring > div {
  line-height: 0;
}

/* TEXT */
.basket-item-label-text span {
  vertical-align: middle;
  padding: 2px 5px;
  font-weight: var(--ui-font-weight-bold);
}

.basket-item-label-text.basket-item-label-small span {
  margin-bottom: 1px;
  padding: 2px 7px;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
}

.basket-item-label-text.basket-item-label-big span {
  padding: 4px 10px;
  font-size: 12px;
  line-height: 17px;
}

/* RING */
.basket-item-label-ring {
  box-sizing: border-box;
  border-radius: 50%;
  font-weight: var(--ui-font-weight-bold);
}

.basket-item-label-ring.basket-item-label-small {
  width: 40px;
  height: 40px;
  font-size: 13px;
  line-height: 40px;
}

.basket-item-label-ring.basket-item-label-big {
  width: 50px;
  height: 50px;
  font-size: 18px;
  line-height: 50px;
}

.basket-item-label-top {
  top: -2px;
}

.basket-item-label-center {
  left: 50%;
  transform: translateX(-50%);
}

.basket-item-label-center > div {
  text-align: center;
}

.basket-item-label-left {
  left: -2px;
}

.basket-item-label-left > div {
  text-align: left;
}

.basket-item-label-bottom {
  bottom: -2px;
}

.basket-item-label-middle {
  top: 50%;
  transform: translateY(-50%);
}

.basket-item-label-right {
  right: -2px;
}

.basket-item-label-right > div {
  text-align: right;
}

.basket-item-label-center.product-item-label-middle {
  transform: translateY(-50%) translateX(-50%);
}

/* endregion */

/* region Info */
.basket-item-block-info {
  flex: 0 1 auto;
  order: 2;
  padding: 0;
}

/* region Name */
.basket-item-info-name,
.basket-item-info-name-link {
  margin: 0;
  text-decoration: none;
  transition: 300ms all ease;

  @include screen.from(screen.$md) {
    padding: 0 20px 0 0;
  }
}

h2.basket-item-info-name {
  margin-bottom: 5px;
  padding-top: 0;

  @include screen.to(400px) {
    font-size: 12px;
  }
}

.basket-item-highlighted {
  background: #ff0;
}

.basket-items-list-wrapper-compact .basket-item-info-name {
  margin-bottom: 5px;
}

.basket-item-info-name-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* endregion */

.basket-item-block-common {
  display: flex;
  flex: 2;
  flex-wrap: nowrap;
  align-content: baseline;
  justify-content: flex-end;
  align-items: flex-start;
  order: 4;
  -ms-flex-order: 4;
  -ms-flex-pack: end;
  -ms-flex-align: start;
  -ms-flex-line-pack: baseline;
  -webkit-box-pack: end;
  -webkit-box-align: start;
  -webkit-box-ordinal-group: 5;
  -webkit-box-flex: 2;
}

.basket-items-list-wrapper-compact .basket-item-block-common {
  flex-wrap: nowrap;
  justify-content: flex-end;
  -ms-flex-pack: end;
  -webkit-box-pack: end;
}

/* region Properties */
.basket-item-block-properties {
  flex: 5;
}

.basket-item-property,
.basket-item-property-custom {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}

.basket-items-list-wrapper-compact .basket-item-property,
.basket-items-list-wrapper-compact .basket-item-property-custom {
  display: inline-flex;
  align-content: center;
  margin-right: 10px;
  padding-bottom: 0;
}

.basket-item-property-name,
.basket-item-property-custom-name {
  margin-right: 5px;
}

.basket-item-property-value,
.basket-item-property-custom-value {
  white-space: nowrap;
}

/* region Custom Column */

.basket-item-property-custom {
  padding-right: 15px;
}

.basket-item-property-custom-content {
  padding: 3px 0;
}

.basket-item-property-custom.basket-item-property-custom-photo {
  display: block;
}

.basket-item-property-custom-photo .basket-item-property-custom-value {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  min-height: 64px;
  word-spacing: 0;
  -ms-flex-pack: start;
  -ms-flex-align: stretch;
  -webkit-box-align: stretch;
  -webkit-box-pack: start;
}

.basket-item-property-custom-photo .basket-item-property-custom-value span {
  display: inline-block;
}

.basket-item-custom-block-photo-link {
  display: block;
  margin-right: 5px;
}

.basket-item-custom-block-photo-item {
  width: auto;
  height: 64px;
  margin-right: 3px;
  border: 1px solid #e4e4e4;
  transition: 300ms all ease;
}

.basket-item-custom-block-photo-item:hover {
  border-color: #ee9200;
}

/* endregion */

/* scu */
.basket-items-list-wrapper-compact .basket-item-property.basket-item-property-scu-text,
.basket-items-list-wrapper-compact .basket-item-property.basket-item-property-scu-image,
.basket-items-list-wrapper-compact .basket-item-property.basket-item-scu-color {
  display: inline-flex;
}

.basket-item-property.basket-item-property-scu-text,
.basket-item-property.basket-item-property-scu-image,
.basket-item-property.basket-item-scu-color {
  display: block;
}

.basket-item-scu-list {
  display: inline-flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
}

.basket-item-scu-item,
.basket-item-scu-item.not-available:hover {
  position: relative;
  display: inline-block;
  margin: 2px 3px 3px 0;
  padding: 0;
  border: 1px solid #eeecef;
  cursor: pointer;
  user-select: none;
  transition: 300ms all ease;
  -webkit-touch-callout: none;
}

.basket-item-scu-item:hover,
.basket-item-scu-item.selected,
.basket-item-scu-item.not-available:hover {
  border-color: var(--primary);
}

.basket-item-scu-item.selected:hover {
  cursor: default;
}

.basket-item-scu-item.not-available::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  background: no-repeat center
    url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20width%3D%2220px%22%20height%3D%2220px%22%20viewBox%3D%220%200%20100%25%20100%25%22%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%20%20%20%20%3Cline%20x1%3D%220%22%20y1%3D%2220%22%20x2%3D%2220%22%20y2%3D%220%22%20stroke-width%3D%221%22%20stroke%3D%22%23a1a1a1%22/%3E%0A%3C/svg%3E);
  background-size: 100% 100%;
}

.basket-item-scu-item-inner {
  display: block;
  transition: 300ms all ease;
}

.basket-item-scu-item.not-available .basket-item-scu-item-inner {
  opacity: 0.5;
}

.basket-item-scu-color .basket-item-scu-item-inner,
.basket-item-property-scu-image .basket-item-scu-item-inner {
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.basket-items-list-wrapper-compact .basket-item-scu-color .basket-item-scu-item-inner,
.basket-items-list-wrapper-compact .basket-item-property-scu-image .basket-item-scu-item-inner {
  width: 16px;
  height: 16px;
  border: 1px solid #fff;
}

.basket-item-property-scu-text .basket-item-scu-item-inner {
  min-width: 30px;
  padding: 3px 5px;
  text-align: center;
}

.basket-items-list-wrapper-compact .basket-item-property-scu-text .basket-item-scu-item-inner {
  padding: 1px 2px;
}

/* endregion */

/* region Amount */
.basket-items-list-item-amount {
}

.basket-item-block-amount {
  position: relative;
  display: flex;
  align-content: flex-start;
  justify-content: flex-start;

  @include screen.from(screen.$md) {
    justify-content: center;
  }
}

.basket-items-list-wrapper-compact .basket-item-block-amount {
  margin-bottom: 0;
  padding-bottom: 0;
}

.basket-item-amount-filed-block {
  position: relative;
}

.basket-item-amount-filed,
.basket-item-block-amount.disabled .basket-item-amount-filed:hover {
  position: relative;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  padding: 0;
  border: 1px solid #9b9b9b;
  border-radius: 1px;
  background: transparent;
  outline: none;
  text-align: center;
  transition: 300ms all ease;

  @include screen.from(screen.$sm) {
    width: 60px;
    height: 38px;
  }
}

.basket-item-block-amount.disabled .basket-item-amount-filed {
  background-color: transparent;
}

.basket-item-amount-filed:hover,
.basket-item-amount-filed:focus {
  z-index: 1;
  border-color: var(--color-links-hover);
}

.basket-item-amount-filed::-webkit-outer-spin-button,
.basket-item-amount-filed::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  appearance: none;
}

.basket-item-amount-filed.basket-updated {
  animation-name: basketAmountFiledUpdated;
  animation-duration: 600ms;
}

@keyframes basketAmountFiledUpdated {
  0%,
  100% {
    border-color: #e4e4e4;
  }

  50% {
    border-color: #ee9200;
  }
}

@keyframes basketAmountFiledUpdated {
  0%,
  100% {
    border-color: #e4e4e4;
  }

  50% {
    border-color: #ee9200;
  }
}

.basket-item-amount-btn-plus,
.basket-item-amount-btn-minus {
  position: relative;
  width: 32px;
  height: 32px;
  border: 1px solid #9b9b9b;
  cursor: pointer;
  user-select: none;
  transition: 300ms all ease;

  @include screen.from(screen.$sm) {
    width: 38px;
    height: 38px;
  }
}

.basket-item-amount-btn-minus {
  margin-right: -1px;
}

.basket-item-amount-btn-plus {
  margin-left: -1px;
}

.basket-item-amount-btn-plus:hover,
.basket-item-amount-btn-minus:hover {
  z-index: 1;
  border-color: var(--color-links-hover);
  background-color: transparent;
  color: var(--color-links-hover);
}

.basket-item-block-amount.disabled .basket-item-amount-btn-plus,
.basket-item-block-amount.disabled .basket-item-amount-btn-minus {
  background-color: transparent;
  cursor: default;
}

.basket-item-amount-btn-plus::before,
.basket-item-amount-btn-plus::after,
.basket-item-amount-btn-minus::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 2px;
  margin-top: -1px;
  margin-left: -5px;
  background-color: var(--color-text-main);
  transition: 300ms all ease;
}

.basket-item-amount-btn-plus:hover::before,
.basket-item-amount-btn-plus:hover::after,
.basket-item-amount-btn-minus:hover::after {
  background-color: #535353;
}

.basket-item-block-amount.disabled .basket-item-amount-btn-plus::before,
.basket-item-block-amount.disabled .basket-item-amount-btn-plus::after,
.basket-item-block-amount.disabled .basket-item-amount-btn-minus::after {
  background-color: #f3f3f3;
}

.basket-item-amount-btn-plus::before {
  width: 2px;
  height: 10px;
  margin-top: -5px;
  margin-left: -1px;
}

.basket-item-amount-field-description {
  position: absolute;
  top: calc(100% + 1px);
  right: 0;
  left: 0;
  color: #a1a1a1;
  text-align: center;
}

/* endregion */

/* region Price */
.basket-items-list-item-price {
  vertical-align: center;
  order: 4;
  padding-top: 0;
}

.basket-items-list-item-price-for-one {
  order: 2;
}

.basket-item-block-price {
  position: relative;
  white-space: nowrap;
}

.basket-item-price-title {
  display: none;
}

.basket-item-price-current {
  vertical-align: bottom;
  padding-top: 1px;
  color: #2f3435;
  line-height: 24px;
}

.basket-item-price-current-text {
  letter-spacing: -0.33px;
  transition: 180ms text-shadow ease, 180ms color ease;
}

.basket-item-price-old {
  position: absolute;
  top: 5px;
  right: 0;
  vertical-align: middle;
  color: #a1a1a1;
  line-height: 15px;
}

.basket-item-price-old-text {
  position: relative;
  display: inline-block;
}

.basket-item-price-old-text::after {
  content: '';
  position: absolute;
  top: 3px;
  right: 3px;
  bottom: 3px;
  left: 3px;
  background: no-repeat center
    url(data:image/svg+xml;charset=US-ASCII,%0A%3Csvg%20width%3D%22491px%22%20height%3D%22125px%22%20viewBox%3D%220%200%20100%25%20100%25%22%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M1.23046875%2C121.410156%20C122.764455%2C80.3998661%20212.227997%2C52.9779911%20269.621094%2C39.1445312%20C327.014191%2C25.3110714%20400.420441%2C13.476436%20489.839844%2C3.640625%22%20stroke%3D%22%23B80909%22%20stroke-width%3D%2216%22%20/%3E%0A%20%20%20%20%3C/g%3E%0A%3C/svg%3E);
  background-size: 100% 100%;
}

.basket-item-price-difference {
  max-width: 100%;
  padding: 3px;
  background: #d1ecf1;
  color: #179ebb;
  text-align: center;
  white-space: normal;
}

/* endregion */

/* region Actions */
.basket-items-list-item-remove {
  vertical-align: center;
  padding: 14px 0;

  @include screen.from(screen.$xl) {
    padding-right: 36px;
  }
}

.basket-item-block-actions {
  position: relative;
}

.basket-item-actions-remove {
  position: relative;
  display: block;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.basket-item-actions-remove::after,
.basket-item-actions-remove::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 14px;
  background: #acacac;
  transition: 300ms background-color ease;
}

.basket-item-actions-remove::after {
  transform: translate3d(-50%, -50%, 0) rotate(45deg);
}

.basket-item-actions-remove::before {
  transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}

.basket-item-actions-remove:hover::after,
.basket-item-actions-remove:hover::before {
  background-color: #7e000f;
}

.basket-item-block-info .basket-item-actions-remove {
  position: absolute;
  top: -4px;
  right: 0;
}

.basket-items-list-wrapper-compact .basket-item-block-actions {
  position: relative;
  top: auto;
  right: auto;
  padding-left: 10px;
}

.basket-items-list-item-notification-inner {
  position: relative;
  transition: 220ms all ease;
}

.basket-items-list-item-container.basket-items-list-item-container-expend {
  display: table-row;
}

.basket-items-list-item-container-expend .basket-items-list-item-removed-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-radius: 2px;
  background-color: #e6f8fe;
  color: #333;
  text-align: center;
  transition: 300ms all ease;
  -ms-flex-pack: justify;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -webkit-box-pack: justify;
}

.basket-items-list-item-container-expend .basket-items-list-item-removed-container div {
  padding: 0 10px;
  text-align: left;
}

@keyframes ShowNote {
  0% {
    opacity: 0;
  }

  0%,
  50% {
    background-color: #fff;
  }

  50%,
  100% {
    opacity: 1;
  }

  100% {
    background-color: #e6f8fe;
  }
}

@keyframes ShowNote {
  0% {
    opacity: 0;
  }

  0%,
  50% {
    background-color: #fff;
  }

  50%,
  100% {
    opacity: 1;
  }

  100% {
    background-color: #e6f8fe;
  }
}

.basket-items-list-item-container-collapse .basket-item-block-info,
.basket-items-list-item-container-collapse .basket-item-block-common,
.basket-items-list-item-container-collapse .basket-item-block-image {
  opacity: 0;
}

.basket-items-list-item-container.basket-items-list-item-container-collapse > td > div {
  overflow: hidden;
  height: 80px;
  opacity: 0;
}

.basket-items-list-item-removed-block {
  display: inline-flex;
  align-items: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
}

.basket-items-list-item-removed-block .faded {
  color: #ccc;
  cursor: default;
}

.basket-items-list-item-removed-anchor {
  border-bottom: 1px dashed;
  color: #333;
  font-weight: var(--ui-font-weight-bold);
}

.basket-items-list-item-clear-btn {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-left: 10px;
  cursor: pointer;
}

.basket-items-list-item-clear-btn::after,
.basket-items-list-item-clear-btn::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 14px;
  background: #d0021b;
  transition: 300ms background-color ease;
}

.basket-items-list-item-clear-btn::after {
  transform: translate3d(-50%, -50%, 0) rotate(45deg);
}

.basket-items-list-item-clear-btn::before {
  transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}

.basket-items-list-item-clear-btn:hover::after,
.basket-items-list-item-clear-btn:hover::before {
  background-color: #7e000f;
}

.basket-items-list-item-double {
  margin: 10px 0;
}

.basket-items-list-item-double > div {
  margin-bottom: 10px;
  padding: 5px 35px 5px 15px;
}

.basket-items-list-item-double-anchor,
.basket-items-list-item-double-anchor:visited {
  border-bottom: 1px dashed;
  color: #333;
  text-decoration: none;
}

.basket-items-list-item-double-anchor:hover {
  color: #23527c;
}

.basket-items-list-item-warning-container {
  margin: 10px 0;
}

.basket-items-list-item-warning-container > div {
  margin-bottom: 10px;
  padding: 5px 35px 5px 15px;
}

.basket-items-list-wrapper-compact .basket-items-list-item-container {
  align-items: flex-start;
  padding: 17px 0;
  -ms-flex-align: start;
  -webkit-box-align: start;
}

/* region Gifts */
.basket-item-gifts-list-container {
  position: relative;
  margin-bottom: 20px;
  border: 1px solid #fceb9f;
  background: #fffef8;
}

.basket-item-gifts-list-container::after {
  content: '';
  position: absolute;
  top: -10px;
  left: 90px;
  display: block;
  width: 17px;
  height: 17px;
  background-color: #fceb9f;
  transform: rotate(45deg);
}

.basket-item-gifts-list-title {
  padding: 12px 17px;
  background-color: #fceb9f;
  color: #5b5848;
}

.basket-item-gifts-list {
  padding: 15px;
}

.basket-items-list-gift-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border-bottom: 1px solid #f3f2ed;
  -ms-flex-align: center;
  -webkit-box-align: center;
}

.basket-item-gifts-list .basket-items-list-gift-item:last-child {
  border-bottom: none;
}

/* region Gift image */
.basket-item-gift-image-container {
  flex: 1;
  width: 168px;
  min-width: 48px;
  max-width: 100%;
  padding: 10px 0;
  text-align: center;
  -webkit-box-flex: 1;
}

.basket-item-gift-image-link {
  display: inline-block;
}

.basket-item-gift-image {
  display: inline-block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 69px;
}

/* endregion */

/* region Gift title */
.basket-item-gift-title-container {
  flex: 9;
  width: 100%;
  padding: 5px 15px;
  -webkit-box-flex: 9;
}

.basket-item-gift-title-link {
  color: #333;
  font-weight: var(--ui-font-weight-regular, 400);
  transition: 300ms all ease;
}

/* endregion */

/* region Gift price */
.basket-item-gift-price-container {
  flex: 2;
  text-align: right;
  -webkit-box-flex: 2;
}

.basket-item-gift-price-current {
  color: #2f3435;
}

.basket-item-gift-price-old {
  color: #a1a1a1;
  text-decoration: line-through;
}

.basket-item-gift-price-difference {
  display: flex;
  align-items: center;
  padding: 5px 6px 6px;
  background: #e2f0cb;
  color: #525c69;
  text-align: center;
  text-transform: uppercase;
  -ms-flex-align: center;
  -webkit-box-align: center;
}

/* endregion */

/* endregion */

/* region Gift-container */
.basket-gifts-list-wrapper,
.basket-gifts-list-wrapper-compact {
  margin-bottom: 15px;
  border: 1px solid #acacac;
}

.basket-gifts-list-header {
  padding: 18px 25px 20px;
  border-bottom: 1px solid #acacac;
  background: #f2f2f2;
  transition: 300ms all ease;
}

.basket-clear {
  cursor: pointer;
  pointer-events: auto;
}

.basket-clear::after,
.basket-clear::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 14px;
  background: #a1a1a1;
  transition: 300ms background-color ease;
}

.basket-clear::after {
  transform: translate3d(-50%, -50%, 0) rotate(45deg);
}

.basket-clear::before {
  transform: translate3d(-50%, -50%, 0) rotate(-45deg);
}

.basket-clear:hover::after,
.basket-clear:hover::before {
  background-color: #333;
}

.basket-gifts-list {
  padding: 0 25px;
}

.basket-gifts-description-container {
  padding: 11px;
  color: #868d96;
  text-align: center;
}

.basket-gifts-list-item-container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow: hidden;
  padding: 20px 0;
  border-top: 1px solid #f2f2f2;
  transition: 300ms all ease;
  -ms-flex-align: center;
  -webkit-box-align: center;
}

.basket-gifts-list-item-container:first-child {
  border-top: none;
}

.basket-gift-block-image {
  min-width: 50px;
  max-width: 50px;
  padding: 5px 15px 5px 0;
}

.basket-gift-image-link {
  display: inline-block;
}

.basket-gift-image {
  display: inline-block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 168px;
  transition: 300ms all ease;
}

.basket-gift-block-info {
  flex: 1;
}

.basket-gift-info-name,
.basket-gift-info-name-link {
  margin: 0;
  padding: 0;
  color: #2f3435;
  font-weight: var(--ui-font-weight-regular, 400);
  text-decoration: none;
  transition: 300ms all ease;
}

.basket-gift-info-name-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.basket-gift-block-common {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  -ms-flex-align: center;
  -ms-flex-line-pack: center;
}

.basket-gift-item-select-btn-container {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  -ms-flex-align: center;
}

.basket-gift-item-selected {
  padding: 0 10px;
  color: #b6bbbf;
}

.basket-gift-item-select-btn-label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  -ms-flex-align: center;
  -webkit-box-align: center;
}

.basket-gift-item-select-input {
  display: none;
}

.basket-gift-item-select-btn-radio {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border: 10px solid #fff;
  border-radius: 50%;
  background: var(--primary);
  box-shadow: 0 0 0 1px #828b95;
  cursor: pointer;
  transition: 190ms all ease;
}

.basket-gift-item-select-input:checked + .basket-gift-item-select-btn-radio {
  border-width: 5px;
}

.basket-gift-item-select-btn-text {
  text-transform: lowercase;
  cursor: pointer;
}

.basket-gift-item-select-btn-checkbox {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  width: 19px;
  height: 19px;
  margin-right: 10px;
  border: 1px solid #828b95;
  border-radius: 2px;
  background-color: #fff;
  cursor: pointer;
}

.basket-gift-item-select-btn-checkbox::after,
.basket-gift-item-select-btn-checkbox::before {
  content: '';
  position: absolute;
  background-color: #fff;
  opacity: 0;
  transition: 190ms all ease;
}

.basket-gift-item-select-btn-checkbox::after {
  top: 5px;
  left: 5px;
  z-index: 15;
  width: 3px;
  height: 9px;
  border-radius: 1px;
  transform: rotate(-45deg);
}

.basket-gift-item-select-btn-checkbox::before {
  top: -1px;
  left: 11px;
  z-index: 10;
  width: 5px;
  height: 17px;
  border: 1px solid #fff;
  border-radius: 2px;
  transform: rotate(45deg);
}

.basket-gift-item-select-input:checked + .basket-gift-item-select-btn-checkbox::after,
.basket-gift-item-select-input:checked + .basket-gift-item-select-btn-checkbox::before {
  background-color: var(--primary);
  opacity: 1;
}

.bx-step-opacity {
  transition: 500ms opacity ease;
}

/* endregion */

/* region not found */
.basket-search-not-found {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50px 0 0;
  transform: translate3d(-50%, -50%, 0);
}

.basket-search-not-found-icon {
  width: 180px;
  height: 180px;
  margin: 0 auto 80px;
  background: no-repeat center
    url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%22182px%22%20height%3D%22182px%22%20viewBox%3D%220%200%20182%20182%22%20version%3D%221.1%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%3E%0A%20%20%20%20%3Cg%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.2%22%20fill%3D%22%23A7ABB0%22%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M17%2C70.5%20C17%2C40.9473747%2040.9473747%2C17%2070.5%2C17%20C100.052625%2C17%20124%2C40.9473747%20124%2C70.5%20C124%2C100.052625%20100.052625%2C124%2070.5%2C124%20C40.9473747%2C124%2017%2C100.052625%2017%2C70.5%20M178.408149%2C160.192955%20L129.280894%2C111.056302%20C129.216524%2C111.017683%20129.152154%2C110.991937%20129.100658%2C110.953317%20C136.979557%2C99.5348706%20141.614204%2C85.7220257%20141.614204%2C70.8020936%20C141.614204%2C31.6935917%20109.905496%2C0%2070.8071019%2C0%20C31.7087076%2C0%200%2C31.6935917%200%2C70.8020936%20C0%2C109.910596%2031.7087076%2C141.604187%2070.8071019%2C141.604187%20C85.7152154%2C141.604187%2099.5419113%2C136.969868%20110.948292%2C129.091526%20C110.999788%2C129.143019%20111.012662%2C129.220257%20111.064158%2C129.27175%20L160.204287%2C178.408403%20C164.993422%2C183.197199%20172.846573%2C183.197199%20177.635708%2C178.408403%20L178.408149%2C177.623143%20C183.197284%2C172.834347%20183.197284%2C164.981751%20178.408149%2C160.192955%22/%3E%0A%20%20%20%20%3C/g%3E%0A%3C/svg%3E);
  background-size: cover;
}

.basket-search-not-found-text {
  color: #c0c5ca;
  text-align: center;
}

/* endregion */

.bx-sbb-empty-cart-image {
  width: 131px;
  height: 116px;
  margin: 50px auto 42px;
  //background: url(images/empty_cart.svg) no-repeat center;
  background-size: contain;
}

.bx-sbb-empty-cart-text {
  margin-bottom: 42px;
  color: #bababa;
  font-size: 36px;
  text-align: center;
}

.bx-sbb-empty-cart-desc {
  margin-bottom: 42px;
  color: #000;
  font-size: 16px;
  text-align: center;
}

.catalog-block-header {
  margin: 5px 0;
  padding: 5px 15px;
  border-bottom: 2px solid #d9dee6;
  color: #000;
  font-weight: var(--ui-font-weight-bold);
  font-size: 14px;
  text-align: left;
}

body.bx-theme-blue .catalog-block-header {
  border-color: #006cc0;
}

body.bx-theme-green .catalog-block-header {
  border-color: #63aa28;
}

body.bx-theme-red .catalog-block-header {
  border-color: #da3737;
}

body.bx-theme-yellow .catalog-block-header {
  border-color: #f4b236;
}
