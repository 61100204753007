@use "../../common/screen";

.news-section {
  overflow: hidden;
  padding: 40px 0;
  background: linear-gradient(95deg, #c5c5c5 12.39%, #f2f2f2 45.24%, #c9c9c9 64.7%, #afafaf 95.38%);

  @include screen.from(screen.$xl) {
    padding-bottom: 50px;
  }
}

.news-section__heading {
  margin-bottom: 40px;
  text-align: center;
}
