.link {
  color: var(--color-links);
  text-decoration: underline;
  transition: color var(--transition);

  &:hover,
  &:focus-visible {
    color: var(--color-links-hover);
  }
}

.colored-link-with-icon {
  color: var(--color-links);
  transition: color var(--transition);

  &:hover,
  &:focus-visible {
    color: var(--color-links-hover);
  }

  .link-with-icon__text {
    text-decoration: underline;
  }
}

.text-with-links {
  a {
    color: inherit;
    text-decoration: underline;
    transition: color var(--transition);

    &:hover,
    &:focus-visible {
      color: var(--color-links-hover);
    }
  }
}

.text-with-colored-links {
  a {
    color: var(--color-links);
    text-decoration: underline;
    transition: color var(--transition);

    &:hover,
    &:focus-visible {
      color: var(--color-links-hover);
    }
  }
}

.text-with-links--light {
  a {
    &:hover,
    &:focus-visible {
      color: var(--color-links-hover-light);
    }
  }
}
