@use "../../common/screen";

.header {
  position: relative;
  z-index: 10;
  padding-top: 50px;

  @include screen.from(screen.$xl) {
    overflow: hidden;
    padding: 0;
  }
}

.header__mobile-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-index-mobile-bar);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 56px;
  padding: 8px var(--size-container-sidegap);
  background: #fff;
  box-shadow: 0 2px 16px 0 rgb(0 0 0 / 10%);
  transition: transform var(--transition);
  transform: translateY(-100%);

  &.is-shown {
    transform: none;
  }

  @include screen.from(screen.$xl) {
    display: none;
  }
}

.header__menu-button-mobile-text {
  @include screen.to(screen.$sm) {
    display: none;
  }
}

.header__mobile-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 130px;
  transform: translate(-50%, -50%);

  @include screen.from(screen.$md) {
    width: 150px;
  }
}

.header__icon-button {
  text-transform: uppercase;
  transition: color var(--transition);

  &:hover,
  &:focus-visible {
    color: var(--color-links-hover);
  }
}

.header__layout {
  display: grid;
  //                     1     2   3    4    5    6
  grid-template-columns: 115px 1fr 20px 40px 40px;
  align-items: center;

  @include screen.from(390px) {
    grid-template-columns: 140px 1fr 20px 40px 40px;
  }

  @include screen.from(screen.$sm) {
    grid-template-columns: 150px 1fr 130px 60px 60px;
  }

  @include screen.from(screen.$md) {
    grid-template-columns: 1fr 1fr 60px 60px 40px;
    padding: 0 32px;
  }

  @include screen.from(screen.$xl) {
    grid-template-columns: 260px 1fr 217px 126px 100px;
    padding: 0;
  }
}

.header__logo {
  display: block;
  grid-row: 1 / 2;
  grid-column: 1 / 2;
  width: 100%;

  @include screen.from(screen.$sm) {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
  }

  @include screen.from(screen.$md) {
    grid-row: 1 / 3;
    grid-column: 1 / -1;
    justify-self: center;
    width: 150px;
    margin-bottom: 10px;
  }

  @include screen.from(screen.$xl) {
    grid-row: 2 / 5;
    grid-column: 1 / 2;
    justify-self: start;
    width: 225px;
    margin: 30px 0;
  }
}

.header__language {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  justify-self: end;
  margin-top: 10px;
  margin-right: 13px;

  @include screen.from(screen.$sm) {
    grid-row: 4 / 5;
    grid-column: 4 / 5;
    margin: 0;
    padding: 4px 0;
  }

  @include screen.from(screen.$md) {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
    margin-right: 15px;
  }

  @include screen.from(screen.$xl) {
    grid-row: 1 / 2;
    grid-column: 4 / 5;
  }
}

.header__user {
  grid-row: 1 / 2;
  grid-column: 3 / 5;
  justify-self: end;
  margin-top: 10px;
  margin-right: 8px;

  @include screen.from(screen.$sm) {
    grid-row: 4 / 5;
    grid-column: 5 / 6;
    margin: 0;
    padding: 4px 0;
  }

  @include screen.from(screen.$md) {
    grid-row: 3 / 4;
    grid-column: 3 / 4;
    justify-self: center;
  }

  @include screen.from(screen.$xl) {
    grid-row: 1 / 2;
    grid-column: 5 / 6;
  }
}

.header__user-button {
  @include screen.from(screen.$xl) {
    display: flex;
    align-items: center;
  }
}

.header__user-label {
  display: none;

  @include screen.from(screen.$xl) {
    display: block;
    margin-left: 6px;
  }
}

.header__user-name {
  margin-bottom: 8px;
  padding: 7px 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
}

.header__basket {
  grid-row: 1 / 2;
  grid-column: 5 / 6;
  justify-self: center;

  @include screen.from(screen.$sm) {
    grid-row: 1 / 3;
    grid-column: 5 / 6;
    justify-self: end;
  }

  @include screen.from(screen.$md) {
    grid-row: 3 / 4;
    grid-column: 5 / 6;
  }

  @include screen.from(screen.$xl) {
    grid-row: 2 / 5;
    grid-column: 5 / 6;
    margin-right: 34px;
    margin-bottom: 5px;

    .i {
      width: 40px;
      height: 40px;
    }
  }
}

.header__basket-link {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
}

.header__basket-counter {
  position: absolute;
  top: 5px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  border-radius: 999px;
  background: var(--color-red);
  color: #fff;
  font-weight: 500;
  font-style: normal;
  font-size: 10px;

  @include screen.from(screen.$xl) {
    top: 0;
    right: -8px;
    width: 20px;
    height: 20px;
    font-size: 12px;
  }
}

.header__back-call {
  position: relative;
  display: inline-flex;
  grid-row: 3 / 4;
  grid-column: 2 / 5;
  justify-content: center;
  align-items: center;
  min-height: 32px;
  margin: 20px 0;
  padding: 6px 5px;
  border: 1px solid #6189e9;
  border-radius: 16px;
  background: #6189e9;
  color: #fff;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 140%; /* 28px */
  text-align: center;
  transition: background-color var(--transition), color var(--transition);
  appearance: none;

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    background-color: #e8efff;
    color: #315cc1;
  }

  &:active:not(:disabled) {
    background-color: #fff;
    color: #6189e9;
  }

  .i {
    margin-right: 4px;
  }

  @include screen.from(screen.$sm) {
    grid-row: 3 / 4;
    grid-column: 1 / -1;
    justify-self: center;
    min-width: 236px;
    margin: 20px 0;
  }

  @include screen.from(screen.$md) {
    grid-row: 1 / 3;
    grid-column: 1 / 2;
    justify-self: start;
    min-width: 200px;
    min-height: 34px;
    margin: 0 0 10px;
  }

  @include screen.from(screen.$xl) {
    grid-row: 1 / 2;
    grid-column: 3 / 4;
    margin: 0;
  }
}

.header__back-call-text1 {
  @include screen.to(screen.$sm) {
    display: none;
  }
}

.header__back-call-text2 {
  @include screen.to(screen.$sm) {
    &::first-letter {
      text-transform: capitalize;
    }
  }
}

.header__ims-list {
  display: flex;

  @include screen.from(screen.$sm) {
    order: 1;
    margin-right: -5px;
  }
}

.header__im {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;

  &:not(:last-of-type) {
    margin-right: 8px;
  }

  &.is-whatsapp {
    color: #128c7e;
  }

  &.is-tg {
    color: #00a0e7;
  }

  &.is-viber {
    color: #7360f2;
  }

  &.is-skype {
    color: #00b5f9;
  }
}

.header__phone {
  display: flex;
  grid-row: 2 / 3;
  grid-column: 1 / -1;
  align-items: center;
  justify-self: center;
  margin-top: 12px;

  @include screen.from(screen.$sm) {
    display: flex;
    flex-direction: column;
    grid-row: 1 / 2;
    grid-column: 1 / 5;
    align-items: end;
    justify-self: end;
    margin-right: 20px;
  }

  @include screen.from(screen.$md) {
    grid-row: 1 / 2;
    grid-column: 2 / 6;
    margin: 0;
  }

  @include screen.from(screen.$xl) {
    position: relative;
    grid-row: 2 / 3;
    grid-column: 3 / 5;
    margin-top: 37px;
    margin-bottom: 37px;
  }
}

.header__phone-text {
  margin-left: 16px;
  white-space: nowrap;

  b {
    font-weight: 500;
  }

  @include screen.from(screen.$md) {
    font-size: 16px;
  }

  @include screen.from(screen.$xl) {
    font-size: 18px;
  }
}

.header__buttons {
  display: flex;
  grid-row: 3 / 4;
  grid-column: 1 / 2;

  @include screen.from(screen.$sm) {
    grid-row: 4 / 5;
    grid-column: 1 / 4;
  }

  @include screen.from(screen.$md) {
    grid-row: 3 / 4;
  }

  @include screen.from(screen.$xl) {
    display: none;
  }
}

.header__menu-button {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 140%;

  .i {
    margin-right: 8px;
  }

  &:not(:last-child) {
    margin-right: 24px;
  }

  @include screen.from(screen.$md) {
    font-size: 20px;

    .i {
      margin-right: 14px;
    }

    &:not(:last-child) {
      margin-right: 30px;
    }
  }
}

.header__search {
  grid-row: 3 / 4;
  grid-column: 5 / 6;
  justify-self: end;

  &[aria-expanded='true'] {
    color: var(--color-links-hover);
  }

  @include screen.from(screen.$sm) {
    grid-row: 4 / 5;
    grid-column: 3 / 4;
    margin-right: 15px;
  }

  @include screen.from(screen.$md) {
    grid-row: 3 / 4;
    grid-column: 4 / 5;
    justify-self: center;
    margin: 0;
  }

  @include screen.from(screen.$xl) {
    display: none;
  }
}

.header__search-field {
  @include screen.to(screen.$xl) {
    position: absolute;
    top: calc(100% + 12px);
    left: 0;
    width: 100%;
    padding: 8px var(--size-container-sidegap);
    background: #fff;
    box-shadow: 0 8px 36px 0 rgb(0 0 0 / 20%);
    visibility: hidden;

    &.is-opened {
      visibility: visible;
      animation: fade 0.3s ease both;
    }
  }

  @include screen.from(screen.$xl) {
    display: block;
    grid-row: 2 / 5;
    grid-column: 2 / 3;
    align-self: center;
    justify-self: start;
    width: 100%;
  }
}

.header__main-menu {
  @include screen.from(screen.$xl) {
    position: relative;
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    justify-self: start;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -100vw;
      display: block;
      width: 200vw;
      height: 1px;
      background: var(--color-lines);
    }
  }
}

.header__catalog-menu {
  @include screen.from(screen.$xl) {
    position: relative;
    grid-row: 5 / 6;
    grid-column: 1 / -1;
    justify-self: start;
    width: 100%;
  }
}
