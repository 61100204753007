@use "../../common/screen";

.contract-how__list {
  max-width: 800px;
  margin: 0 auto;
}

.contract-faq {
  @include screen.from(screen.$xl) {
    display: grid;
    grid-template:
      'heading . faq'
      / 400px 1fr 684px;
  }
}

.contract-faq__heading {
  @include screen.from(screen.$xl) {
    grid-area: heading;
    margin-top: 15px;
    margin-left: 30px;
    text-align: left;
  }
}

.contract-faq__details {
  @include screen.from(screen.$xl) {
    grid-area: faq;
  }
}
