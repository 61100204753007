.i {
  display: inline-block;
  vertical-align: text-bottom;
  width: 20px;
  height: 20px;
  background-color: currentcolor;
  transition: background-color var(--transition);
  mask-repeat: no-repeat;
  mask-size: 100% 100%;
}

.i--accessories-20 {
  width: 18px;
  height: 17px;
  mask-image: url(svg/icons/accessories-20.svg)
}


.i--angle-down-16 {
  width: 16px;
  height: 16px;
  mask-image: url(svg/icons/angle-down-16.svg)
}


.i--angle-down-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/angle-down-20.svg)
}


.i--angle-left-20 {
  width: 21px;
  height: 20px;
  mask-image: url(svg/icons/angle-left-20.svg)
}


.i--angle-right-20 {
  width: 21px;
  height: 21px;
  mask-image: url(svg/icons/angle-right-20.svg)
}


.i--arrow-left-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/arrow-left-20.svg)
}


.i--arrow-right-16 {
  width: 16px;
  height: 16px;
  mask-image: url(svg/icons/arrow-right-16.svg)
}


.i--arrow-right-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/arrow-right-20.svg)
}


.i--box-22 {
  width: 22px;
  height: 22px;
  mask-image: url(svg/icons/box-22.svg)
}


.i--briefcase-22 {
  width: 22px;
  height: 22px;
  mask-image: url(svg/icons/briefcase-22.svg)
}


.i--calculator-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/calculator-20.svg)
}


.i--cart-24 {
  width: 24px;
  height: 24px;
  mask-image: url(svg/icons/cart-24.svg)
}


.i--catalog-24 {
  width: 24px;
  height: 24px;
  mask-image: url(svg/icons/catalog-24.svg)
}


.i--checkmark-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/checkmark-20.svg)
}


.i--chevron-down-16 {
  width: 16px;
  height: 16px;
  mask-image: url(svg/icons/chevron-down-16.svg)
}


.i--close-30 {
  width: 30px;
  height: 30px;
  mask-image: url(svg/icons/close-30.svg)
}


.i--contacts-16 {
  width: 16px;
  height: 17px;
  mask-image: url(svg/icons/contacts-16.svg)
}


.i--contract-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/contract-20.svg)
}


.i--download-22 {
  width: 22px;
  height: 22px;
  mask-image: url(svg/icons/download-22.svg)
}


.i--education-22 {
  width: 22px;
  height: 22px;
  mask-image: url(svg/icons/education-22.svg)
}


.i--glue-20 {
  width: 23px;
  height: 19px;
  mask-image: url(svg/icons/glue-20.svg)
}


.i--home-24 {
  width: 24px;
  height: 24px;
  mask-image: url(svg/icons/home-24.svg)
}


.i--hooks-20 {
  width: 23px;
  height: 27px;
  mask-image: url(svg/icons/hooks-20.svg)
}


.i--info-16 {
  width: 16px;
  height: 16px;
  mask-image: url(svg/icons/info-16.svg)
}


.i--kits-20 {
  width: 19px;
  height: 18px;
  mask-image: url(svg/icons/kits-20.svg)
}


.i--lamps-20 {
  width: 20px;
  height: 18px;
  mask-image: url(svg/icons/lamps-20.svg)
}


.i--mail-16 {
  width: 16px;
  height: 16px;
  mask-image: url(svg/icons/mail-16.svg)
}


.i--map-30 {
  width: 30px;
  height: 30px;
  mask-image: url(svg/icons/map-30.svg)
}


.i--menu-24 {
  width: 24px;
  height: 24px;
  mask-image: url(svg/icons/menu-24.svg)
}


.i--partnership-16 {
  width: 16px;
  height: 16px;
  mask-image: url(svg/icons/partnership-16.svg)
}


.i--phone-12 {
  width: 12px;
  height: 12px;
  mask-image: url(svg/icons/phone-12.svg)
}


.i--phone-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/phone-20.svg)
}


.i--phone-24 {
  width: 24px;
  height: 24px;
  mask-image: url(svg/icons/phone-24.svg)
}


.i--pin-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/pin-20.svg)
}


.i--play-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/play-20.svg)
}


.i--plus-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/plus-20.svg)
}


.i--reveal-price-24 {
  width: 24px;
  height: 24px;
  mask-image: url(svg/icons/reveal-price-24.svg)
}


.i--reviews-16 {
  width: 16px;
  height: 16px;
  mask-image: url(svg/icons/reviews-16.svg)
}


.i--search-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/search-20.svg)
}


.i--search-24 {
  width: 24px;
  height: 24px;
  mask-image: url(svg/icons/search-24.svg)
}


.i--skype-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/skype-20.svg)
}


.i--tg-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/tg-20.svg)
}


.i--thumbsup-24 {
  width: 24px;
  height: 24px;
  mask-image: url(svg/icons/thumbsup-24.svg)
}


.i--truck-22 {
  width: 22px;
  height: 22px;
  mask-image: url(svg/icons/truck-22.svg)
}


.i--user-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/user-20.svg)
}


.i--user-22 {
  width: 22px;
  height: 22px;
  mask-image: url(svg/icons/user-22.svg)
}


.i--user-24 {
  width: 24px;
  height: 24px;
  mask-image: url(svg/icons/user-24.svg)
}


.i--viber-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/viber-20.svg)
}


.i--vk-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/vk-20.svg)
}


.i--wallet-16 {
  width: 16px;
  height: 16px;
  mask-image: url(svg/icons/wallet-16.svg)
}


.i--warranty-16 {
  width: 16px;
  height: 16px;
  mask-image: url(svg/icons/warranty-16.svg)
}


.i--whatsapp-20 {
  width: 20px;
  height: 21px;
  mask-image: url(svg/icons/whatsapp-20.svg)
}


.i--whatsapp-24 {
  width: 24px;
  height: 24px;
  mask-image: url(svg/icons/whatsapp-24.svg)
}


.i--youtube-20 {
  width: 20px;
  height: 20px;
  mask-image: url(svg/icons/youtube-20.svg)
}

